import { ReactNode } from "react";
import { DataTableCell } from "./dataTable.interface";

export const createDataRow = <T extends Record<string, ReactNode>>(
  id: number | string,
  data: T
): DataTableCell => ({
  id,
  ...data,
});
