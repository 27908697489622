import React, { useState } from "react";
import { Grid, Box, Typography, TablePagination } from "@mui/material";
import ApprovalRequest from "./ApprovalRequest";
import theme from "@/utils/theme";
import {
  useGetAlertsByOfficerId,
  useUpdateAlertIsViewed,
} from "@/hooks/useAlerts";
import { useUserStore } from "@/hooks/useUserStore";
import { ResponseOfficerPendingAlerts } from "@/domain/alerts.interface";
import SkeletonLoader from "@/components/SkeletonLoader";
import dayjs from "dayjs";
import { addOrdinalSuffixToDay } from "@/utils/dateUtils";

const DashboardCards: React.FC = () => {
  const { userInfo } = useUserStore();
  const officerId = userInfo?._id;
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(5);

  const { alerts, totalAlerts, isLoadingAlerts, isErrorAlerts, refetchAlerts } =
    useGetAlertsByOfficerId(officerId || "", pageSize, pageNumber + 1);

  const { mutateAsync: markAsViewed } = useUpdateAlertIsViewed();
  const handleMarkAlertAsViewed = async (
    alert: ResponseOfficerPendingAlerts
  ) => {
    if (!alert.isViewed) {
      await markAsViewed({
        alertId: alert._id,
      });
    }
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };

  return (
    <Grid container mt={0} spacing={2}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Grid xs={12} mb={1}>
          <Typography fontWeight={theme.typography.fontWeightBold} variant="h5">
            {dayjs().format("[Today], MMM")} {addOrdinalSuffixToDay(dayjs())}
          </Typography>
        </Grid>
        <Box
          p={3}
          sx={{
            boxShadow: 1,
            borderRadius: 2,
            bgcolor: "background.paper",
            height: "100%",
          }}
        >
          <Box>
            <Typography
              color={theme.palette.textMain.dark2}
              variant="headlineSmall"
            >
              Approval requests
            </Typography>
          </Box>

          {isErrorAlerts && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Typography color="error">Failed to load alerts</Typography>
            </Box>
          )}

          <SkeletonLoader
            skeletonHeight={80}
            skeletonCount={3}
            isLoading={isLoadingAlerts}
          >
            {!isLoadingAlerts && alerts && alerts.length > 0 && (
              <>
                {alerts.map((alert: ResponseOfficerPendingAlerts) => (
                  <ApprovalRequest
                    alert={alert}
                    key={alert._id}
                    handleMarkAlertAsViewed={() =>
                      handleMarkAlertAsViewed(alert)
                    }
                    refetchAlerts={refetchAlerts}
                  />
                ))}
                <Box
                  borderTop={1}
                  marginTop={2}
                  borderColor={theme.palette.outline.light2}
                >
                  <TablePagination
                    component="div"
                    count={totalAlerts || 0}
                    page={pageNumber}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[]}
                  />
                </Box>
              </>
            )}
          </SkeletonLoader>

          {!isLoadingAlerts && alerts?.length === 0 && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Typography>No approval requests</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardCards;
