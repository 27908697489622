import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useAppTheme } from "@/utils/theme";
import { Controller, useFormContext } from "react-hook-form";
import { RejectedForm } from "@/ui/modals/clients/ClientStepModal";

const RejectedStep: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<RejectedForm>();
  const theme = useAppTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.25rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <Typography
          variant="titleSmall"
          color={theme.palette.icon.dark}
          fontWeight={700}
        >
          Result: Rejection
        </Typography>
        <Typography variant="bodyLarge" color={theme.palette.textMain.dark2}>
          Please give the reason for rejecting this step:
        </Typography>
      </Box>

      <Controller
        name="rejectedReason"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            id="rejectedReason"
            label="Comments"
            variant="outlined"
            required
            value={field.value}
            onChange={(value) => field.onChange(value)}
            error={!!errors.rejectedReason}
            helperText={
              errors.rejectedReason ? errors.rejectedReason.message : null
            }
            fullWidth
            multiline
            rows={10}
          />
        )}
      />
    </Box>
  );
};

export default RejectedStep;
