import { UserPayload } from "../domain/user.interface";
import { UsersService } from "../services/users.service";
import { useMutation, useQuery } from "@tanstack/react-query";

export type UsersPayload = {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  search: string;
  status: string[];
  roleId: string;
  roleSubType?: string;
  programId?: string;
  orgUnitIds?: string;
};

export const useUsers = (
  {
    sortField,
    sortOrder,
    pageNumber,
    pageSize,
    search,
    status,
    roleId,
    roleSubType,
    programId,
    orgUnitIds,
  }: UsersPayload,
  enabled = true
) => {
  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ["parca-users"],
    queryFn: () =>
      UsersService.findAll({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        search,
        status,
        roleId,
        roleSubType,
        programId,
        orgUnitIds,
      }),
    enabled,
  });

  return {
    users,
    isLoadingUsers,
    refetchUsers,
  };
};

export const useUserByEmail = ({ email }: { email: string }) => {
  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ["parca-user-by-email"],
    queryFn: () => UsersService.findByEmail(email),
  });

  return {
    user,
    isLoadingUser,
    refetchUser,
  };
};

export const useProgramsByUser = ({
  userId,
  sortType,
}: {
  userId: string;
  sortType: string;
}) => {
  const {
    data: programs,
    isLoading: isLoadingPrograms,
    isFetching: isFetchingPrograms,
    refetch: refetchPrograms,
  } = useQuery({
    queryKey: ["parca-programs", userId],
    queryFn: () =>
      UsersService.findProgramsByUser({
        id: userId,
        sortType,
      }),
  });

  return {
    programs,
    isLoadingPrograms,
    isFetchingPrograms,
    refetchPrograms,
  };
};

export const useOrganizationsByUser = ({
  userId,
  isEditMode,
}: {
  userId: string;
  isEditMode: boolean;
}) => {
  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    refetch: refetchOrganizations,
  } = useQuery({
    queryKey: ["parca-organizations", userId],
    queryFn: () => UsersService.findOrganizationsByUser(userId),
    enabled: isEditMode,
  });

  return {
    organizations,
    isLoadingOrganizations,
    refetchOrganizations,
  };
};

export const useUserCreateOne = () => {
  return useMutation({
    mutationFn: (data: UserPayload) => UsersService.createOne(data),
  });
};

export const useUserUpdateOne = () => {
  return useMutation({
    mutationFn: async ({
      user,
      userId,
    }: {
      user: UserPayload;
      userId: string;
    }) => {
      await UsersService.updateOne({
        user,
        userId,
      });
    },
  });
};

export const useUserDeleteOne = () => {
  return useMutation({
    mutationFn: (userId: string) => UsersService.deleteOne(userId as string),
  });
};

export const useUserResendInvitation = () => {
  return useMutation({
    mutationFn: (email: string) => UsersService.resendInvitation(email),
  });
};

export const useUserById = (userId: string, enabled = true) => {
  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ["parca-user-by-id", userId],
    queryFn: () => UsersService.findById(userId),
    enabled: !!userId && enabled,
  });

  return {
    user,
    isLoadingUser,
    refetchUser,
  };
};
