import React, { FC, useMemo, useState } from "react";
import { RedeemRequestStatus } from "@/domain/enrollment.interface";
import { useParticipantById } from "@/hooks/useParticipants";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { Avatar, Tooltip, Typography } from "@mui/material";
import RedemptionCard from "@/ui/content/clients/RedemptionCard";
import { useTranslation } from "react-i18next";
import { useSearchRedeemRequests } from "@/hooks/useEnrollments";
import SkeletonLoader from "@/components/SkeletonLoader";

type RedeemRequestModalProps = {
  open: boolean;
  onClose: () => void;
  participantId: string;
};

interface Filters {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  status: string[];
}

const defaultFilters: Filters = {
  sortField: "createdAt",
  sortOrder: 1,
  status: [RedeemRequestStatus.PENDING_APPROVAL],
  pageNumber: 0,
  pageSize: 10,
};

const RedeemRequestModal: FC<RedeemRequestModalProps> = ({
  open,
  onClose,
  participantId,
}) => {
  const { t } = useTranslation("clients");
  const theme = useAppTheme();
  const { data: participantResponse, isLoadingParticipant } =
    useParticipantById(participantId);
  const [filters] = useState<Filters>(defaultFilters);

  const {
    redeemRequests,
    isLoadingRedeemRequests,
    isFetchingRedeemRequests,
    refetchRedeemRequests,
  } = useSearchRedeemRequests({
    sortField: filters.sortField,
    sortOrder: filters.sortOrder,
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize,
    status: filters.status,
    enrollmentId: participantResponse?.lastEnrollment?._id ?? "",
  });

  const modalTitle = useMemo(() => t("alerts.redeem_requests_title"), [t]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={false}
      title={modalTitle}
      theme={theme}
      size={{
        width: "100%",
        minHeight: 610,
        maxHeight: 620,
        maxWidth: 580,
      }}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 0",
            gap: 1.2,
          }}
        >
          <SkeletonLoader
            isLoading={isLoadingRedeemRequests || isFetchingRedeemRequests}
            skeletonHeight={80}
            skeletonCount={1}
          >
            <Box
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="headlineSmall">
                {participantResponse
                  ? `${participantResponse.participant?.firstName} ${participantResponse.participant?.lastName}`
                  : ""}
              </Typography>
              <Tooltip title="Redemptions">
                <Avatar
                  sx={{
                    backgroundColor: "black",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <img
                    style={{ maxHeight: "96px", maxWidth: "100%" }}
                    src="/assets/icons/AiOutlineSchedule.svg"
                    alt="Redemptions"
                  />
                </Avatar>
              </Tooltip>
            </Box>
          </SkeletonLoader>
          <SkeletonLoader
            isLoading={
              isLoadingRedeemRequests ||
              isFetchingRedeemRequests ||
              isLoadingParticipant
            }
            skeletonHeight={80}
            skeletonCount={3}
          >
            {redeemRequests?.list?.length && participantResponse ? (
              redeemRequests.list
                .sort(
                  (a, b) =>
                    new Date(b.processDate).getTime() -
                    new Date(a.processDate).getTime()
                )
                .map((request, index) => (
                  <RedemptionCard
                    key={index}
                    item={request}
                    participant={participantResponse}
                    enrollmentId={
                      participantResponse?.lastEnrollment?._id || ""
                    }
                    refetchRequests={refetchRedeemRequests}
                  />
                ))
            ) : (
              <Typography>{t("alerts.no_redeem_requests")}</Typography>
            )}
          </SkeletonLoader>
        </Box>
      }
      actions={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variantType="cancel"
            onClick={onClose}
            label="CLOSE"
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      }
    />
  );
};

export default RedeemRequestModal;
