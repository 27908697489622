import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React, { FC } from "react";

export const SkeletonClientButtons: FC = () => {
  return (
    <>
      <Skeleton variant="rounded" width={40} height={40} />

      <Skeleton variant="rounded" width={80} height={40} />
    </>
  );
};

export const SkeletonClientFilters: FC = () => {
  return (
    <Box>
      <Skeleton data-testid="skeleton" variant="text" width={500} height={56} />
      <div className="flex gap-5">
        <Skeleton
          data-testid="skeleton"
          variant="text"
          width={320}
          height={56}
        />
        <Skeleton
          data-testid="skeleton"
          variant="text"
          width={320}
          height={56}
        />
      </div>
    </Box>
  );
};
