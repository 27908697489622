import React, { FC, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@/components/Select/Select";
import { useAppTheme } from "@/utils/theme";
import { useUserStore } from "@/hooks/useUserStore";
import { useGetUserPrograms } from "@/hooks/usePrograms";
import { SkeletonClientFilters } from "@/ui/menus/clients/Skeletons";
import { ParticipantStatus } from "@/domain/participant.enum";

export type Filters = {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  search: string;
  status: string[];
  programId: string;
};

type Props = {
  filtersState: [Filters, React.Dispatch<React.SetStateAction<Filters>>];
};

const ClientFilters: FC<Props> = ({ filtersState }) => {
  const theme = useAppTheme();
  const [filters, setFilters] = filtersState;
  const { userInfo, hasSystemAdminRole } = useUserStore();

  const { programs, isLoadingPrograms, isFetchingPrograms } =
    useGetUserPrograms({
      userId: userInfo?._id as string,
      sortType: "desc",
      hasSystemAdminRole,
    });

  const programsOptions = useMemo(() => {
    if (!programs) return [];
    return programs.map((program) => ({
      key: program._id,
      value: program.name,
    }));
  }, [programs]);

  useEffect(() => {
    if (programs && programs.length > 0 && !filters.programId) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        programId: programs[0]._id,
      }));
    }
  }, [programs, filters.programId, setFilters]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.25rem",
        width: "100%",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
        },
      }}
    >
      {isFetchingPrograms || isLoadingPrograms ? (
        <SkeletonClientFilters />
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: theme.palette.surface.light2,
              px: 2,
              width: {
                xs: "100%",
                sm: "100%",
              },
            }}
            className="flex rounded-lg py-3 max-w-full items-center justify-between h-14"
          >
            <InputBase
              placeholder="Search participant"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setFilters((filters) => ({
                  ...filters,
                  search: e.target.value,
                  pageNumber: 0,
                }));
              }}
              value={filters.search}
            />
            <SearchIcon className="text-icon-primary" />
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              width: {
                xs: "100%",
                sm: "100%",
              },
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
            }}
          >
            <Select
              handleChange={(value) => {
                setFilters((filters) => ({
                  ...filters,
                  programId: value as string,
                  pageNumber: 0,
                }));
              }}
              data={programsOptions || []}
              value={filters.programId}
              label="Program"
              id="users-program"
              isLoading={isLoadingPrograms}
              maxWidth={320}
              fullWidth
            />

            <Select
              handleChange={(value) => {
                setFilters((filters) => ({
                  ...filters,
                  status: value as string[],
                  pageNumber: 0,
                }));
              }}
              data={[
                { key: ParticipantStatus.ACTIVE, value: "Active" },
                { key: ParticipantStatus.PAUSED, value: "Paused" },
                { key: ParticipantStatus.COMPLETED, value: "Completed" },
                { key: ParticipantStatus.DISENROLLED, value: "Dismissed" },
                { key: ParticipantStatus.CANCELED, value: "Canceled" },
              ]}
              value={filters.status[0] === "" ? [] : filters.status}
              label="Status"
              id="participant-status"
              multiple
              maxWidth={320}
              fullWidth
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ClientFilters;
