import React from "react";
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  DoNotDisturbOnTwoTone as DoNotDisturbOnTwoToneIcon,
  CheckCircle as CheckCircleIcon,
  CancelTwoTone as CancelTwoToneIcon,
} from "@mui/icons-material";
import { GoalStatus } from "@/domain/goal.interface";
import { GoalStepStatus } from "@/domain/goal.interface";

interface StatusIconProps {
  status: GoalStatus | GoalStepStatus;
  type: "goal" | "step";
}

const StatusIcon: React.FC<StatusIconProps> = ({ status, type }) => {
  const getIconForGoal = () => {
    switch (status) {
      case GoalStatus.COMPLETED_STEPS:
        return <CheckCircleIcon color="success" />;
      case GoalStatus.PENDING_APPROVAL_STEPS:
        return <DoNotDisturbOnTwoToneIcon color="warning" />;
      case GoalStatus.PENDING_STEPS:
        return <RadioButtonUncheckedIcon color="disabled" />;
      default:
        return <RadioButtonUncheckedIcon color="disabled" />;
    }
  };

  const getIconForStep = () => {
    switch (status) {
      case GoalStepStatus.APPROVED:
        return <CheckCircleIcon color="success" />;
      case GoalStepStatus.PENDING_APPROVAL:
        return <DoNotDisturbOnTwoToneIcon color="warning" />;
      case GoalStepStatus.PENDING_SUBMIT:
        return <RadioButtonUncheckedIcon color="disabled" />;
      case GoalStepStatus.REJECTED:
        return <CancelTwoToneIcon color="error" />;
      default:
        return <RadioButtonUncheckedIcon color="disabled" />;
    }
  };

  return type === "goal" ? getIconForGoal() : getIconForStep();
};

export default StatusIcon;
