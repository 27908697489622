import { Goal, UpdateStepPayload } from "@/domain/goal.interface";
import { axios, transformAxiosError } from "@/utils/axios.create";

const findGoalsByEnrollment = async (enrollmentId: string): Promise<Goal[]> => {
  return axios
    .get(`/enrollments/${enrollmentId}/goals`)
    .then((res) => {
      return (res.data.body as Goal[]) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const updateGoalIsViewed = async (
  enrollmentId: string,
  goalId: string
): Promise<void> => {
  return axios
    .patch(`/enrollments/${enrollmentId}/goals/${goalId}/viewed`)
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const updateStep = async ({
  enrollmentId,
  goalId,
  stepId,
  submissionId,
  data,
}: {
  enrollmentId: string;
  goalId: string;
  stepId: string;
  submissionId: string;
  data: UpdateStepPayload;
}): Promise<void> => {
  return axios
    .patch(
      `/enrollments/${enrollmentId}/goals/${goalId}/step/${stepId}/submission/${submissionId}`,
      data
    )
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const validationProofLinks = async (
  enrollmentId: string,
  goalId: string,
  keys: string[]
): Promise<{ links: { [key: string]: string } }> => {
  return axios
    .post(
      `/enrollments/${enrollmentId}/goals/${goalId}/validation-proof/links`,
      { keys }
    )
    .then((res) => {
      return (res.data.body as { links: { [key: string]: string } }) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const GoalService = {
  findGoalsByEnrollment,
  updateGoalIsViewed,
  updateStep,
  validationProofLinks,
};
