import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../components/Spinner";
import { setToken } from "../utils/axios.create";
import { useUserStore } from "../hooks/useUserStore";
import { useMenuStore } from "../hooks/useMenuStore";
import { routesConfig, isKnownRoute, RouteItem } from "./routesConfig";
import { useCreateAuditlog } from "../hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "../domain/auditlog.enum";
import { Unauthorized } from "../pages/ErrorPages";

const clearSession = () => {
  localStorage.clear();
  sessionStorage.clear();
  document.cookie = "";
};

const AppRoutes = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading: isAuthLoading,
    error,
    user,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const {
    menus,
    getUserByEmail,
    isUserDataFetched,
    fetchRolesAndActions,
    userInfo,
    userHasAccess,
  } = useUserStore();

  const { setMenuItems, setActive } = useMenuStore();
  const location = useLocation();
  const [isAccessChecked, setIsAccessChecked] = useState(false);
  const [accessGranted, setAccessGranted] = useState(true);
  const [isTokenSet, setIsTokenSet] = useState(false);
  const createAuditLog = useCreateAuditlog();

  const clearAndLogout = useCallback(async () => {
    clearSession();
    await logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  useEffect(() => {
    if (menus.length > 0) {
      setMenuItems(menus);
      setActive(location.pathname);
    }
  }, [menus, setMenuItems, setActive, location.pathname]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !isAuthLoading &&
      !error &&
      isKnownRoute(location.pathname)
    ) {
      clearSession();
      loginWithRedirect();
      return;
    }

    if (isAuthenticated && !isUserDataFetched && user?.email) {
      getAccessTokenSilently()
        .then((token) => {
          setToken(token);
          setIsTokenSet(true);
          return getUserByEmail(user?.email as string);
        })
        .catch((error) => {
          console.error("Error getting access token silently:", { error });
          return clearAndLogout();
        });
    }
  }, [
    isAuthenticated,
    isAuthLoading,
    location.pathname,
    getAccessTokenSilently,
    loginWithRedirect,
    isUserDataFetched,
    error,
  ]);

  useEffect(() => {
    if (isAuthenticated && userInfo?._id && isUserDataFetched && isTokenSet) {
      const navigationEntries =
        window.performance.getEntriesByType("navigation");
      if (
        navigationEntries.length > 0 &&
        (navigationEntries[0] as unknown as { type: string }).type === "reload"
      ) {
        fetchRolesAndActions(userInfo?._id, true);
      } else {
        fetchRolesAndActions(userInfo?._id);
      }
    }
  }, [
    isAuthenticated,
    userInfo?._id,
    fetchRolesAndActions,
    isUserDataFetched,
    isTokenSet,
  ]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const checkTokenValidity = async () => {
      try {
        console.log("Checking token validity..."); // Added for testing purposes
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error("Access token is undefined");
        }
        console.debug("Access token refreshed:", { token }); // Added for testing purposes
        const tokenParts = token.split(".");
        const tokenPayload = JSON.parse(atob(tokenParts[1]));
        const expireDate = new Date(tokenPayload.exp * 1000);
        const timeUntilExpiration = expireDate.getTime() - new Date().getTime();
        setToken(token);
        if (intervalId) clearInterval(intervalId);
        intervalId = setInterval(checkTokenValidity, timeUntilExpiration);
        console.log("Token is valid for", timeUntilExpiration / 1000, "secs"); // Added for testing purposes
      } catch (error) {
        if (isAuthenticated && isTokenSet) {
          await sendSessionTimeOutLog();
        }
        console.error("Access token could not be refreshed:", { error });
        await clearAndLogout();
      }
    };

    if (userInfo?._id && isAuthenticated) {
      checkTokenValidity().then();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    getAccessTokenSilently,
    logout,
    userInfo?._id,
    isAuthenticated,
    isTokenSet,
  ]);

  const sendSessionTimeOutLog = async () => {
    const data = {
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.SIGN_OUT,
      option: OPTIONS.SIGN_OUT,
      actionCode: ACTION_CODE.WEB_SIGN_OUT_TIMEOUT,
      action: "Sign out (session timeout)",
      detail: "User signed out due to session timeout",
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
    };
    await createAuditLog.mutateAsync(data);
  };

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && isKnownRoute(location.pathname)) {
        const hasAccess = await userHasAccess(location.pathname);
        setAccessGranted(hasAccess);
        setIsAccessChecked(true);
      } else {
        setIsAccessChecked(true);
      }
    };

    checkAccess();
  }, [isAuthenticated, location.pathname, userHasAccess]);

  // Logic for handling the loading state, and those who dont have access to layout
  if (isAuthLoading || !isAccessChecked || !user) {
    if (error) {
      clearSession();
      return <Unauthorized></Unauthorized>;
    }

    return (
      <div className="flex w-screen h-screen items-center justify-center">
        <Spinner className="border-primary" />
      </div>
    );
  }

  if (!accessGranted) {
    return <Navigate to="/403" />;
  }

  if (!isKnownRoute(location.pathname)) {
    return <Navigate to="/404" />;
  }

  const renderRoutes = (routes: RouteItem[]) =>
    routes.map((route: RouteItem) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ));

  return <Routes>{renderRoutes(routesConfig)}</Routes>;
};

export default AppRoutes;
