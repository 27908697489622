import React, { FC, useRef } from "react";
import { SxProps, Theme } from "@mui/system";
import Button from "../Button";

interface UploadButtonProps {
  label: string;
  onUpload: (files: FileList | null) => void;
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  accept?: string;
  multiple?: boolean;
}

const UploadButton: FC<UploadButtonProps> = ({
  label,
  onUpload,
  disabled = false,
  isLoading = false,
  sx,
  accept,
  multiple = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!disabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    onUpload(files);
    event.target.value = "";
  };

  return (
    <>
      <Button
        variantType="save"
        label={label}
        onClick={handleClick}
        disabled={disabled}
        isLoading={isLoading}
        sx={sx}
      />
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        data-testid="file-input"
        onChange={handleFileChange}
        accept={accept}
        multiple={multiple}
      />
    </>
  );
};

export default UploadButton;
