import { axios, transformAxiosError } from "../utils/axios.create";
import {
  AuditLogPayload,
  AuditLogResponseBody,
  AuditLogParam,
  AuditLogPaginated,
} from "../domain/auditlog.interface";

const create = async (data: AuditLogPayload): Promise<AuditLogResponseBody> => {
  return axios
    .post("/auditlogs", data)
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(transformAxiosError(err)));
};

const findByModule = async (
  data: AuditLogParam
): Promise<AuditLogPaginated> => {
  const queryParams = new URLSearchParams({
    module: data.module,
    from: data.from,
    to: data.to,
    pageNumber: String(data.pageNumber + 1),
    pageSize: String(data.pageSize),
    sortField: data.sortField,
    sortOrder: data.sortOrder === 1 ? "asc" : "desc",
  }).toString();

  return axios
    .get(`/auditlogs?${queryParams}`)
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => Promise.reject(transformAxiosError(err)));
};

export const AuditLogsService = {
  create,
  findByModule,
};
