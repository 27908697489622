import { Module } from "../domain/module.interface";
import { axios } from "../utils/axios.create";

const findAll = async (): Promise<Module[]> => {
  return axios
    .get("/modules")
    .then((res) => {
      return res.data.body; // Assuming the modules are in `body`
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const moduleService = {
  findAll,
};
