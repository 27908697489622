import { RedeemRequest } from "./enrollment.interface";
import { Step } from "./goal.interface";

export type ResponseOfficerPendingAlerts = {
  _id: string;
  isViewed: boolean;
  participantName: string;
  participantId: string;
  title: string;
  date: string;
  description: string;
  code: string;
  referenceId: string;
  data: Step | RedeemRequest;
  status: string;
};

export type OfficerPendingAlertsResponse = {
  alerts: ResponseOfficerPendingAlerts[];
  total: number;
};

export enum AlertCode {
  PARTICIPANT_STEP_COMPLETION = "PARTICIPANT_STEP_COMPLETION",
  PARTICIPANT_REDEEM_REQUEST = "PARTICIPANT_REDEEM_REQUEST",
  APPOINTMENT_UPDATE_DUE = "APPOINTMENT_UPDATE_DUE",
}
