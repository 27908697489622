import React, { FC, useCallback, useMemo } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import screeningColorValidationSchema, {
  ScreeningColorData,
} from "./validation.schema";
import Button from "@/components/Button";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "@/domain/error.interface";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import {
  useColoringRequiredSetting,
  useColorModuleSetting,
} from "@/hooks/useSetting";
import { useGetUserPrograms } from "@/hooks/usePrograms";
import Grid from "@mui/material/Grid";
import Datepicker from "@/components/Datepicker";
import dayjs, { Dayjs } from "dayjs";
import Select from "@/components/Select";
import { useCategoryByType } from "@/hooks/useCategories";
import { CategoryElement } from "@/domain/category.interface";
import { useCreateScreeningColor } from "@/hooks/useScreeningColors";
import { Typography } from "@mui/material";

type ScreeningColorAddModalProps = {
  open: boolean;
  programId: string;
  onClose: () => void;
};

const ScreeningColorAddModal: FC<ScreeningColorAddModalProps> = ({
  open,
  programId,
  onClose,
}) => {
  const { userInfo, hasSystemAdminRole } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const theme = useAppTheme();

  const createAuditLog = useCreateAuditlog();
  const createScreeningColor = useCreateScreeningColor();

  const { programs } = useGetUserPrograms({
    userId: userInfo?._id as string,
    hasSystemAdminRole,
  });

  const { category, isLoadingCategory } = useCategoryByType({
    type: "COLORS",
    status: "ACTIVE",
  });

  const colors = useMemo(() => {
    if (!category) return [];

    return category?.categories.map((categoryElement: CategoryElement) => {
      const name = categoryElement.languages.find(
        (language) => language.language === "en-us"
      )?.name as string;

      return {
        key: categoryElement._id,
        value: name,
        hexaColor: categoryElement.hexaColor,
        extra: (
          <div
            className="rounded-full w-6 h-6"
            style={{
              backgroundColor: categoryElement.hexaColor,
            }}
          ></div>
        ),
      };
    });
  }, [category]);

  const colorModuleSetting = useColorModuleSetting();
  const colorRequired = useColoringRequiredSetting();

  const coloringModule = useMemo(
    () => colorModuleSetting,
    [colorModuleSetting]
  );
  const coloringRequired = useMemo(() => colorRequired, [colorRequired]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ScreeningColorData>({
    resolver: yupResolver(screeningColorValidationSchema()),
    mode: "all",
  });

  const getRandomColorId = useCallback(() => {
    if (!colors) return "";

    const randomIndex = Math.floor(Math.random() * colors.length);

    return colors[randomIndex].key;
  }, [colors]);

  const onSubmit = async (data: ScreeningColorData) => {
    const colorId =
      data.colorId === "random" ? getRandomColorId() : data.colorId;

    const payload = {
      programId: program?._id as string,
      date: data.date as Date,
      categoryId: colorId as string,
      userId: userInfo?._id as string,
      participants: [],
    };

    try {
      await createScreeningColor.mutateAsync(payload, {
        onSuccess: () => {
          onClose();
          showSnackBar("The registry has been saved.", "success");

          createAuditLog.mutate({
            appType: "WEB_BACK_OFFICE",
            module: MODULE_NAME.SCREENING_COLORS,
            option: OPTIONS.SCREENING_COLORS_ADD,
            actionCode: ACTION_CODE.WEB_COLORS_ADD,
            action: "When saving",
            detail: `Added a screening color for day ${dayjs(payload.date).format("DD-MMM-YYYY")}`,
            transactionDate: new Date(),
            accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
            programId: payload.programId,
            createdBy: userInfo?._id as string,
          });
        },
        onError: (error: ErrorResponse) => {
          showSnackBar(
            (Array.isArray(error.message) ? error.message[0] : error.message) ||
              error.error ||
              "An error occurred"
          );
        },
      });
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const program = useMemo(() => {
    if (!programs) return null;

    return programs.find((program) => program._id === programId);
  }, [programs, programId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={false}
      title="Screening color"
      theme={theme}
      size={{
        width: "100%",
        minHeight: 440,
        maxHeight: 530,
        maxWidth: 430,
      }}
      content={
        <Box sx={{ width: "100%", minHeight: 380, padding: "20px 0" }}>
          <Grid container spacing={"20px"}>
            <Grid item xs={12} sm={12}>
              <Typography variant="bodyLarge" fontWeight={700}>
                Program: {program?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="bodyLarge">
                Assign one color to a date:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <div>
                    <Datepicker
                      {...field}
                      label="Date"
                      required={true}
                      onChange={(value) => {
                        field.onChange(
                          value !== undefined ? value?.toDate() : undefined
                        );
                      }}
                      value={
                        field.value
                          ? dayjs(field.value as string | number | Date | Dayjs)
                          : null
                      }
                      error={!!errors.date}
                      helperText={errors.date?.message || ""}
                      disableFuture={false}
                    />
                  </div>
                )}
              />
            </Grid>
            {coloringModule ? (
              <Grid item xs={12} sm={12}>
                <Controller
                  name="colorId"
                  control={control}
                  defaultValue={
                    coloringModule && coloringRequired ? "random" : ""
                  }
                  render={({ field }) => (
                    <Select
                      handleChange={(value) => field.onChange(value)}
                      data={[{ key: "random", value: "(Random)" }, ...colors]}
                      value={field.value as string}
                      label="Screening Color"
                      id="participant-screening-color"
                      isLoading={isLoadingCategory}
                      showClearOption={true}
                      renderValue={(selected) => {
                        if (selected === "random") return "(Random)";

                        return (
                          <div className="flex gap-2.5">
                            <div
                              className="rounded-full w-6 h-6"
                              style={{
                                backgroundColor: colors.find(
                                  (color) => color.key === selected
                                )?.hexaColor,
                              }}
                            ></div>
                            {
                              colors.find((color) => color.key === selected)
                                ?.value
                            }
                          </div>
                        );
                      }}
                      error={!!errors.colorId}
                      errorMessage={errors.colorId?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      }
      actions={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variantType="cancel"
            onClick={onClose}
            disabled={createScreeningColor.isPending}
            label="CANCEL"
          />
          <Button
            variantType="save"
            onClick={async () => {
              handleSubmit(onSubmit)();
            }}
            disabled={createScreeningColor.isPending}
            isLoading={createScreeningColor.isPending}
            label="SAVE"
          />
        </Box>
      }
    />
  );
};

export default ScreeningColorAddModal;
