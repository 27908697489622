import dayjs, { Dayjs } from "dayjs";

export const addOrdinalSuffixToDay = (date: Dayjs) => {
  const day = date.date();
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatMessageTime = (
  timestamp: number,
  format: string = "hh:mm A"
): string => {
  return dayjs(timestamp).format(format);
};

export const formatDayHeader = (timestamp: number) => {
  return dayjs(timestamp).format("D MMM, YYYY");
};

export const normalizeTimestamp = (
  timestamp: number | Date | undefined
): number => {
  if (!timestamp) {
    console.error("Invalid timestamp:", timestamp);
    return 0;
  }
  if (timestamp instanceof Date) {
    return timestamp.getTime();
  }
  return Math.round(timestamp * 1000);
};

export const isNewDay = (
  currentTimestamp: number,
  previousTimestamp?: number
) => {
  const currentDate = dayjs.unix(currentTimestamp).startOf("day");
  const previousDate = previousTimestamp
    ? dayjs.unix(previousTimestamp).startOf("day")
    : null;

  return !previousDate || !currentDate.isSame(previousDate, "day");
};

export const formatDateSeparator = (timestamp: number) => {
  const date = dayjs.unix(timestamp);
  return `${date.format("MMMM")} ${addOrdinalSuffixToDay(date)}, ${date.year()}`;
};
