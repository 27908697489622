import { RoleType } from "../domain/user.enum";

export interface Role {
  _id: string;
  name: string;
  type: string;
}

export interface GroupedRole {
  title: string;
  roles: {
    _id: string;
    name: string;
    type: string;
  }[];
}

export const groupRoles = (roles: Role[]): { [key: string]: GroupedRole } => {
  if (!roles || roles.length === 0) return {};

  return roles.reduce(
    (acc, role: Role) => {
      const typeKey = role.type;
      const title = typeKey
        .replace(/[_-]/g, " ")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase());

      if (!acc[typeKey]) {
        acc[typeKey] = {
          title: title,
          roles: [],
        };
      }

      acc[typeKey].roles.push({
        _id: role._id,
        name: role.name,
        type: role.type,
      });

      return acc;
    },
    {} as { [key: string]: GroupedRole }
  );
};

export const sortGroupedRoles = (groupedRoles: {
  [key: string]: GroupedRole;
}): GroupedRole[] => {
  const order = [
    RoleType.StandardSystemRole,
    RoleType.CustomRole,
    RoleType.StandardAccountRole,
  ];

  return Object.values(groupedRoles).sort((a, b) => {
    return (
      order.indexOf(a.title as RoleType) - order.indexOf(b.title as RoleType)
    );
  });
};

export const showGroupRolesByUserRole = ({
  rolesArray,
  userRole,
}: {
  rolesArray: GroupedRole[];
  userRole: string;
}) => {
  if (userRole) {
    if (
      rolesArray.some((group) =>
        group.roles.some(
          (role) => role.name === "Sysadmin" && role._id === userRole
        )
      )
    ) {
      return rolesArray;
    } else if (
      rolesArray.some((group) =>
        group.roles.some(
          (role) =>
            (role.name === "Support" || role.name === "Account Admin") &&
            role._id === userRole
        )
      )
    ) {
      rolesArray = rolesArray.map((group) => {
        if (group.roles.some((role) => role.type === "STANDARD_SYSTEM_ROLE")) {
          return {
            ...group,
            roles: group.roles.filter((role) => role.name === "Account Admin"),
          };
        }
        return group;
      });
    } else {
      rolesArray = rolesArray.filter(
        (group) =>
          !group.roles.some((role) => role.type === "STANDARD_SYSTEM_ROLE")
      );
    }
  }

  return rolesArray;
};
