import React, { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataTable, { createDataRow, HeadCell } from "@/components/DataTable";
import { usePageStore } from "@/hooks/usePageStore";
import { useAppTheme } from "@/utils/theme";
import {
  useDeleteScreeningColor,
  useSearchScreeningColors,
} from "@/hooks/useScreeningColors";
import { useCategoryByType } from "@/hooks/useCategories";
import { CategoryElement } from "@/domain/category.interface";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ActionMenu from "@/components/ActionMenu";
import {
  defaultFilters,
  Filters,
  ScreeningColorsFilters,
} from "@/ui/content/screeningColors/filters";
import Header from "@/ui/content/screeningColors/header";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import AuditLogModal from "@/ui/modals/AuditLogModal";
import ScreeningColorAddModal from "@/ui/modals/screening-colors/ScreeningColorAddModal";
import { ScreeningColor } from "@/domain/screening-colors.interface";
import ScreeningColorEditModal from "@/ui/modals/screening-colors/ScreeningColorEditModal";
import GenericAlertModal from "@/components/AlertModal";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useTranslation } from "react-i18next";
import { useGetUserPrograms } from "@/hooks/usePrograms";

const ScreeningColorsPage: FC = () => {
  const theme = useAppTheme();
  const { t } = useTranslation(["clients", "common"]);
  const { setPageTitle } = usePageStore();
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const { userInfo, userHasAccessToCode, hasSystemAdminRole } = useUserStore();
  const createAuditLog = useCreateAuditlog();
  const auditLogViewCalledRef = React.useRef(false);
  const [openAuditLogModal, setOpenAuditLogModal] = useState(false);
  const { showSnackBar, showSimpleAlert } = useFeedBack();

  const [genericAlert, setGenericAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => new Object(),
  });

  const deleteScreeningColor = useDeleteScreeningColor();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<{
    open: boolean;
    screeningColor: ScreeningColor | null;
  }>({
    open: false,
    screeningColor: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState<{
    open: boolean;
    screeningColor: ScreeningColor | null;
  }>({
    open: false,
    screeningColor: null,
  });

  const { programs, isLoadingPrograms, isFetchingPrograms } =
    useGetUserPrograms({
      userId: userInfo?._id as string,
      hasSystemAdminRole,
    });

  const { category } = useCategoryByType({
    type: "COLORS",
    status: "ACTIVE",
  });

  const colors = useMemo(() => {
    if (!category) return [];

    return category?.categories.map((categoryElement: CategoryElement) => {
      const name = categoryElement.languages.find(
        (language) => language.language === "en-us"
      )?.name as string;

      return {
        key: categoryElement._id,
        value: name,
        hexaColor: categoryElement.hexaColor,
      };
    });
  }, [category]);

  const {
    screeningColors,
    isLoadingScreeningColors,
    isFetchingScreeningColors,
    refetchScreeningColors,
  } = useSearchScreeningColors({
    sortField: filters.sortField,
    sortOrder: filters.sortOrder,
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize,
    programId: filters.programId,
    startDate: filters.startDate.toISOString(),
    endDate: filters.endDate.toISOString(),
  });

  const handleInsufficientAccess = () => {
    setGenericAlert({
      open: true,
      title: "Insufficient permissions",
      message: t("client_profile.insufficient_permissions_execute"),
      onConfirm: () => {
        setGenericAlert((prevState) => ({ ...prevState, open: false }));
        return {};
      },
    });
  };

  const rows = useMemo(() => {
    if (!screeningColors || screeningColors.list.length === 0) return [];

    return screeningColors.list.map((item, index) => {
      const colorMatch = colors?.find((color) => color.key === item.categoryId);

      const isPastDate = dayjs(item.date).isBefore(dayjs(), "day");

      const actionMenu = [
        {
          value: "Edit",
          onClick: () => {
            if (userHasAccessToCode("COLOR_EDIT")) {
              setShowEditModal({ open: true, screeningColor: item });
            } else {
              handleInsufficientAccess();
            }
          },
          icon: <EditIcon fontSize="small" />,
        },
        {
          value: "Delete",
          onClick: () => {
            if (isPastDate) {
              showSimpleAlert(
                "The color cannot be deleted for past days.",
                "error"
              );
              return;
            }

            if (userHasAccessToCode("COLOR_DELETE")) {
              setShowDeleteModal({ open: true, screeningColor: item });
            } else {
              handleInsufficientAccess();
            }
          },
          icon: <DeleteOutlineIcon fontSize="small" />,
          sx: {
            color: theme.palette.error.main,
          },
        },
      ];
      const row = createDataRow(index, {
        date: (
          <div>{dayjs(item?.date, "YYYY-MM-DD").format("DD MMM YYYY")}</div>
        ),
        color: colorMatch ? (
          <div className="flex items-center">
            <Box
              sx={{
                bgcolor: colorMatch?.hexaColor,
                width: 16,
                height: 16,
                borderRadius: "50%",
                marginRight: 1,
              }}
            />
            <span>{colorMatch?.value}</span>
          </div>
        ) : (
          <div>Not assigned</div>
        ),
        action: <ActionMenu id={item._id} options={actionMenu} />,
      });
      return row;
    });
  }, [screeningColors]);

  const headCells: HeadCell[] = [
    {
      id: "date",
      isSortable: true,
      disablePadding: false,
      label: "Date",
      align: "left",
      width: "30%",
    },
    {
      id: "color",
      isSortable: true,
      disablePadding: false,
      label: "Color assigned",
      align: "left",
      width: "65%",
    },
    {
      id: "actions",
      isSortable: false,
      disablePadding: true,
      label: "Actions",
      align: "right",
    },
  ];

  const handleAddClick = () => setShowAddModal(true);

  const handleAuditLogClick = () => {
    if (userHasAccessToCode("COLOR_AUDIT_LOG")) {
      setOpenAuditLogModal(true);
    } else {
      handleInsufficientAccess();
    }
  };

  useEffect(() => {
    setPageTitle("Screening colors");
  }, []);

  useEffect(() => {
    if (userInfo && !auditLogViewCalledRef.current) {
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.SCREENING_COLORS,
        option: OPTIONS.SCREENING_COLORS_VIEW,
        actionCode: ACTION_CODE.WEB_COLORS_LIST_VIEW,
        action: "When entering",
        detail: "Viewed the screening colors list",
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
      });
      auditLogViewCalledRef.current = true;
    }
  }, [userInfo]);

  const handleDelete = async () => {
    await deleteScreeningColor.mutateAsync(
      showDeleteModal.screeningColor?._id as string,
      {
        onSuccess: () => {
          createAuditLog.mutate({
            appType: "WEB_BACK_OFFICE",
            module: MODULE_NAME.SCREENING_COLORS,
            option: OPTIONS.SCREENING_COLORS_DELETE,
            actionCode: ACTION_CODE.WEB_COLORS_DELETE,
            action: "When deleting",
            detail: `Deleted screening color for day ${dayjs().format("DD-MMM-YYYY")}`,
            transactionDate: new Date(),
            accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
            programId: showDeleteModal.screeningColor?.programId,
            createdBy: userInfo?._id as string,
          });

          refetchScreeningColors();
          setShowDeleteModal({ open: false, screeningColor: null });
          showSnackBar("The registry has been deleted.", "success");
        },
      }
    );
  };

  return (
    <>
      <Box>
        <Card sx={{ width: "100%", borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <Header
              handleAddClick={handleAddClick}
              handleAuditLogClick={handleAuditLogClick}
              handleInsufficientPermissions={handleInsufficientAccess}
            />
            <ScreeningColorsFilters
              filtersState={[filters, setFilters]}
              programs={programs}
              isLoadingPrograms={isLoadingPrograms}
              isFetchingPrograms={isFetchingPrograms}
            />

            <DataTable
              data={rows}
              headCells={headCells}
              order={filters.sortOrder === 1 ? "asc" : "desc"}
              orderBy={filters.sortField}
              rowsPerPage={filters.pageSize}
              page={filters.pageNumber}
              total={screeningColors?.totalCount}
              isLoading={
                isLoadingScreeningColors ||
                isFetchingScreeningColors ||
                isLoadingPrograms ||
                isFetchingPrograms
              }
              onChangePage={(event, newPage) => {
                setFilters((filters) => ({
                  ...filters,
                  pageNumber: newPage,
                }));
              }}
              onChangeSize={(event) => {
                setFilters((filters) => ({
                  ...filters,
                  pageNumber: 0,
                  pageSize: Number(event.target.value),
                }));
              }}
              onChangeSort={(order, orderBy) => {
                setFilters((filters) => ({
                  ...filters,
                  pageNumber: 0,
                  sortField: orderBy as string,
                  sortOrder: order === "asc" ? 1 : -1,
                }));
              }}
              messageEmptyData={
                "No colors assigned for the selected program and date range."
              }
            />
          </CardContent>
        </Card>
      </Box>

      {openAuditLogModal ? (
        <AuditLogModal
          open={openAuditLogModal}
          onClose={() => setOpenAuditLogModal(false)}
          module={MODULE_NAME.SCREENING_COLORS}
          option={OPTIONS.SCREENING_COLORS_AUDIT_LOG_VIEW}
          actionCode={ACTION_CODE.WEB_COLORS_AUDIT_LOG_VIEW}
          detail="Viewed the screening colors audit log"
          action="When entering"
        />
      ) : null}

      {showAddModal && (
        <ScreeningColorAddModal
          open={showAddModal}
          programId={filters.programId}
          onClose={() => {
            setShowAddModal(false);
            refetchScreeningColors();
          }}
        />
      )}

      {showEditModal.open && (
        <ScreeningColorEditModal
          open={showEditModal.open}
          screeningColor={showEditModal.screeningColor as ScreeningColor}
          onClose={() => {
            setShowEditModal({ open: false, screeningColor: null });
          }}
          refetchScreeningColor={refetchScreeningColors}
        />
      )}

      {showDeleteModal.open && (
        <GenericAlertModal
          title="Delete"
          description="Are you sure you want to delete this registry?"
          theme={theme}
          onCancel={() => {
            setShowDeleteModal({ open: false, screeningColor: null });
          }}
          onClick={handleDelete}
          submitText="Confirm"
          cancelText="Cancel"
        />
      )}

      {genericAlert.open ? (
        <GenericAlertModal
          onClick={() => {
            setGenericAlert((prevState) => ({
              ...prevState,
              open: false,
            }));
          }}
          title={genericAlert.title}
          description={genericAlert.message}
          submitText="Close"
          theme={theme}
        />
      ) : null}
    </>
  );
};

export default ScreeningColorsPage;
