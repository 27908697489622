import React, { useCallback, useEffect } from "react";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { useAppTheme } from "@/utils/theme";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import GoalTemplateStepCard from "./GoalTemplateStepCard";
import { useGetGoalTemplates } from "@/hooks/userGoalTemplates";
import { GoalTemplate } from "@/domain/goal-template.interface";
import { useAddGoal } from "@/hooks/useEnrollments";
import { useUserStore } from "@/hooks/useUserStore";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useTranslation } from "react-i18next";
import useDebounce from "@/hooks/useDebounce";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useParams } from "react-router-dom";
import { useParticipantById } from "@/hooks/useParticipants";
import { useRewardsEngine } from "@/hooks/useRewardsEngine";
import { EventTypeEnum } from "@/services/rewards-engine.service";

const SortableItem = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    "touch-action": "none",
  };

  return (
    <Grid
      item
      xs={12}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </Grid>
  );
};

export type GoalTemplateModalProps = {
  open: boolean;
  onAdd: (steps: Pick<GoalTemplate, "steps">) => void;
  onCancel: () => void;
  enrollmentId: string;
};

export const GoalTemplateModal: FC<GoalTemplateModalProps> = (props) => {
  const theme = useAppTheme();
  const { mutateAsync: saveAuditLog } = useCreateAuditlog();
  const { participantId } = useParams();
  const { data: participantInfo } = useParticipantById(participantId || "");

  const { goalTemplates, isLoadingGoalTemplates } = useGetGoalTemplates();
  const {
    mutateAsync: addGoal,
    error,
    isPending: isSavingGoal,
  } = useAddGoal(props.enrollmentId);
  const { userInfo } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const { t } = useTranslation("clients");
  const [goalTemplateSelected, setGoalTemplateSelected] =
    useState<GoalTemplate | null>(null);

  const { data: goalRewardsDataList } = useRewardsEngine(true, {
    eventType: EventTypeEnum.GOAL_COMPLETION,
  });

  const [goalInput, setGoalInput] = useState<string>("");
  const debouncedGoalInput = useDebounce({
    value: goalInput,
    delay: 150,
  });

  const handleSelectGoalTemplate = (
    _event: React.ChangeEvent<object>,
    newGoal: GoalTemplate | null
  ) => {
    setGoalTemplateSelected(newGoal);
    setSteps(newGoal ? mapGoalTemplateStepsToComponents(newGoal) : []);
  };

  const mapGoalTemplateStepsToComponents = useCallback(
    (goalTemplate: GoalTemplate) => {
      return goalTemplate.steps
        .sort((a, b) => a.order - b.order)
        .map((step, index) => ({
          id: `step-${index + 1}`,
          content: (
            <GoalTemplateStepCard
              key={`step-${index + 1}`}
              parcaPoints={step.parcaPoints || 0}
              title={step.name}
              description={`Required validation: ${step.validationType}`}
            />
          ),
          step,
        }));
    },
    []
  );

  const [steps, setSteps] = useState<
    {
      id: string;
      content: React.ReactNode;
      step: GoalTemplate["steps"][number];
    }[]
  >([]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setSteps((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const onAdd = useCallback(async () => {
    const stepsToAdd = steps.map((step, index) => ({
      ...step.step,
      order: index + 1,
    }));

    if (!userInfo || !goalTemplateSelected) {
      throw new Error("User info or goal template id is missing");
    }

    await addGoal({
      ...goalTemplateSelected,
      createdBy: userInfo?._id,
      steps: stepsToAdd,
    });

    saveAuditLog({
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.PARTICIPANT_GOALS,
      option: OPTIONS.GOAL_FROM_TEMPLATE_TO_PARTICIPANT,
      actionCode: ACTION_CODE.WEB_PART_GOAL_ADDED_TEMPLATE,
      action: "When added goal template",
      detail: `Added goal template ${goalTemplateSelected.name} to ${participantInfo?.participant?.firstName} ${participantInfo?.participant?.lastName}`,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
      targetId: participantInfo?.participant?._id,
      programId: participantInfo?.lastEnrollment.programId,
      orgUnitId: participantInfo?.lastEnrollment.orgUnitId,
    });

    props.onAdd({ steps: stepsToAdd });
  }, [steps, userInfo, goalTemplateSelected]);

  useEffect(() => {
    if (error) {
      showSnackBar(error.message, "error");
    }
  }, [error]);

  return (
    <Modal
      data-testid="goal-template-modal"
      title={t("goals.goal_templates_modal_title")}
      open={props.open}
      content={
        <Grid container paddingBottom={2.5} paddingTop={2.5}>
          <Grid item xs={12}>
            <Autocomplete
              data-testid="goal-template-select"
              value={goalTemplateSelected}
              inputValue={goalInput}
              onInputChange={(_event, newInputValue) =>
                setGoalInput(newInputValue)
              }
              options={goalTemplates.filter(
                (option) => option.status === "ACTIVE"
              )}
              getOptionLabel={(option) => `${option.name}`}
              filterOptions={(options) => {
                return options.filter(
                  (option) =>
                    option.name.search(new RegExp(debouncedGoalInput, "i")) > -1
                );
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box key={key} component="li" {...optionProps}>
                    {
                      <div className="w-full flex horizontal justify-between">
                        <div className="flex">
                          <Typography variant="bodyMedium">
                            {option.name}
                          </Typography>
                        </div>
                        <div className="flex">
                          <Typography variant="bodyMedium">
                            {goalRewardsDataList?.filter(
                              (rc) => rc.event === option.name
                            )[0]?.fixedLadderConfiguration?.[0]?.points ?? 0}
                            PP
                          </Typography>
                        </div>
                      </div>
                    }
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("goals.goal_templates_select")}
                />
              )}
              onChange={handleSelectGoalTemplate}
            />
          </Grid>
          <Grid item container xs={12}>
            <Grid item paddingTop={2.5} paddingBottom={2.5} xs={12}>
              {!!goalTemplateSelected && (
                <Typography
                  variant="labelLarge"
                  style={{ fontWeight: "500" }}
                  color={(theme) => theme.palette.textMain.dark2}
                >
                  {t("goals.goal_templates_steps_included")}
                </Typography>
              )}
            </Grid>

            <Grid item container xs={12} rowGap={1}>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={steps.map((step) => step.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {steps.map((step) => (
                    <SortableItem key={step.id} id={step.id}>
                      {step.content}
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Button
            variantType="cancel"
            label="Cancel"
            onClick={props.onCancel}
            isLoading={isSavingGoal}
          />
          <Button
            variantType="save"
            label="ADD"
            onClick={onAdd}
            isLoading={isSavingGoal}
            disabled={!goalTemplateSelected}
          />
        </>
      }
      isPending={isSavingGoal || isLoadingGoalTemplates}
      onClose={props.onCancel}
      theme={theme}
    ></Modal>
  );
};

export default GoalTemplateModal;
