import { useMutation, useQuery } from "@tanstack/react-query";
import { enrollmentService } from "@/services/enrollments.service";
import {
  Journal,
  RoleSubType,
  SearchJournalsPayload,
  SearchRedeemRequestsPayload,
  UpdateEnrollmentPayload,
  UpdateModulesPayload,
  UpdateProviderModulesPayload,
} from "@/domain/enrollment.interface";
import { CreateGoal } from "@/domain/goal.interface";

export const useGetEnrollment = (enrollmentId: string, enabled = false) => {
  const {
    data: enrollment,
    isLoading: isLoadingEnrollment,
    isError: isErrorEnrollment,
    refetch: refetchEnrollment,
  } = useQuery({
    queryKey: ["get-enrollment", enrollmentId],
    queryFn: () => enrollmentService.findById(enrollmentId),
    staleTime: 1 * 60 * 60 * 1000,
    enabled,
  });

  return {
    enrollment,
    isLoadingEnrollment,
    isErrorEnrollment,
    refetchEnrollment,
  };
};

export const useUpdateEnrollment = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      enrollmentData,
    }: UpdateEnrollmentPayload) => {
      await enrollmentService.updateEnrollment(enrollmentId, enrollmentData);
    },
  });
};

export const useUpdateProviderModules = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      providerId,
      modulesIds,
    }: UpdateProviderModulesPayload) => {
      await enrollmentService.updateProviderModules(
        enrollmentId,
        providerId,
        modulesIds
      );
    },
  });
};

export const useUpdateSupportStaffModules = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      providerId,
      modulesIds,
      providerType,
    }: UpdateModulesPayload) => {
      await enrollmentService.updateSupportStaffModules(
        enrollmentId,
        providerId,
        modulesIds,
        providerType
      );
    },
  });
};

export const useRemoveSupportStaff = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      providerId,
      providerType,
    }: {
      enrollmentId: string;
      providerId: string;
      providerType: RoleSubType;
    }) => {
      await enrollmentService.removeSupportStaff(
        enrollmentId,
        providerId,
        providerType
      );
    },
  });
};

export const useUpdateAdditionalOfficers = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      additionalOfficerIds,
    }: {
      enrollmentId: string;
      additionalOfficerIds: string[];
    }) => {
      await enrollmentService.updateAdditionalOfficers(
        enrollmentId,
        additionalOfficerIds
      );
    },
  });
};

export const useAddGoal = (enrollmentId: string) => {
  return useMutation({
    mutationFn: async (goal: CreateGoal) => {
      await enrollmentService.addGoal(enrollmentId, goal);
    },
  });
};

export const useListRedeemRequests = (
  enrollmentId: string,
  enabled = false
) => {
  const {
    data: redeemRequests,
    isLoading: isLoadingRedeemRequests,
    isError: isErrorRedeemRequests,
    refetch: refetchRedeemRequests,
  } = useQuery({
    queryKey: ["list-redeem-requests", enrollmentId],
    queryFn: () => enrollmentService.listRedeemRequests(enrollmentId),
    enabled,
  });

  return {
    redeemRequests,
    isLoadingRedeemRequests,
    isErrorRedeemRequests,
    refetchRedeemRequests,
  };
};

export const useGetEnrollmentBalance = (
  enrollmentId: string,
  enabled = false
) => {
  const {
    data: enrollmentBalance,
    isLoading: isLoadingEnrollmentBalance,
    isError: isErrorEnrollmentBalance,
    refetch: refetchEnrollmentBalance,
  } = useQuery({
    queryKey: ["get-enrollment-balance", enrollmentId],
    queryFn: () => enrollmentService.getEnrollmentBalance(enrollmentId),
    enabled,
  });

  return {
    enrollmentBalance,
    isLoadingEnrollmentBalance,
    isErrorEnrollmentBalance,
    refetchEnrollmentBalance,
  };
};

export const useMarkRedeemRequestViewed = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      ids,
    }: {
      enrollmentId: string;
      ids: string[];
    }) =>
      await enrollmentService.updateRedeemRequestIsViewed(enrollmentId, ids),
  });
};

export const useApproveRedeemRequest = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      redeemRequestId,
      approvedBy,
    }: {
      enrollmentId: string;
      redeemRequestId: string;
      approvedBy: string;
    }) =>
      await enrollmentService.approveRedeemRequest(
        enrollmentId,
        redeemRequestId,
        approvedBy
      ),
  });
};

export const useRejectRedeemRequest = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      redeemRequestId,
      rejectedBy,
      rejectedComment,
    }: {
      enrollmentId: string;
      redeemRequestId: string;
      rejectedBy: string;
      rejectedComment: string;
    }) =>
      await enrollmentService.rejectRedeemRequest(
        enrollmentId,
        redeemRequestId,
        rejectedBy,
        rejectedComment
      ),
  });
};

export const useSearchRedeemRequests = ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  status,
  enrollmentId,
}: SearchRedeemRequestsPayload) => {
  const {
    data: redeemRequests,
    isLoading: isLoadingRedeemRequests,
    isFetching: isFetchingRedeemRequests,
    refetch: refetchRedeemRequests,
  } = useQuery({
    queryKey: ["parca-redeem-requests-search", enrollmentId],
    queryFn: () =>
      enrollmentService.searchRedeemRequests({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        status,
        enrollmentId,
      }),
    enabled: enrollmentId !== "",
  });

  return {
    redeemRequests,
    isLoadingRedeemRequests,
    isFetchingRedeemRequests,
    refetchRedeemRequests,
  };
};

export const useSearchJournals = ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  enrollmentId,
}: SearchJournalsPayload) => {
  const {
    data: journals,
    isLoading: isLoadingJournals,
    isFetching: isFetchingJournals,
    refetch: refetchJournals,
  } = useQuery({
    queryKey: ["parca-journals-search", enrollmentId, pageNumber, pageSize],
    queryFn: () =>
      enrollmentService.searchJournals({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        enrollmentId,
      }),
    enabled: enrollmentId !== "",
  });

  return {
    journals,
    isLoadingJournals,
    isFetchingJournals,
    refetchJournals,
  };
};

export const useUpdateJournal = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      journalId,
      body,
    }: {
      enrollmentId: string;
      journalId: string;
      body: Partial<Journal>;
    }) => await enrollmentService.updateJournal(enrollmentId, journalId, body),
  });
};

export const useAddCasePlan = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      file,
    }: {
      enrollmentId: string;
      file: File;
    }) => await enrollmentService.addCasePlan(enrollmentId, file),
  });
};

export const useGetPlans = (enrollmentId: string) => {
  const {
    data: casePlans,
    isLoading: isLoadingCasePlans,
    isError: isErrorCasePlans,
    refetch: refetchCasePlans,
  } = useQuery({
    queryKey: ["get-case-plans", enrollmentId],
    queryFn: () => enrollmentService.getCasePlans(enrollmentId),
  });

  return {
    casePlans,
    isLoadingCasePlans,
    isErrorCasePlans,
    refetchCasePlans,
  };
};

export const useRemoveCasePlan = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      casePlanId,
    }: {
      enrollmentId: string;
      casePlanId: string;
    }) => {
      await enrollmentService.removeCasePlan(enrollmentId, casePlanId);
    },
  });
};

export const useSignedLinks = ({
  enrollmentId,
  keys,
}: {
  enrollmentId: string;
  keys: string[];
}) => {
  const {
    data: signedLinks,
    isLoading: isLoadingSignedLinks,
    isError: isErrorSignedLinks,
    refetch: refetchSignedLinks,
  } = useQuery({
    queryKey: ["parca-signed-links", enrollmentId, ...keys],
    queryFn: () => enrollmentService.getSignedLinks(enrollmentId, keys),
    enabled: !!enrollmentId && keys?.length > 0,
  });
  return {
    signedLinks,
    isLoadingSignedLinks,
    isErrorSignedLinks,
    refetchSignedLinks,
  };
};
