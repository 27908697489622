import React, { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@/components/Select/Select";
import { useAppTheme } from "@/utils/theme";
import { useRoles } from "@/hooks/useRoles";

export type Filters = {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  search: string;
  status: string[];
  roleId: string;
};

type Props = {
  filtersState: [Filters, React.Dispatch<React.SetStateAction<Filters>>];
};

const UserFilters: FC<Props> = ({ filtersState }) => {
  const theme = useAppTheme();
  const [filters, setFilters] = filtersState;

  const { roles, isLoadingRoles } = useRoles();

  const rolesOptions = useMemo(() => {
    if (!roles) return [];

    return (
      roles?.map((role) => ({
        key: role._id,
        value: role.name,
      })) || []
    );
  }, [roles]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        width: "100%",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.surface.light2,
          px: 2,
          width: {
            xs: "100%",
            sm: "100%",
          },
        }}
        className="flex rounded-lg py-3 max-w-full items-center justify-between h-14"
      >
        <InputBase
          className="flex-1"
          placeholder="Search participant"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            setFilters((filters) => ({
              ...filters,
              search: e.target.value,
              pageNumber: 0,
            }));
          }}
          value={filters.search}
        />
        <SearchIcon className="text-icon-primary" />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          width: {
            xs: "100%",
            sm: "100%",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
          },
        }}
      >
        <Select
          handleChange={(value) => {
            setFilters((filters) => ({
              ...filters,
              roleId: value as string,
              pageNumber: 0,
            }));
          }}
          data={rolesOptions || []}
          value={filters.roleId}
          label="Roles"
          id="users-roles"
          isLoading={isLoadingRoles}
          maxWidth={320}
          showClearOption
          fullWidth
        />

        <Select
          handleChange={(value) => {
            setFilters((filters) => ({
              ...filters,
              status: value as string[],
              pageNumber: 0,
            }));
          }}
          data={[
            { key: "ACTIVE", value: "Active" },
            { key: "INACTIVE", value: "Inactive" },
            { key: "PENDING", value: "Pending activation" },
          ]}
          value={filters.status[0] === "" ? [] : filters.status}
          label="Status"
          id="participant-status"
          multiple
          maxWidth={320}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default UserFilters;
