export interface Module {
  _id: string;
  accountId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  createdBy: string;
  isDeleted: boolean;
}

export enum PROVIDER_MODULE_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
