import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Chip,
  ChipOwnProps,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  TablePagination,
  Theme,
  Tooltip,
} from "@mui/material";
import { useParticipantById } from "@/hooks/useParticipants";
import { useNavigate, useParams } from "react-router-dom";
import { useAppTheme } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import { ParticipantResponse } from "@/domain/participant.interface";
import { usePageStore } from "@/hooks/usePageStore";
import RewardsHeader from "@/ui/content/clients/rewards/RewardsHeader";
import {
  useApproveRedeemRequest,
  useGetEnrollmentBalance,
  useMarkRedeemRequestViewed,
  useSearchRedeemRequests,
} from "@/hooks/useEnrollments";
import {
  RedeemableItem,
  RedeemableItemType,
  RedeemRequest,
  RedeemRequestStatus,
} from "@/domain/enrollment.interface";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import GenericAlertModal from "@/components/AlertModal";
import { useUserStore } from "@/hooks/useUserStore";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { ErrorResponse } from "@/domain/error.interface";
import RejectModal from "@/ui/content/clients/rewards/RejectModal";
import EarningHistoryCard from "@/ui/content/clients/rewards/EarningHistoryCard";
import EarningHeader from "@/ui/content/clients/rewards/EarningHistoryHeader";
import Select from "@/components/Select";
import { useGetEarnings } from "@/hooks/useClients";
import {
  useEarningsFilters,
  defaultFilters as defaultEarningsFilters,
} from "@/providers/EarningsFiltersContext";
import { EventTypeEnum } from "@/services/rewards-engine.service";
import { SearchEarningResponse } from "@/domain/client.interface";
import SkeletonLoader from "@/components/SkeletonLoader";

export const goalBoxStyle: SxProps<Theme> = (theme) => ({
  padding: 3,
  boxShadow: 1,
  borderRadius: 2,
  bgcolor: theme.palette.surface.light,
});

interface Filters {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  status: string[];
}

const defaultFilters: Filters = {
  sortField: "createdAt",
  sortOrder: -1,
  status: [
    RedeemRequestStatus.PENDING_APPROVAL,
    RedeemRequestStatus.APPROVED,
    RedeemRequestStatus.REJECTED,
  ],
  pageNumber: 0,
  pageSize: 5,
};

interface EarningFilterProps {
  status: string[];
  pageSize: number;
  pageNumber: number;
}

interface EarningHistoryProps {
  isLoading: boolean;
  historyData: SearchEarningResponse;
  earningFilters: EarningFilterProps;
  setEarningFilters: (filters: EarningFilterProps) => void;
}

const RewardsPage: React.FC = () => {
  const theme = useAppTheme();
  const { participantId } = useParams();
  const { setPageTitle } = usePageStore();
  const { userInfo, userHasAccessToCode } = useUserStore();
  const navigate = useNavigate();

  const { t } = useTranslation(["clients", "common"]);
  const { showSnackBar } = useFeedBack();

  const [genericAlert, setGenericAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => new Object(),
  });
  const [approveAlert, setApproveAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => new Object(),
  });
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<RedeemRequest | undefined>(
    undefined
  );

  const { data: participantResponse, isLoadingParticipant } =
    useParticipantById(participantId || "");
  const { enrollmentBalance, refetchEnrollmentBalance } =
    useGetEnrollmentBalance(
      participantResponse?.lastEnrollment?._id ?? "",
      !!participantResponse?.lastEnrollment
    );

  useEffect(() => {
    const primaryOfficerId =
      participantResponse?.lastEnrollment?.primaryOfficerId;
    const additionalOfficerIds =
      participantResponse?.lastEnrollment?.additionalOfficerId || [];
    const currentUserId = userInfo?._id;

    if (primaryOfficerId && currentUserId) {
      if (
        primaryOfficerId !== currentUserId &&
        !additionalOfficerIds.includes(currentUserId)
      ) {
        navigate("/403");
      }
    }
  }, [
    participantResponse?.lastEnrollment?.primaryOfficerId,
    participantResponse?.lastEnrollment?.additionalOfficerId,
    userInfo?._id,
    navigate,
  ]);

  const createAuditLog = useCreateAuditlog();
  const markRedeemRequestViewed = useMarkRedeemRequestViewed();
  const approveRedeemRequest = useApproveRedeemRequest();
  const auditLogViewRewardsCalledRef = React.useRef(false);

  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const { filters: earningFilters, setFilters: setEarningFilters } =
    useEarningsFilters();

  const { redeemRequests, isLoadingRedeemRequests, refetchRedeemRequests } =
    useSearchRedeemRequests({
      sortField: filters.sortField,
      sortOrder: filters.sortOrder,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      status: filters.status,
      enrollmentId: participantResponse?.lastEnrollment?._id ?? "",
    });

  const {
    earningsResponse,
    refetchEarnings,
    isLoadingClients: isLoadingEarnings,
  } = useGetEarnings({
    sortField: earningFilters.sortField,
    sortOrder: earningFilters.sortOrder,
    pageNumber: earningFilters.pageNumber,
    pageSize: earningFilters.pageSize,
    search: earningFilters.search,
    status: earningFilters.status,
    programId: earningFilters.programId,
    enrollmentId: participantResponse?.lastEnrollment?._id ?? ("" as string),
  });

  useEffect(() => {
    if (participantResponse?.lastEnrollment?._id) {
      refetchEarnings();
    }
  }, [
    earningFilters.sortField,
    earningFilters.sortOrder,
    earningFilters.pageNumber,
    earningFilters.pageSize,
    earningFilters.status,
    participantResponse?.lastEnrollment?._id,
  ]);

  useEffect(() => {
    if (
      !isLoadingParticipant &&
      !isLoadingRedeemRequests &&
      (redeemRequests?.list || []).length > 0
    ) {
      const ids = (redeemRequests?.list || []).map((item) =>
        item._id.toString()
      );
      if (ids?.length > 0) {
        markRedeemRequestViewed.mutate({
          enrollmentId: participantResponse?.lastEnrollment?._id || "",
          ids,
        });
      }
    }
  }, [participantResponse?.lastEnrollment?._id, redeemRequests?.list]);

  useEffect(() => {
    refetchRedeemRequests();
  }, [
    filters.sortField,
    filters.sortOrder,
    filters.pageNumber,
    filters.pageSize,
    filters.status,
    participantResponse?.lastEnrollment?._id,
  ]);

  useEffect(() => {
    setPageTitle(t("client_profile.page_rewards_title"));
  }, []);

  useEffect(() => {
    if (
      !isLoadingParticipant &&
      userInfo &&
      !auditLogViewRewardsCalledRef.current
    ) {
      const participantFullName = `${participantResponse?.participant?.firstName} ${participantResponse?.participant?.lastName}`;
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANT_REWARDS,
        option: OPTIONS.PARTICIPANT_REWARDS_LIST,
        actionCode: ACTION_CODE.WEB_PART_REWARDS_VIEW,
        action: "When viewing the rewards of participant",
        detail: `Viewed the rewards of ${participantFullName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
        targetId: participantResponse?.participant?._id as string,
        programId: participantResponse?.lastEnrollment.programId,
        orgUnitId: participantResponse?.lastEnrollment.orgUnitId,
      });
      auditLogViewRewardsCalledRef.current = true;
    }
  }, [isLoadingParticipant, userInfo]);

  const handleChipClick = (message: string) => {
    setGenericAlert((prevState) => ({
      ...prevState,
      open: true,
      message,
      title: "Request rejected reason",
    }));
  };

  const onApproveHandler = (item: RedeemRequest) => {
    if (userHasAccessToCode("PARTICIPANT_REWARD_APPROVE")) {
      setApproveAlert({
        open: true,
        title: "Approve request",
        message:
          "Are you sure you want to approve this reward? This action cannot be undone.",
        onConfirm: () => handleApproveRequest(item),
      });
    } else {
      setGenericAlert({
        open: true,
        title: "Insufficient permissions",
        message: t("client_profile.insufficient_permissions_execute"),
        onConfirm: () => {
          setGenericAlert((prevState) => ({ ...prevState, open: false }));
          return {};
        },
      });
    }
  };

  const handleApproveRequest = async (item: RedeemRequest) => {
    setApproveAlert({
      open: false,
      title: "",
      message: "",
      onConfirm: () => ({}),
    });

    try {
      await approveRedeemRequest.mutateAsync(
        {
          redeemRequestId: item._id,
          enrollmentId: participantResponse?.lastEnrollment?._id as string,
          approvedBy: userInfo?._id as string,
        },
        {
          onSuccess: () => {
            showSnackBar("The request has been approved", "success");
            refetchRedeemRequests();
            refetchEnrollmentBalance();

            createAuditLog.mutate({
              appType: "WEB_BACK_OFFICE",
              module: MODULE_NAME.PARTICIPANT_REWARDS,
              option: OPTIONS.PARTICIPANT_REWARD_REDEMPTION,
              actionCode: ACTION_CODE.WEB_PART_REWARD_REDEMPTION_APPROVE,
              action: "When approved reward redemption request",
              detail: `Approved the reward redemption request for ${item?.redeemableItem.description} to ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
              transactionDate: new Date(),
              accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
              createdBy: userInfo?._id as string,
              targetId: participantResponse?.participant._id as string,
              programId: participantResponse?.lastEnrollment?.programId,
              orgUnitId: participantResponse?.lastEnrollment.orgUnitId,
            });
          },
          onError: (error: ErrorResponse) => {
            showSnackBar(
              (Array.isArray(error.message)
                ? error.message[0]
                : error.message) ||
                error.error ||
                "An error occurred"
            );
          },
        }
      );
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const onRejectHandler = (item: RedeemRequest) => {
    if (userHasAccessToCode("PARTICIPANT_REWARD_REJECT")) {
      setSelectedItem(item);
      setShowRejectModal(true);
    } else {
      setGenericAlert({
        open: true,
        title: "Insufficient permissions",
        message: t("client_profile.insufficient_permissions_execute"),
        onConfirm: () => {
          setGenericAlert((prevState) => ({ ...prevState, open: false }));
          return {};
        },
      });
    }
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setEarningFilters(defaultEarningsFilters);
  };

  useEffect(() => {
    resetFilters();
  }, [participantId]);

  return (
    <Box>
      <RewardsHeader
        participantResponse={participantResponse as ParticipantResponse}
        isLoadingParticipant={isLoadingParticipant}
        enrollmentBalance={enrollmentBalance}
      />
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} md={5}>
          <EarningHistory
            isLoading={isLoadingEarnings}
            historyData={earningsResponse as SearchEarningResponse}
            earningFilters={earningFilters}
            setEarningFilters={
              setEarningFilters as unknown as (
                filters: EarningFilterProps
              ) => void
            }
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Card sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                display="flex"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    gap: "10x",
                    flexDirection: "column",
                  }}
                  display="flex"
                >
                  <Typography variant="headlineSmall" component="h1">
                    Redemptions
                  </Typography>
                  <Typography
                    variant="bodyMedium"
                    sx={{
                      color: theme.palette.menu.dark,
                      fontWeight: "600",
                    }}
                  >
                    PARCA POINTS
                  </Typography>
                </Box>

                <Box display="flex" gap="0.5rem">
                  <Tooltip title="Redemptions">
                    <Avatar
                      sx={{
                        backgroundColor: "black",
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <img
                        style={{ maxHeight: "96px", maxWidth: "100%" }}
                        src="/assets/icons/AiOutlineSchedule.svg"
                        alt="Redemptions"
                      />
                    </Avatar>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                  },
                }}
              >
                <Select
                  handleChange={(value) => {
                    setFilters((filters) => ({
                      ...filters,
                      status: value as string[],
                      pageNumber: 0,
                    }));
                  }}
                  data={[
                    {
                      key: RedeemRequestStatus.PENDING_APPROVAL,
                      value: t("client_profile.pending_approval"),
                    },
                    {
                      key: RedeemRequestStatus.APPROVED,
                      value: t("client_profile.approved"),
                    },
                    {
                      key: RedeemRequestStatus.REJECTED,
                      value: t("client_profile.rejected"),
                    },
                  ]}
                  value={filters.status[0] === "" ? [] : filters.status}
                  label="Status"
                  id="participant-status"
                  multiple
                  maxWidth={420}
                  fullWidth
                />
              </Box>
              <SkeletonLoader
                skeletonCount={2}
                skeletonHeight={194}
                isLoading={isLoadingRedeemRequests}
              >
                <Box sx={{}}>
                  {(redeemRequests?.totalCount || 0) === 0 && (
                    <p>There are not redeem requests yet</p>
                  )}

                  {redeemRequests?.list?.map((item, index) => (
                    <RedemptionCard
                      key={index}
                      item={item}
                      onChipClick={(text) => handleChipClick(text ?? "")}
                      onApproveHandler={onApproveHandler}
                      onRejectHandler={onRejectHandler}
                    />
                  ))}
                </Box>
              </SkeletonLoader>
              {(redeemRequests?.totalCount || 0) > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={redeemRequests?.totalCount || 0}
                    rowsPerPage={filters.pageSize}
                    page={filters.pageNumber}
                    onPageChange={(e, newPage) => {
                      setFilters((filters) => ({
                        ...filters,
                        pageNumber: newPage,
                      }));
                    }}
                    onRowsPerPageChange={() =>
                      setFilters((filters) => ({
                        ...filters,
                        pageNumber: 0,
                      }))
                    }
                  />
                </Box>
              )}
            </CardContent>
          </Card>
          {genericAlert.open ? (
            <GenericAlertModal
              onClick={() => {
                setGenericAlert((prevState) => ({
                  ...prevState,
                  open: false,
                }));
              }}
              title={genericAlert.title}
              description={genericAlert.message}
              submitText="Close"
              theme={theme}
            />
          ) : null}
          {approveAlert.open ? (
            <GenericAlertModal
              onCancel={() =>
                setApproveAlert((prevState) => ({
                  ...prevState,
                  open: false,
                }))
              }
              onClick={() => {
                approveAlert.onConfirm();
              }}
              title={approveAlert.title}
              description={approveAlert.message}
              submitText="Accept"
              cancelText="Cancel"
              theme={theme}
            />
          ) : null}
          {showRejectModal ? (
            <RejectModal
              open={showRejectModal}
              onClose={() => setShowRejectModal(false)}
              participant={participantResponse as ParticipantResponse}
              redeemRequest={selectedItem}
              refetch={() => {
                refetchRedeemRequests();
                refetchEnrollmentBalance();
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

const EarningHistory: React.FC<EarningHistoryProps> = ({
  isLoading,
  historyData,
  earningFilters,
  setEarningFilters,
}) => {
  return (
    <Card sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
        }}
      >
        <EarningHeader />
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            width: {
              xs: "80%",
              sm: "80%",
            },
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
            },
          }}
        >
          <Select
            handleChange={(value) => {
              setEarningFilters({
                ...earningFilters,
                status: value as string[],
                pageNumber: 0,
              });
            }}
            data={[
              { key: EventTypeEnum.JOURNAL, value: "Journals" },
              { key: EventTypeEnum.STEP_COMPLETION, value: "Step completions" },
              { key: EventTypeEnum.GOAL_COMPLETION, value: "Goal completions" },
              {
                key: EventTypeEnum.SUPERVISION_ACTIVITY,
                value: "Supervision activities",
              },
              {
                key: EventTypeEnum.IN_HOUSE_SERVICE,
                value: "In-house services",
              },
              {
                key: EventTypeEnum.EXTERNAL_PROVIDER_SERVICE,
                value: "External provider services",
              },
            ]}
            value={earningFilters.status}
            label="Filter by"
            id="participant-status"
            multiple
            maxWidth={360}
            fullWidth
            width={"80vw"}
          />
        </Box>
        {!isLoading && historyData?.earnings?.length === 0 ? (
          <p>No points earned yet</p>
        ) : (
          <SkeletonLoader
            skeletonCount={2}
            skeletonHeight={104}
            isLoading={isLoading}
          >
            {historyData?.earnings.map((item, index) => (
              <EarningHistoryCard
                key={index}
                title={item.description}
                description={item.mechanism ?? ""}
                points={item.points}
                date={dayjs(item.date).format("DD MMM YYYY, hh:mm A")}
              />
            ))}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={historyData?.totalCount || 0}
                rowsPerPage={earningFilters.pageSize}
                page={earningFilters.pageNumber}
                onPageChange={(e, newPage) => {
                  setEarningFilters({
                    ...earningFilters,
                    pageNumber: newPage,
                  });
                }}
                onRowsPerPageChange={() =>
                  setEarningFilters({
                    ...earningFilters,
                    pageNumber: 0,
                  })
                }
              />
            </Box>
          </SkeletonLoader>
        )}
      </CardContent>
    </Card>
  );
};

interface RedemptionCardProps {
  item: RedeemRequest;
  onChipClick?: (text?: string) => void;
  onApproveHandler: (redeemRequest: RedeemRequest) => void;
  onRejectHandler: (redeemRequest: RedeemRequest) => void;
}

type ChipProps = ChipOwnProps["sx"];

const RedemptionCard = ({
  item,
  onChipClick,
  onApproveHandler,
  onRejectHandler,
}: RedemptionCardProps) => {
  const theme = useAppTheme();
  const { t } = useTranslation("clients");

  const statusStyles = useMemo<ChipProps>(() => {
    switch (item.status) {
      case RedeemRequestStatus.PENDING_APPROVAL:
        return {
          borderColor: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
          borderWidth: "1px",
          borderStyle: "solid",
        };
      case RedeemRequestStatus.APPROVED:
        return {
          borderColor: theme.palette.brand.main,
          backgroundColor: theme.palette.brand.light,
          borderWidth: "1px",
          borderStyle: "solid",
        };
      default:
        return {
          borderColor: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
          borderWidth: "1px",
          borderStyle: "solid",
          ":hover": {
            backgroundColor: theme.palette.error.light,
          },
        };
    }
  }, [item.status]);

  const statusText = useMemo(() => {
    switch (item.status) {
      case RedeemRequestStatus.PENDING_APPROVAL:
        return t("client_profile.pending_approval");
      case RedeemRequestStatus.APPROVED:
        return t("client_profile.approved");
      default:
        return t("client_profile.rejected");
    }
  }, [item.status]);

  return (
    <Card
      sx={{ marginBottom: 1, backgroundColor: theme.palette.surface.light2 }}
    >
      <CardContent sx={{ paddingBottom: "16px !important" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10} container>
            <Box display="flex" sx={{ flexDirection: "column", gap: "0" }}>
              <Typography
                variant="titleSmall"
                sx={{ marginBottom: "10px", fontWeight: "700" }}
              >
                Requested benefit: {item.redeemableItem?.description} -{" "}
                {getValuePerRedeemableItem(item.redeemableItem)}
              </Typography>
              <Box display="flex" sx={{ flexDirection: "column", gap: "4px" }}>
                <Typography variant="bodyMedium" color="textSecondary">
                  <Typography
                    variant="bodyMedium"
                    component="span"
                    sx={{ fontWeight: "600" }}
                  >
                    Points required:
                  </Typography>{" "}
                  {item.parcaPoints * item.quantity}
                </Typography>
                <Typography variant="bodyMedium" color="textSecondary">
                  <Typography
                    variant="bodyMedium"
                    component="span"
                    sx={{ fontWeight: "600" }}
                  >
                    Quantity:
                  </Typography>{" "}
                  {item.quantity}
                </Typography>
                <Box display="flex" sx={{ alignItems: "center" }}>
                  <Typography
                    variant="bodyMedium"
                    color="textSecondary"
                    sx={{ fontWeight: "600" }}
                  >
                    Status:
                  </Typography>
                  <Chip
                    {...(item.status === RedeemRequestStatus.REJECTED
                      ? {
                          clickable: true,
                          onClick: () =>
                            onChipClick &&
                            onChipClick(
                              `Rejected by: ${item?.rejectedByOfficer}\nReason: ${item?.rejectedComment}`
                            ),
                        }
                      : {})}
                    label={statusText}
                    sx={{ marginLeft: "5px", ...statusStyles }}
                  />
                </Box>
              </Box>
              <Typography
                variant="labelSmall"
                color="textSecondary"
                sx={{ fontWeight: 500 }}
              >
                {`${dayjs(item.processDate).format("DD MMM YYYY, hh:mm A")}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} container direction="column" alignItems="flex-end">
            {item.status === RedeemRequestStatus.PENDING_APPROVAL && (
              <ApproveRejectMenu
                onApprove={() => onApproveHandler(item)}
                onReject={() => onRejectHandler(item)}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

interface ApproveRejectMenuProps {
  onApprove: () => void;
  onReject: () => void;
}

export function ApproveRejectMenu({
  onApprove,
  onReject,
}: ApproveRejectMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useAppTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApproveClick = () => {
    handleClose();
    onApprove();
  };

  const handleRejectClick = () => {
    handleClose();
    onReject();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          width: 40,
          height: 40,
          borderRadius: 2,
          backgroundColor: "white",
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleApproveClick}>
          <ListItemIcon>
            <img
              style={{ width: "24px", height: "24px" }}
              src="/assets/icons/BiCheckCircle.svg"
              alt="Redemptions"
            />
          </ListItemIcon>
          <ListItemText
            primary="Approve"
            sx={{ color: theme.palette.textMain.dark2 }}
          />
        </MenuItem>
        <MenuItem onClick={handleRejectClick}>
          <ListItemIcon>
            <img
              style={{ width: "24px", height: "24px" }}
              src="/assets/icons/AiOutlineCloseCircle.svg"
              alt="Redemptions"
            />
          </ListItemIcon>
          <ListItemText
            primary="Reject"
            sx={{ color: theme.palette.textMain.dark2 }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

function getValuePerRedeemableItem(item: RedeemableItem) {
  if (item.type === RedeemableItemType.TIME_REDUCTION) {
    return `${item.unit} ${item.postUnitText}`;
  }

  if (item.type === RedeemableItemType.MONEY_REDUCTION) {
    return `${item.preUnitText}${item.unit}${item.postUnitText}`;
  }

  if (item.type === RedeemableItemType.REMOTE_APPOINTMENT) {
    return "";
  }
}
export default RewardsPage;
