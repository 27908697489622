import { ISettingResponse } from "../domain/setting.interface";
import { axios } from "../utils/axios.create";

const findAll = async (): Promise<ISettingResponse> => {
  return axios
    .get("/settings?application=backoffice")
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

const findByName = async (settingName: string): Promise<ISettingResponse> => {
  return axios
    .get(`/settings?application=backoffice&settingName=${settingName}`)
    .then((res) => {
      const settingName = res.data.body;

      if (!settingName) {
        return Promise.reject("Setting not found");
      }

      return settingName;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const settingService = {
  findAll,
  findByName,
};
