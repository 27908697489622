import { useQuery } from "@tanstack/react-query";
import { ChimeSDKMessagingClient } from "@aws-sdk/client-chime-sdk-messaging";
import { ChimeService } from "@/services/chime.service";

export const useMessagingClient = (userId: string) => {
  const {
    data: messagingClient,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["messagingClient", userId],
    queryFn: async () => {
      const credentials = await ChimeService.getChimeCredentials(userId);

      return new ChimeSDKMessagingClient({
        region: credentials.chatRegion,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      });
    },
    staleTime: 30 * 60 * 1000, // 30 min since we don't want to expire before the session ends
    enabled: !!userId,
    retry: 2,
  });

  return { messagingClient, isFetching, error };
};
