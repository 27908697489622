import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common from "./en/common.json";
import clients from "./en/clients.json";
import auditLogs from "./en/auditLogs.json";

export const defaultNS = "common";

i18next.use(initReactI18next).init({
  lng: "en", // if you"re using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      common,
      clients,
      auditLogs,
    },
  },
  defaultNS,
});
