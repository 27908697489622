import { SearchScreeningColorsPayload } from "@/domain/screening-colors.interface";
import { ScreeningColorService } from "@/services/screening-colors.service";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useScreeningColors = ({ status }: { status: string }) => {
  const {
    data: screeningColors,
    isLoading: isLoadingScreeningColors,
    refetch: refetchScreeningColors,
  } = useQuery({
    queryKey: ["screening-colors"],
    queryFn: () => ScreeningColorService.findAll({ status }),
  });

  return {
    screeningColors,
    isLoadingScreeningColors,
    refetchScreeningColors,
  };
};

export const useCurrentDayColor = ({
  date,
  programId,
}: {
  date?: string;
  programId?: string;
}) => {
  const {
    data: currentDayColor,
    isLoading: isLoadingCurrentDayColor,
    refetch: refetchCurrentDayColor,
  } = useQuery({
    queryKey: ["current-day-color", { programId }],
    queryFn: () =>
      ScreeningColorService.getCurrentDayColor({ date, programId }),
    enabled: !!programId,
  });

  return {
    currentDayColor,
    isLoadingCurrentDayColor,
    refetchCurrentDayColor,
  };
};

export const useCreateScreeningColor = () => {
  return useMutation({
    mutationFn: async (screeningColor: {
      categoryId: string;
      date: Date;
      programId: string;
    }) => {
      await ScreeningColorService.create(screeningColor);
    },
  });
};

export const useUpdateScreeningColor = () => {
  return useMutation({
    mutationFn: async (screeningColor: {
      _id: string;
      categoryId: string | null;
      date: Date;
      programId: string;
    }) => {
      await ScreeningColorService.update(screeningColor);
    },
  });
};

export const useDeleteScreeningColor = () => {
  return useMutation({
    mutationFn: async (screeningColorId: string) => {
      await ScreeningColorService.deleteById(screeningColorId);
    },
  });
};

export const useSearchScreeningColors = ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  programId,
  startDate,
  endDate,
}: SearchScreeningColorsPayload) => {
  const {
    data: screeningColors,
    isLoading: isLoadingScreeningColors,
    isFetching: isFetchingScreeningColors,
    refetch: refetchScreeningColors,
  } = useQuery({
    queryKey: [
      "parca-screening-colors-search",
      sortField,
      sortOrder,
      pageNumber,
      pageSize,
      programId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      ScreeningColorService.search({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        programId,
        startDate,
        endDate,
      }),
    enabled: programId !== "",
  });

  return {
    screeningColors,
    isLoadingScreeningColors,
    isFetchingScreeningColors,
    refetchScreeningColors,
  };
};
