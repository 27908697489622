import * as yup from "yup";
import { InferType } from "yup";

const screeningColorValidationSchema = () => {
  return yup.lazy(() => {
    const schemaFields: yup.ObjectShape = {
      date: yup.string().required("This field is required"),
      colorId: yup.string().nullable().optional(),
    };

    return yup.object().shape(schemaFields);
  });
};

export type ScreeningColorData = InferType<
  ReturnType<typeof screeningColorValidationSchema>
>;

export default screeningColorValidationSchema;
