import { useMutation, useQuery } from "@tanstack/react-query";
import { AuditLogPayload } from "../domain/auditlog.interface";
import { AuditLogsService } from "../services/auditlogs.service";
import { useEffect, useRef } from "react";
import { MODULE_NAME } from "@/domain/auditlog.enum";

const { create, findByModule } = AuditLogsService;

export const useCreateAuditlog = () => {
  return useMutation({
    mutationFn: (data: AuditLogPayload) => create(data),
  });
};

export const useAuditLogFirstRender = ({
  appType,
  module,
  option,
  actionCode,
  action,
  detail,
  transactionDate,
  accountId,
  createdBy,
  targetId,
}: AuditLogPayload) => {
  const createAuditLog = useCreateAuditlog();
  const auditLogCalledRef = useRef(false);

  useEffect(() => {
    if (!auditLogCalledRef.current && createdBy && detail) {
      auditLogCalledRef.current = true;
      createAuditLog.mutate({
        appType,
        module,
        option,
        actionCode,
        action,
        detail,
        transactionDate,
        accountId,
        createdBy,
        targetId,
      });
    }
  }, [createdBy, detail]);
};

export const useGetAuditLog = (
  module: MODULE_NAME,
  from: string,
  to: string,
  pageNumber: number,
  pageSize: number,
  sortField: string,
  sortOrder: number
) => {
  return useQuery({
    queryKey: [
      "get-audit-log",
      module,
      from,
      to,
      pageNumber,
      pageSize,
      sortField,
      sortOrder,
    ],
    queryFn: () =>
      findByModule({
        module,
        from,
        to,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      }),
  });
};
