import { GoalTemplate } from "@/domain/goal-template.interface";
import { axios, transformAxiosError } from "../utils/axios.create";

const findAll = async (): Promise<Array<GoalTemplate>> => {
  return axios
    .get("/goal-templates")
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const goalTemplatesService = {
  findAll,
};
