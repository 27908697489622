import React, { FC, useMemo, useState } from "react";
import { GoalStepStatus, Step } from "@/domain/goal.interface";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useAppTheme } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { ParticipantResponse } from "@/domain/participant.interface";
import { Typography } from "@mui/material";
import GenericAlertModal from "@/components/AlertModal";
import ViewStep from "@/ui/content/clients/steps/ViewStep";
import PhotoStep from "@/ui/content/clients/steps/PhotoStep";
import RejectedStep from "@/ui/content/clients/steps/RejectedStep";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateStep } from "@/hooks/useGoals";
import * as yup from "yup";
import { useUserStore } from "@/hooks/useUserStore";
import dayjs from "dayjs";

export type RejectedForm = {
  rejectedReason: string;
};

type ClientStepModalProps = {
  open: boolean;
  onClose: () => void;
  participant: ParticipantResponse;
  goalId: string;
  stepData: Step;
  refetchGoals: () => void;
};

const ClientStepModal: FC<ClientStepModalProps> = ({
  open,
  onClose,
  participant,
  goalId,
  stepData,
  refetchGoals,
}) => {
  const { t } = useTranslation("clients");
  const { userInfo } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const theme = useAppTheme();

  const [stepScreen, setStepScreen] = useState<
    "view" | "reject" | "proof" | "photo"
  >("view");

  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [showRejectedAlert, setShowRejectedAlert] = useState(false);
  const [showApprovedAlert, setShowApprovedAlert] = useState(false);

  const updateStep = useUpdateStep();

  const modalTitle = useMemo(() => {
    switch (stepScreen) {
      case "proof":
        return t("goals.title_step_proof");
      case "reject":
        return t("goals.title_step_reject");
      case "photo":
        return t("goals.title_step_photo");
      default:
        return t("goals.title_step_view");
    }
  }, [stepScreen, t]);

  const statusStyles = useMemo(() => {
    switch (stepData.status) {
      case GoalStepStatus.PENDING_APPROVAL:
        return {
          borderColor: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
        };
      case GoalStepStatus.APPROVED:
        return {
          borderColor: theme.palette.brand.dark,
          backgroundColor: theme.palette.brand.light,
        };
      default:
        return {
          borderColor: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
        };
    }
  }, [stepData.status]);

  const statusText = useMemo(() => {
    switch (stepData.status) {
      case GoalStepStatus.PENDING_APPROVAL:
        return t("goals.pending_approval");
      case GoalStepStatus.APPROVED:
        return t("goals.approved");
      default:
        return t("goals.rejected");
    }
  }, [stepData.status]);

  const stepLastSubmissionIndex = useMemo(() => {
    if (stepData.status === GoalStepStatus.REJECTED) {
      const lastRejectedSubmission = stepData.submissions
        .sort(
          (a, b) =>
            dayjs(b.submittedDate).unix() - dayjs(a.submittedDate).unix()
        )
        .find((s) => s.rejectionDate);

      return stepData.submissions.findIndex(
        (submission) => submission._id === lastRejectedSubmission?._id
      );
    }

    return stepData.submissions.length - 1;
  }, [stepData.status, stepData.submissions]);

  const renderContent = () => {
    switch (stepScreen) {
      case "view":
        return (
          <ViewStep
            participant={participant}
            goalId={goalId}
            stepData={stepData}
            statusStyles={statusStyles}
            statusText={statusText}
            setStepScreen={setStepScreen}
            setShowRejectedAlert={setShowRejectedAlert}
            onHandlePhoto={(photo) => setSelectedPhoto(photo)}
            stepLastSubmission={stepLastSubmissionIndex}
          />
        );
      case "reject":
        return <RejectedStep />;
      case "photo":
        return <PhotoStep selectedPhoto={selectedPhoto as string} />;
      default:
        return null;
    }
  };

  const rejectedFormSchema = yup.object().shape({
    rejectedReason: yup.string().required("This field is required"),
  });

  const methods = useForm<RejectedForm>({
    resolver: yupResolver(rejectedFormSchema),
    mode: "all",
  });

  const onSubmit = async (data: RejectedForm) => {
    await updateStep.mutateAsync(
      {
        enrollmentId: participant.lastEnrollment._id,
        goalId: goalId,
        stepId: stepData._id as string,
        submissionId: stepData.submissions[stepLastSubmissionIndex]
          ._id as string,
        data: {
          rejectedBy: userInfo?._id,
          approvedBy: userInfo?._id,
          stepStatus: GoalStepStatus.REJECTED,
          rejectedReason: data.rejectedReason,
        },
      },
      {
        onSuccess: () => {
          showSnackBar("The step has been marked as rejected", "success");
          refetchGoals();
          onClose();
        },
      }
    );
  };

  const onHandleApprove = async () => {
    setShowApprovedAlert(false);

    await updateStep.mutateAsync(
      {
        enrollmentId: participant.lastEnrollment._id,
        goalId: goalId,
        stepId: stepData._id as string,
        submissionId: stepData.submissions[stepLastSubmissionIndex]
          ._id as string,
        data: {
          approvedBy: userInfo?._id,
          rejectedBy: userInfo?._id,
          stepStatus: GoalStepStatus.APPROVED,
          rejectedReason: "",
        },
      },
      {
        onSuccess: () => {
          showSnackBar("The step has been marked as approved", "success");
          refetchGoals();
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (stepScreen === "photo") {
            setStepScreen("view");
          } else {
            onClose();
          }
        }}
        isPending={false}
        title={modalTitle}
        theme={theme}
        size={{
          width: "100%",
          minHeight: 610,
          maxHeight: 620,
          maxWidth: 430,
        }}
        content={
          <FormProvider {...methods}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 0",
                gap: "1.25rem",
              }}
            >
              {renderContent()}
            </Box>
          </FormProvider>
        }
        actions={
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            {stepScreen === "view" && (
              <>
                {stepData.status === GoalStepStatus.PENDING_APPROVAL && (
                  <>
                    <Button
                      variantType="cancel"
                      onClick={() => setStepScreen("reject")}
                      label="REJECT"
                      disabled={updateStep.isPending}
                      isLoading={updateStep.isPending}
                    />
                    <Button
                      variantType="save"
                      onClick={() => setShowApprovedAlert(true)}
                      label="APPROVE"
                      disabled={updateStep.isPending}
                      isLoading={updateStep.isPending}
                    />
                  </>
                )}

                {(stepData.status === GoalStepStatus.APPROVED ||
                  stepData.status === GoalStepStatus.REJECTED) && (
                  <Button variantType="save" onClick={onClose} label="CLOSE" />
                )}
              </>
            )}

            {stepScreen === "photo" && (
              <Button
                variantType="save"
                onClick={() => setStepScreen("view")}
                label={t("goals.button_back")}
              />
            )}

            {stepScreen === "reject" && (
              <>
                <Button
                  variantType="cancel"
                  onClick={() => setStepScreen("view")}
                  label="CANCEL"
                />
                <Button
                  variantType="save"
                  onClick={() => methods.handleSubmit(onSubmit)()}
                  label="CONFIRM"
                />
              </>
            )}
          </Box>
        }
      />

      {showRejectedAlert && (
        <GenericAlertModal
          description={
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="bodyLarge"
                color={theme.palette.textMain.dark2}
                fontWeight={700}
              >
                Rejection reason:
              </Typography>
              <Typography variant="bodyLarge">
                {stepData.submissions[stepLastSubmissionIndex].rejectedReason}
              </Typography>
            </Box>
          }
          submitText="Close"
          onClick={() => setShowRejectedAlert(false)}
          theme={theme}
          style={{
            borderRadius: ".5rem",
            minHeight: "100px",
          }}
        />
      )}

      {showApprovedAlert && (
        <GenericAlertModal
          title="Approve step"
          description="Are you sure you want to approve this step? This action cannot be undone."
          submitText="Confirm"
          cancelText="Cancel"
          onClick={onHandleApprove}
          onCancel={() => setShowApprovedAlert(false)}
          theme={theme}
        />
      )}
    </>
  );
};

export default ClientStepModal;
