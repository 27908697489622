import { OfficerPendingAlertsResponse } from "@/domain/alerts.interface";
import { axios } from "@/utils/axios.create";

const getAlertsByOfficerId = async (
  officerId: string,
  pageSize: number = 5,
  pageNumber: number = 1
): Promise<OfficerPendingAlertsResponse> => {
  return axios
    .get(`/users/${officerId}/alerts`, {
      params: {
        pageSize,
        pageNumber,
        sortField: "alertDate",
        sortOrder: "desc",
      },
    })
    .then((res) => ({
      alerts: res.data.body.alerts || [],
      total: res.data.body.total,
    }))
    .catch((err) => Promise.reject(err));
};

const updateAlertIsViewed = async (alertId: string): Promise<void> => {
  return axios
    .patch(`/alerts/${alertId}/viewed`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
};

export const AlertsService = {
  getAlertsByOfficerId,
  updateAlertIsViewed,
};
