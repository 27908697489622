import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useAppTheme } from "@/utils/theme";
import { Controller } from "react-hook-form";
import Modal from "@/components/Modal";
import { useForm } from "react-hook-form";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { useUserStore } from "@/hooks/useUserStore";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { ParticipantResponse } from "@/domain/participant.interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { useRejectRedeemRequest } from "@/hooks/useEnrollments";
import { RedeemRequest } from "@/domain/enrollment.interface";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { ErrorResponse } from "@/domain/error.interface";

const rejectSchema = (t: TFunction) => {
  const schemaFields: yup.ObjectShape = {
    rejectedReason: yup
      .string()
      .required("This field is required")
      .min(2, t("validations.min_length", { count: 2 }))
      .max(200, t("validations.max_length", { count: 200 })),
  };

  return yup.lazy(() => {
    return yup.object().shape(schemaFields);
  });
};

export type RejectedForm = yup.InferType<ReturnType<typeof rejectSchema>>;

type RejectModalProps = {
  open: boolean;
  onClose: () => void;
  participant: ParticipantResponse;
  redeemRequest?: RedeemRequest;
  refetch: () => void;
};

const RejectModal: FC<RejectModalProps> = ({
  open,
  onClose,
  refetch,
  participant,
  redeemRequest,
}) => {
  const { t } = useTranslation("common");
  const { userInfo } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const theme = useAppTheme();

  const createAuditLog = useCreateAuditlog();
  const rejectRedeemRequest = useRejectRedeemRequest();

  const onSubmit = async (data: RejectedForm) => {
    try {
      await rejectRedeemRequest.mutateAsync(
        {
          redeemRequestId: redeemRequest?._id ?? "",
          enrollmentId: participant?.lastEnrollment?._id as string,
          rejectedBy: userInfo?._id as string,
          rejectedComment: `${data.rejectedReason}`,
        },
        {
          onSuccess: () => {
            showSnackBar("The request has been rejected", "success");
            refetch();

            createAuditLog.mutate({
              appType: "WEB_BACK_OFFICE",
              module: MODULE_NAME.PARTICIPANT_REWARDS,
              option: OPTIONS.PARTICIPANT_REWARD_REDEMPTION,
              actionCode: ACTION_CODE.WEB_PART_REWARD_REDEMPTION_REJECT,
              action: "When rejected reward redemption request",
              detail: `Rejected the reward redemption request for ${redeemRequest?.redeemableItem.description} to ${participant?.participant.firstName} ${participant?.participant.lastName}`,
              transactionDate: new Date(),
              accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
              createdBy: userInfo?._id as string,
              targetId: participant?.participant._id as string,
              programId: participant?.lastEnrollment?.programId,
              orgUnitId: participant?.lastEnrollment.orgUnitId,
            });
            onClose();
          },
          onError: (error: ErrorResponse) => {
            showSnackBar(
              (Array.isArray(error.message)
                ? error.message[0]
                : error.message) ||
                error.error ||
                "An error occurred"
            );
            onClose();
          },
        }
      );
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RejectedForm>({
    resolver: yupResolver(rejectSchema(t)),
    mode: "all",
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        isPending={false}
        title={"Reject request"}
        theme={theme}
        size={{
          width: "100%",
          minHeight: 610,
          maxHeight: 620,
          maxWidth: 430,
        }}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "20px 0",
              gap: "1.25rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <Typography
                  variant="titleSmall"
                  color={theme.palette.icon.dark}
                  fontWeight={700}
                >
                  Result: Rejection
                </Typography>
                <Typography
                  variant="bodyLarge"
                  color={theme.palette.textMain.dark2}
                >
                  Please give the reason for rejecting this reward:
                </Typography>
              </Box>

              <Controller
                name="rejectedReason"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="rejectedReason"
                    label="Comments"
                    variant="outlined"
                    required
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.rejectedReason}
                    helperText={
                      errors.rejectedReason
                        ? errors.rejectedReason.message
                        : null
                    }
                    fullWidth
                    multiline
                    rows={10}
                  />
                )}
              />
            </Box>
          </Box>
        }
        actions={
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              variantType="cancel"
              onClick={() => onClose()}
              label="CANCEL"
            />
            <Button
              variantType="save"
              onClick={handleSubmit(onSubmit)}
              label="CONFIRM"
              disabled={rejectRedeemRequest.isPending}
              isLoading={rejectRedeemRequest.isPending}
            />
          </Box>
        }
      />
    </>
  );
};

export default RejectModal;
