import {
  CreateClientPayload,
  SearchClientsPayload,
  SearchEarningPayload,
} from "@/domain/client.interface";
import { ClientsService } from "@/services/clients.service";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useClients = ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  search,
  status,
  programId,
  userId,
}: SearchClientsPayload) => {
  const {
    data: clients,
    isLoading: isLoadingClients,
    isFetching: isFetchingClients,
    refetch: refetchClients,
  } = useQuery({
    queryKey: [
      "parca-clients",
      sortField,
      sortOrder,
      pageNumber,
      pageSize,
      search,
      status,
      programId,
      userId,
    ],
    queryFn: () =>
      ClientsService.findAll({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        search,
        status,
        programId,
        userId,
      }),
    enabled: programId !== "",
  });

  return {
    clients,
    isLoadingClients,
    isFetchingClients,
    refetchClients,
  };
};

export const useCreateClient = () => {
  return useMutation({
    mutationFn: (data: CreateClientPayload) => ClientsService.create(data),
  });
};

export const useUpdateClient = () => {
  return useMutation({
    mutationFn: (data: {
      participantId: string;
      enrollmentId: string;
      editClient: CreateClientPayload;
    }) =>
      ClientsService.update({
        participantId: data.participantId,
        enrollmentId: data.enrollmentId,
        data: data.editClient,
      }),
  });
};

export const useDismissedClient = () => {
  return useMutation({
    mutationFn: (data: {
      participantId: string;
      enrollmentId: string;
      updatedBy: string;
    }) => ClientsService.dismissed(data),
  });
};

export const useGetEarnings = ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  search,
  status,
  programId,
  enrollmentId,
}: SearchEarningPayload) => {
  const {
    data: earningsResponse,
    isLoading: isLoadingClients,
    isFetching: isFetchingClients,
    refetch: refetchEarnings,
  } = useQuery({
    queryKey: ["parca-earnings"],
    queryFn: () =>
      ClientsService.findAllEarnings({
        sortField,
        sortOrder,
        pageNumber,
        pageSize,
        search,
        status,
        programId,
        enrollmentId,
      }),
    enabled: !!enrollmentId,
  });

  return {
    earningsResponse,
    isLoadingClients,
    isFetchingClients,
    refetchEarnings,
  };
};
