import { axios } from "@/utils/axios.create";
import { ChimeSDKMessagingClient } from "@aws-sdk/client-chime-sdk-messaging";

export const getChimeCredentials = async (
  userId?: string
): Promise<{
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: string;
  chatRegion: string;
}> => {
  return axios
    .get(`/chat/users/${userId}/credentials`)
    .then((res) => res.data.body)
    .catch((error) => {
      console.error("Error getting Chime credentials:", error);
      throw new Error("Error getting Chime credentials");
    });
};

export const setupChat = async (
  officerId: string,
  participantId: string
): Promise<{
  channelArn: string;
  participantArn: string;
  officerArn: string;
  chatRegion: string;
}> => {
  return axios
    .post("/chat", {
      officerId,
      participantId,
    })
    .then((res) => res.data.body)
    .catch(() => {
      return Promise.resolve({});
    });
};

const createMessagingClient = async (
  userId: string
): Promise<ChimeSDKMessagingClient> => {
  try {
    const credentials = await getChimeCredentials(userId);
    return new ChimeSDKMessagingClient({
      region: credentials.chatRegion,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
  } catch (error) {
    console.error("Error creating Chime Messaging Client:", error);
    throw error;
  }
};

export const ChimeService = {
  getChimeCredentials,
  createMessagingClient,
  setupChat,
};
