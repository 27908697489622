import { useMutation, useQuery } from "@tanstack/react-query";
import {
  rewardsEngineService,
  RewardsEngine,
} from "@/services/rewards-engine.service";

export const useRewardsEngine = (
  enabled = false,
  filters?: { eventType?: string; event?: string }
) => {
  return useQuery<RewardsEngine[], Error>({
    queryKey: ["get-rewards-engine", filters],
    queryFn: () => rewardsEngineService.findAll(filters),
    staleTime: 1 * 60 * 60 * 1000,
    enabled,
  });
};

export const useCreateRewardConfig = () => {
  return useMutation({
    mutationFn: async (data: Partial<RewardsEngine>) => {
      const response = await rewardsEngineService.create(data);
      return response;
    },
  });
};
