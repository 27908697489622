import { axios } from "../utils/axios.create";

export enum EventTypeEnum {
  JOURNAL = "JOURNAL",
  STEP_COMPLETION = "STEP_COMPLETION",
  GOAL_COMPLETION = "GOAL_COMPLETION",
  SUPERVISION_ACTIVITY = "SUPERVISION_ACTIVITY",
  IN_HOUSE_SERVICE = "IN_HOUSE_SERVICE",
  EXTERNAL_PROVIDER_SERVICE = "EXTERNAL_PROVIDER_SERVICE",
  REDEEMED = "REDEEMED",
}

export enum RewardConfigurationType {
  FixedLadder = "FIXED_LADDER",
  ProgrammableLadder = "PROGRAMMABLE_LADDER",
}

export interface FixedLadderConfiguration {
  index: number;
  points: number;
}

export interface ProgrammableLadderConfiguration {
  startValue: number;
  stepValue: number;
  maxValue: number;
  resetValue: number;
}

export interface InvalidResult {
  invalidResultType: string;
  rewardForInvalid: string;
  resetRuleType: string;
  specificIndex: number;
}

export interface RewardsEngine {
  _id: string;
  accountId: string;
  programId: string;
  eventType: string;
  event: string;
  rewardConfigurationType: RewardConfigurationType;
  fixedLadderConfiguration?: FixedLadderConfiguration[];
  programmableLadderConfiguration?: ProgrammableLadderConfiguration[];
  invalidResult?: InvalidResult;
  createdAt: string;
  createdBy: string;
  isDeleted: boolean;
}

const findAll = async (
  options: { eventType?: string; event?: string } = {}
): Promise<RewardsEngine[]> => {
  const { eventType, event } = options;
  const params = new URLSearchParams();

  if (eventType) params.append("eventType", eventType);
  if (event) params.append("event", event);

  return axios
    .get(`/rewards-engine?${params.toString()}`)
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(err.response.data));
};

const create = async (data: Partial<RewardsEngine>): Promise<RewardsEngine> => {
  return axios
    .post("/rewards-engine", data)
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(err.response.data));
};

export const rewardsEngineService = {
  findAll,
  create,
};
