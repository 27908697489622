import { Box, Typography } from "@mui/material";
import React from "react";

const ParcaPoints: React.FC<{ points: number | undefined }> = ({ points }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img
        src="/assets/icons/star-icon-green.svg"
        style={{ minHeight: 40, minWidth: 40 }}
      />
      <Typography
        variant="labelLarge"
        fontWeight="500"
        color="black"
        lineHeight={1}
        position="absolute"
      >
        {points || "-"}
      </Typography>
    </Box>
    <Typography
      variant="labelSmall"
      fontWeight="bold"
      color="black"
      lineHeight={1}
      sx={{ marginTop: 1 }}
    >
      PP
    </Typography>
  </Box>
);

export default ParcaPoints;
