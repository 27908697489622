import { GoalTemplate } from "./goal-template.interface";
import { UserType } from "./user.interface";

export interface CreateGoal extends Omit<GoalTemplate, "_id"> {
  createdBy: string;
}

export enum GoalStatus {
  PENDING_STEPS = "PENDING_STEPS",
  COMPLETED_STEPS = "COMPLETED_STEPS",
  PENDING_APPROVAL_STEPS = "PENDING_APPROVAL_STEPS",
}

export enum GoalStepStatus {
  PENDING_SUBMIT = "PENDING_SUBMIT",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface ValidationProof {
  validationType: string;
  documentName: string;
  urlDocument: string;
  parcaPoints: number;
}

export interface Submission {
  _id?: string;
  submittedDate: Date;
  approvedDate?: Date;
  approvedBy?: UserType;
  rejectionDate?: Date;
  rejectedBy?: UserType;
  rejectedReason?: string;
  comments?: string;
  validationProofs: ValidationProof[];
}

export interface Step {
  _id?: string;
  name: string;
  status: GoalStepStatus;
  creationDate: Date;
  parcaPoints?: number;
  order: number;
  validationType: string;
  submissions: Submission[];
  goalId?: string;
}

export interface Goal {
  _id?: string;
  accountId: string;
  name: string;
  icon: string;
  status: GoalStatus;
  creationDate: Date;
  completionDate?: Date;
  parcaPoints: number;
  order: number;
  steps: Step[];
  isViewed: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  isDeleted: boolean;
}

export interface UpdateStepPayload {
  stepStatus: string;
  rejectedReason?: string;
  approvedBy?: string;
  rejectedBy?: string;
}
