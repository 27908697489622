import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChevronRight, Circle } from "@mui/icons-material";
import theme from "@/utils/theme";
import { GoalStepStatus, Step } from "@/domain/goal.interface";
import { useParticipantById } from "@/hooks/useParticipants";
import { ParticipantResponse } from "@/domain/participant.interface";
import ClientStepModal from "@/ui/modals/clients/ClientStepModal";
import {
  AlertCode,
  ResponseOfficerPendingAlerts,
} from "@/domain/alerts.interface";
import RedeemRequestModal from "@/ui/modals/clients/RedeemRequestModal";
import dayjs from "dayjs";

type Request = {
  alert: ResponseOfficerPendingAlerts;
  handleMarkAlertAsViewed?: () => void;
  refetchAlerts: () => void;
};

const ApprovalRequest: React.FC<Request> = ({
  alert,
  handleMarkAlertAsViewed,
  refetchAlerts,
}) => {
  const { data: participantResponse } = useParticipantById(alert.participantId);
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);
  const [showStepModal, setShowStepModal] = useState(false);

  const [showRedeemRequestModal, setShowRedeemRequestModal] = useState(false);

  const alertClickHandler = () => {
    if (handleMarkAlertAsViewed) {
      handleMarkAlertAsViewed();
    }
    switch (alert.code) {
      case AlertCode.PARTICIPANT_STEP_COMPLETION:
        if (alert.data.status === GoalStepStatus.PENDING_SUBMIT) {
          return;
        }
        setSelectedStep(alert.data as Step);
        setShowStepModal(true);
        break;
      case AlertCode.PARTICIPANT_REDEEM_REQUEST:
        setShowRedeemRequestModal(true);
        break;
      default:
        console.warn(`Unhandled alert code: ${alert.code}`);
        break;
    }
  };

  const getBorderColorBasedOnAlertCode = () => {
    switch (alert.code) {
      case AlertCode.PARTICIPANT_STEP_COMPLETION:
        return theme.palette.warning.light;
      case AlertCode.PARTICIPANT_REDEEM_REQUEST:
        return theme.palette.brand.main;
      default:
        return theme.palette.error.dark;
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "5px" }}
        onClick={alertClickHandler}
      >
        <Box display="flex" justifyContent="start" alignItems="stretch" mt={2}>
          <Box
            sx={{
              width: "10px",
              height: "80px",
              backgroundColor: getBorderColorBasedOnAlertCode(),
              borderRadius: "5px",
            }}
          ></Box>
          <Box pl={1}>
            <Box>
              <Typography
                fontWeight={theme.typography.fontWeightBold}
                fontSize={theme.typography.h6.fontSize}
                color={theme.palette.menu.dark}
                variant="headlineSmall"
              >
                {alert.title}
                {!alert.isViewed && (
                  <Circle sx={{ height: 10 }} color={"error"} />
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                color={theme.palette.textMain.dark2}
                variant="bodyMedium"
              >
                {alert.participantName
                  ? alert.description
                      .split(alert.participantName)
                      .map((part, index, array) => (
                        <React.Fragment key={index}>
                          {part}
                          {index < array.length - 1 && (
                            <Typography component="span" fontWeight="bold">
                              {alert.participantName}
                            </Typography>
                          )}
                        </React.Fragment>
                      ))
                  : alert.description}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={theme.typography.caption.fontSize}
                textTransform={"uppercase"}
                color={theme.palette.icon.dark}
                variant="bodyMedium"
              >
                {dayjs(alert.date).format("DD MMM YYYY, hh:mm A")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ChevronRight />
      </Box>
      {showStepModal ? (
        <ClientStepModal
          open={showStepModal}
          onClose={() => setShowStepModal(false)}
          participant={participantResponse as ParticipantResponse}
          goalId={selectedStep?.goalId as string}
          stepData={selectedStep as Step}
          refetchGoals={refetchAlerts}
        />
      ) : null}
      {showRedeemRequestModal ? (
        <RedeemRequestModal
          open={showRedeemRequestModal}
          onClose={() => {
            setShowRedeemRequestModal(false);
            refetchAlerts();
          }}
          participantId={alert.participantId}
        />
      ) : null}
    </>
  );
};

export default ApprovalRequest;
