import { MessageModelExtended } from "@/domain/chat.interface";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface ChatMessagesContextType {
  contactMessages: { [key: string]: MessageModelExtended[] };
  setContactMessages: React.Dispatch<
    React.SetStateAction<{ [key: string]: MessageModelExtended[] }>
  >;
}

const ChatMessagesContext = createContext<ChatMessagesContextType | null>(null);

interface ChatMessagesProviderProps {
  children: ReactNode;
}

export const ChatMessagesProvider: React.FC<ChatMessagesProviderProps> = ({
  children,
}) => {
  const [contactMessages, setContactMessages] = useState<{
    [key: string]: MessageModelExtended[];
  }>({});

  return (
    <ChatMessagesContext.Provider
      value={{ contactMessages, setContactMessages }}
    >
      {children}
    </ChatMessagesContext.Provider>
  );
};

export const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);
  if (!context) {
    throw new Error(
      "useChatMessagesContext must be used within a ChatMessagesProvider"
    );
  }
  return context;
};
