import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import inviteValidationSchema, { InviteFormData } from "./validation.schema";
import { useTranslation } from "react-i18next";
import Button from "@/components/Button";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { ErrorResponse } from "@/domain/error.interface";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import {
  useColoringRequiredSetting,
  useColorModuleSetting,
  useCountrySetting,
  useDateOfBirthSetting,
} from "@/hooks/useSetting";
import { useGetUserPrograms } from "@/hooks/usePrograms";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import { Typography } from "@mui/material";
import Datepicker from "@/components/Datepicker";
import dayjs, { Dayjs } from "dayjs";
import Select from "@/components/Select";
import { useOrganizationUnitsByIds } from "@/hooks/useOrganizations";
import { useCreateClient } from "@/hooks/useClients";
import { useUsers } from "@/hooks/useUsers";
import { useOfficerRoles } from "@/hooks/useRoles";
import { useCategoryByType } from "@/hooks/useCategories";
import { CategoryElement } from "@/domain/category.interface";

type ClientInviteModalProps = {
  open: boolean;
  onClose: () => void;
};

const ClientInviteModal: FC<ClientInviteModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation("common");
  const { userInfo, hasSystemAdminRole } = useUserStore();
  const { showSnackBar, showSimpleAlert } = useFeedBack();
  const theme = useAppTheme();
  const { officerRoles } = useOfficerRoles();

  const createAuditLog = useCreateAuditlog();
  const createClient = useCreateClient();
  const isPending = createClient.isPending;

  const { programs, isLoadingPrograms } = useGetUserPrograms({
    userId: userInfo?._id as string,
    hasSystemAdminRole,
  });

  const [requiredCaseNumber, setRequiredCaseNumber] = useState(false);

  const { category, isLoadingCategory } = useCategoryByType({
    type: "COLORS",
    status: "ACTIVE",
  });

  const colors = useMemo(() => {
    if (!category) return [];

    return category?.categories.map((categoryElement: CategoryElement) => {
      const name = categoryElement.languages.find(
        (language) => language.language === "en-us"
      )?.name as string;

      return {
        key: categoryElement._id,
        value: name,
        hexaColor: categoryElement.hexaColor,
        extra: (
          <div
            className="rounded-full w-6 h-6"
            style={{
              backgroundColor: categoryElement.hexaColor,
            }}
          ></div>
        ),
      };
    });
  }, [category]);

  const programsOptions = useMemo(() => {
    if (!programs) return [];

    return programs.map((program) => ({
      key: program._id,
      value: program.name,
    }));
  }, [programs]);

  const [selectedOrgUnitIds, setSelectedOrgUnitIds] = useState<string[]>([]);

  const {
    organizationUnits,
    isLoadingOrganizationUnits,
    refetchOrganizationUnits,
  } = useOrganizationUnitsByIds({
    ids: selectedOrgUnitIds,
    status: "ACTIVE",
  });

  const orgUnitsOptions = useMemo(() => {
    if (!organizationUnits) return [];

    return organizationUnits.map((orgUnit) => ({
      key: orgUnit._id,
      value: orgUnit.name,
    }));
  }, [organizationUnits]);

  const countrySetting = useCountrySetting();
  const dateOfBirthSetting = useDateOfBirthSetting();
  const colorModuleSetting = useColorModuleSetting();
  const colorRequired = useColoringRequiredSetting();

  const country = useMemo(() => countrySetting, [countrySetting]);
  const dateOfBirth = useMemo(() => dateOfBirthSetting, [dateOfBirthSetting]);
  const coloringModule = useMemo(
    () => colorModuleSetting,
    [colorModuleSetting]
  );
  const coloringRequired = useMemo(() => colorRequired, [colorRequired]);

  console.log("coloringModule", coloringModule);
  console.log("coloringRequired", coloringRequired);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InviteFormData>({
    resolver: yupResolver(
      inviteValidationSchema(
        t,
        country,
        dateOfBirth,
        requiredCaseNumber,
        coloringModule,
        coloringRequired
      )
    ),
    mode: "all",
  });

  const programId = watch("programId") as string;
  const orgUnitIds = watch("orgUnitIds") as string;

  const { users, isLoadingUsers, refetchUsers } = useUsers({
    sortField: "firstName",
    sortOrder: -1,
    pageNumber: 0,
    pageSize: 100,
    search: "",
    status: ["ACTIVE"],
    roleId: (officerRoles && officerRoles.length && officerRoles[0]._id) || "",
    programId: programId,
    orgUnitIds: orgUnitIds,
  });

  const usersOptions = useMemo(() => {
    if (!users) return [];

    return users.users.map((user) => ({
      key: user._id,
      value: `${user.firstName} ${user.lastName}`,
    }));
  }, [users]);

  const getRandomColorId = useCallback(() => {
    if (!colors) return "";

    const randomIndex = Math.floor(Math.random() * colors.length);

    return colors[randomIndex].key;
  }, [colors]);

  const onSubmit = async (data: InviteFormData) => {
    const colorId =
      data.colorId === "random" ? getRandomColorId() : data.colorId;

    const payload = {
      firstName: data.firstName as string,
      lastName: data.lastName as string,
      email: data.email as string,
      countryPhoneCode: country === "US" ? "1" : "44",
      phoneNumber: data.phoneNumber as string,
      externalId: data.externalId as string,
      createdBy: userInfo?._id as string,
      dateOfBirth: data.dateOfBirth as Date,
      programId: data.programId as string,
      orgUnitId: data.orgUnitIds as string,
      primaryOfficerId: data.primaryOfficerId as string,
      caseNumbers: data.caseNumbers as string,
      status: "INVITED",
      ...(colorId ? { colorId: colorId } : { colorId: null }),
    };

    try {
      await createClient.mutateAsync(payload, {
        onSuccess: (data) => {
          onClose();
          showSnackBar("The client has been invited.", "success");

          createAuditLog.mutate({
            appType: "WEB_BACK_OFFICE",
            module: MODULE_NAME.PARTICIPANTS_LIST,
            option: OPTIONS.PARTICIPANT_ADD,
            actionCode: ACTION_CODE.WEB_PART_ADD_INVITE,
            action: "When inviting a new client (adding)",
            detail: `Invited a new client ${data.firstName} ${data.lastName}`,
            transactionDate: new Date(),
            accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
            createdBy: userInfo?._id as string,
            targetId: data._id,
          });
        },
        onError: (error: ErrorResponse) => {
          showSimpleAlert(
            (Array.isArray(error.message) ? error.message[0] : error.message) ||
              error.error ||
              "An error occurred"
          );
        },
      });
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSimpleAlert(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const canChooseOfficer = useMemo(() => {
    if (!programId || !orgUnitIds) return false;

    return true;
  }, [programId, orgUnitIds]);

  useEffect(() => {
    if (selectedOrgUnitIds.length > 0) {
      refetchOrganizationUnits();
    }
  }, [selectedOrgUnitIds]);

  useEffect(() => {
    if (programId) {
      setValue("orgUnitIds", "");
    }
  }, [programId]);

  useEffect(() => {
    if (orgUnitIds && officerRoles && officerRoles.length > 0) {
      refetchUsers();
    }
  }, [orgUnitIds, officerRoles]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={false}
      title="Invite client"
      theme={theme}
      size={{
        width: "100%",
        minHeight: 440,
        maxHeight: 720,
        maxWidth: 860,
      }}
      content={
        <Box sx={{ width: "100%", minHeight: 380, padding: "20px 0" }}>
          <Grid container spacing={"20px"}>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="titleSmall"
                fontWeight={700}
                gutterBottom
                color={theme.palette.menu.dark}
              >
                Personal information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="firstName"
                    label="First name"
                    variant="outlined"
                    required
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.firstName}
                    helperText={
                      errors.firstName ? errors.firstName.message : null
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="lastName"
                    label="Last name"
                    variant="outlined"
                    required
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.lastName}
                    helperText={
                      errors.lastName ? errors.lastName.message : null
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="Email"
                    required
                    variant="outlined"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : null}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {dateOfBirth !== "hidden" ? (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Datepicker
                        {...field}
                        label="Date of birth (mm/dd/yyyy)"
                        onChange={(value) => {
                          field.onChange(
                            value !== undefined ? value?.toDate() : undefined
                          );
                        }}
                        value={
                          field.value
                            ? dayjs(
                                field.value as string | number | Date | Dayjs
                              )
                            : null
                        }
                        error={!!errors.dateOfBirth}
                        helperText={errors.dateOfBirth?.message || ""}
                        required={dateOfBirth === "mandatory"}
                      />
                    </div>
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PhoneNumberInput
                    {...field}
                    defaultCode={country}
                    value={field.value}
                    label="Phone number"
                    variant="outlined"
                    className="flex flex-1 w-full"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber ? errors.phoneNumber.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="titleSmall"
                fontWeight={700}
                gutterBottom
                color={theme.palette.menu.dark}
              >
                PARCA information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="programId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    handleChange={(value) => {
                      field.onChange(value);

                      const selectedProgram = programs?.find(
                        (program) => program._id === value
                      );

                      const orgUnitIds = selectedProgram?.orgUnitId;
                      const requiresCaseNumber =
                        selectedProgram?.parameters.requiresCaseNumber;

                      setSelectedOrgUnitIds(orgUnitIds || []);
                      setRequiredCaseNumber(requiresCaseNumber || false);
                    }}
                    data={programsOptions || []}
                    value={field.value as string}
                    label="Program"
                    required
                    id="client-program"
                    isLoading={isLoadingPrograms}
                    fullWidth
                    error={!!errors.programId}
                    errorMessage={errors.programId?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="orgUnitIds"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    handleChange={(value) => {
                      field.onChange(value);
                    }}
                    data={orgUnitsOptions || []}
                    value={field.value as string}
                    label="Org unit"
                    required
                    id="client-orgUnitIds"
                    isLoading={!programId || isLoadingOrganizationUnits}
                    fullWidth
                    error={!!errors.orgUnitIds}
                    errorMessage={errors.orgUnitIds?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="primaryOfficerId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    handleChange={(value) => {
                      field.onChange(value);
                    }}
                    data={usersOptions || []}
                    value={field.value as string}
                    label="Primary Officer"
                    required
                    id="client-primaryOfficerId"
                    isLoading={!canChooseOfficer || isLoadingUsers}
                    fullWidth
                    error={!!errors.primaryOfficerId}
                    errorMessage={errors.primaryOfficerId?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="caseNumbers"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="caseNumbers"
                    label="Case number(s)"
                    variant="outlined"
                    className="flex flex-1"
                    required={requiredCaseNumber}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.caseNumbers}
                    helperText={
                      errors.caseNumbers ? errors.caseNumbers.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="externalId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="externalId"
                    label="External ID"
                    variant="outlined"
                    className="flex flex-1"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!errors.externalId}
                    helperText={
                      errors.externalId ? errors.externalId.message : null
                    }
                  />
                )}
              />
            </Grid>
            {coloringModule ? (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="colorId"
                  control={control}
                  defaultValue={
                    coloringModule && coloringRequired ? "random" : ""
                  }
                  render={({ field }) => (
                    <Select
                      handleChange={(value) => field.onChange(value)}
                      data={[{ key: "random", value: "(Random)" }, ...colors]}
                      value={field.value as string}
                      label="Screening Color"
                      id="participant-screening-color"
                      isLoading={isLoadingCategory}
                      required={coloringRequired}
                      showClearOption={!coloringRequired}
                      renderValue={(selected) => {
                        if (selected === "random") return "(Random)";

                        return (
                          <div className="flex gap-2.5">
                            <div
                              className="rounded-full w-6 h-6"
                              style={{
                                backgroundColor: colors.find(
                                  (color) => color.key === selected
                                )?.hexaColor,
                              }}
                            ></div>
                            {
                              colors.find((color) => color.key === selected)
                                ?.value
                            }
                          </div>
                        );
                      }}
                      error={!!errors.colorId}
                      errorMessage={errors.colorId?.message}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      }
      actions={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variantType="cancel"
            onClick={onClose}
            disabled={isPending}
            label="CANCEL"
          />
          <Button
            variantType="save"
            onClick={async () => {
              handleSubmit(onSubmit)();
            }}
            disabled={isPending}
            isLoading={isPending}
            label="INVITE"
          />
        </Box>
      }
    />
  );
};

export default ClientInviteModal;
