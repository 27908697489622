import React from "react";
import Layout from "../views/Layout";
import Box from "@mui/material/Box";
import theme from "./theme";

interface WithLayoutProps {
  noLayout?: boolean;
}

const withLayout = <P extends object>(
  Component: React.ComponentType<P>,
  noLayout = false
) => {
  const WrappedComponent: React.FC<P & WithLayoutProps> = (props) => {
    return noLayout ? (
      <Box sx={{ backgroundColor: theme.palette.surface.light2 }}>
        <Component {...(props as P)} />
      </Box>
    ) : (
      <Layout>
        <Component {...(props as P)} />
      </Layout>
    );
  };

  return WrappedComponent;
};

export default withLayout;
