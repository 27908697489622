import { useQuery } from "@tanstack/react-query";
import { goalTemplatesService } from "@/services/goal-templates.service";

export const useGetGoalTemplates = () => {
  const {
    data: goalTemplates = [],
    isLoading: isLoadingGoalTemplates,
    isFetching: isFetchingGoalTemplates,
    refetch: refetchGoalTemplates,
  } = useQuery({
    queryKey: ["get-goal-templates"],
    queryFn: () => goalTemplatesService.findAll(),
  });

  return {
    goalTemplates,
    isLoadingGoalTemplates,
    isFetchingGoalTemplates,
    refetchGoalTemplates,
  };
};
