import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GroupedRole } from "./UserInviteModal";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useAppTheme } from "@/utils/theme";
import { InviteFormData } from "./validation.schema";
import GenericAlertModal from "@/components/AlertModal";

interface TabRolesProps {
  rolesArray: GroupedRole[];
  isSubmitting?: boolean;
  clearSubmitting?: () => void;
}

export const TabRoles: FC<TabRolesProps> = ({
  isSubmitting,
  rolesArray,
  clearSubmitting,
}) => {
  const theme = useAppTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext<InviteFormData>();

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (errors.roles && isSubmitting) {
      setShowError(true);
      clearSubmitting?.();
    }
  }, [errors, isSubmitting]);

  return (
    <Box display="flex" width="100%">
      <Controller
        name="roles"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <RadioGroup
            {...field}
            onChange={(e) => field.onChange(e.target.value)}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              width: "100%",
            }}
          >
            {rolesArray.map((group, index) => (
              <Box key={index} width={200}>
                <Typography
                  variant="titleSmall"
                  fontWeight={700}
                  color={theme.palette.primary.dark}
                >
                  {group.title}
                </Typography>
                {group.roles.map((role) => (
                  <FormControlLabel
                    key={role._id}
                    value={role._id}
                    control={<Radio />}
                    label={role.name}
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                ))}
              </Box>
            ))}
          </RadioGroup>
        )}
      />

      {showError && (
        <GenericAlertModal
          onCancel={() => {
            setShowError(false);
          }}
          onClick={() => {
            setShowError(false);
          }}
          title="Error"
          description="You must select one role for this user."
          submitText="Ok"
          theme={theme}
        />
      )}
    </Box>
  );
};
