import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, IconButton, Tooltip } from "@mui/material";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useAppTheme } from "@/utils/theme";
import { useUserStore } from "@/hooks/useUserStore";

interface HeaderProps {
  handleAddClick: () => void;
  handleInsufficientPermissions: () => void;
  handleAuditLogClick: () => void;
}

const Header: React.FC<HeaderProps> = ({
  handleAddClick,
  handleInsufficientPermissions,
  handleAuditLogClick,
}) => {
  const theme = useAppTheme();
  const { userHasAccessToCode } = useUserStore();

  return (
    <Box
      sx={{
        width: "100%",
      }}
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="headlineSmall" component="h1">
        Screening colors
      </Typography>

      <Box display="flex" gap="0.5rem">
        <Tooltip title="Audit log">
          <IconButton
            size="small"
            sx={{
              borderRadius: "8px",
              border: `1px solid ${theme.palette.primary.dark}`,
              height: "40px",
              width: "40px",
            }}
            onClick={handleAuditLogClick}
          >
            <HistoryOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </Tooltip>

        <Button
          size={"medium"}
          onClick={() => {
            if (userHasAccessToCode("COLOR_ADD")) {
              handleAddClick();
            } else {
              handleInsufficientPermissions();
            }
          }}
          variant="contained"
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            textAlign: "center",
            height: "40px",
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.textMain.light,
          }}
          startIcon={<AddIcon />}
        >
          ADD
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
