import { axios, transformAxiosError } from "../utils/axios.create";
import { RoleType, UserRoleType } from "../domain/rol.interface";

const findAll = async ({ status }: { status: string }): Promise<RoleType[]> => {
  return axios
    .get(`/roles?status=${status}`)
    .then((res) => {
      return res.data.body as RoleType[];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findById = async (id: string): Promise<RoleType> => {
  return axios
    .get(`/roles/${id}`)
    .then((res) => {
      return res.data.body as RoleType;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findByUserId = async (userId: string): Promise<UserRoleType[]> => {
  return axios
    .get(`/users/${userId}/roles`)
    .then((res) => {
      return res.data.body as UserRoleType[];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const RolesService = {
  findAll,
  findById,
  findByUserId,
};
