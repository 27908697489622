import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SxProps, Theme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ReactNode } from "react";

interface Option {
  value: string;
  onClick: () => void;
  disabled?: boolean;
  sx?: MenuItemProps;
  theme?: Theme;
  icon?: ReactNode;
}

interface ActionMenuProps {
  id?: string;
  options: Option[];
  isSquare?: boolean;
  squareSx?: SxProps;
  disabled?: boolean;
  theme?: Theme;
}

const ITEM_HEIGHT = 48;

export default function ActionMenu({
  id,
  options,
  isSquare = false,
  squareSx,
  disabled = false,
  theme,
}: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id={"actionMenu-button-" + id}
        data-testid={"actionMenu-button-" + id}
        aria-controls={open ? "actionMenu-menu-" + id : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        {...(isSquare && {
          size: "large",
          sx: {
            borderRadius: "8px",
            border: `1px solid ${theme?.palette.outline.dark || "#E0E0E0"}`,
            width: "3.25rem",
            height: "3.25rem",
            ...squareSx,
          },
        })}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={"actionMenu-menu-" + id}
        MenuListProps={{
          "aria-labelledby": "actionMenu-button-" + id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              minWidth: "80px",
              width: "auto",
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option, key) => (
          <MenuItem
            key={`${id}-menu-item-${key}`}
            onClick={() => {
              if (!option.disabled) option.onClick();
              handleClose();
            }}
            sx={{
              fontSize: "0.875rem",
              padding: "0.5rem 0.5rem",
              cursor: !option.disabled ? "pointer" : "none",
              color:
                option.value.toLocaleLowerCase() === "delete"
                  ? theme?.palette.error.main
                  : "inherit",
              gap: "10px",
              ...option.sx,
            }}
            disabled={option.disabled}
          >
            {option.icon && (
              <ListItemIcon
                className="!min-w-4"
                sx={{
                  ...option.sx,
                }}
              >
                {option.icon}
              </ListItemIcon>
            )}
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
