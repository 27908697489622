import { ParticipantsService } from "@/services/participants.service";
import { useQuery } from "@tanstack/react-query";

export const useParticipantById = (id: string) => {
  const {
    data,
    isLoading: isLoadingParticipant,
    isError: isErrorParticipant,
    refetch: refetchParticipant,
  } = useQuery({
    queryKey: ["parca-participant-by-id", id],
    queryFn: () => ParticipantsService.findById(id),
  });

  return {
    data,
    isLoadingParticipant,
    isErrorParticipant,
    refetchParticipant,
  };
};
