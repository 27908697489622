import { GoalStepStatus, Step } from "@/domain/goal.interface";
import { ParticipantResponse } from "@/domain/participant.interface";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import { useValidationProofLinks } from "@/hooks/useGoals";

type ViewStepProps = {
  participant: ParticipantResponse;
  goalId: string;
  stepData: Step;
  statusStyles: object;
  statusText: string;
  stepLastSubmission: number;
  setStepScreen: (screen: "view" | "reject" | "proof" | "photo") => void;
  setShowRejectedAlert: (show: boolean) => void;
  onHandlePhoto: (photo: string) => void;
};

const ViewStep: FC<ViewStepProps> = ({
  participant,
  goalId,
  stepData,
  statusStyles,
  statusText,
  stepLastSubmission,
  setStepScreen,
  setShowRejectedAlert,
  onHandlePhoto,
}) => {
  const theme = useAppTheme();
  const { data, isLoadingValidationProofLinks } = useValidationProofLinks(
    participant?.lastEnrollment?._id || "",
    goalId,
    stepData.submissions[stepLastSubmission]?.validationProofs?.map(
      (proof) => proof.urlDocument
    )
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Typography variant="headlineSmall">
            {participant.participant.firstName}{" "}
            {participant.participant.lastName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: ".25rem",
              alignItems: "center",
            }}
          >
            {stepData.status === GoalStepStatus.REJECTED && (
              <InfoOutlinedIcon
                sx={{
                  color: theme.palette.error.main,
                  cursor: "pointer",
                }}
                onClick={() => setShowRejectedAlert(true)}
              />
            )}
            <Chip
              sx={{
                ...statusStyles,
                borderWidth: 1,
                borderStyle: "solid",
              }}
              label={statusText}
            />
          </Box>
        </Box>

        <Typography
          variant="titleSmall"
          color={theme.palette.icon.dark}
          fontWeight={700}
        >
          {stepData.name}
        </Typography>

        {stepData.submissions.length > 0 && (
          <Typography variant="bodyMedium" color={theme.palette.icon.dark}>
            Submitted on:{" "}
            {dayjs(
              stepData.submissions[stepLastSubmission].submittedDate,
              "YYYY-MM-DD"
            ).format("DD MMM YYYY, hh:mm A")}
          </Typography>
        )}

        {stepData.status === GoalStepStatus.REJECTED && (
          <Typography variant="bodyMedium" color={theme.palette.icon.dark}>
            Rejected on:{" "}
            {dayjs(
              stepData.submissions[stepLastSubmission].rejectionDate,
              "YYYY-MM-DD"
            ).format("DD MMM YYYY, hh:mm A")}
          </Typography>
        )}

        {stepData.status === GoalStepStatus.APPROVED && (
          <Typography variant="bodyMedium" color={theme.palette.icon.dark}>
            Approved on:{" "}
            {dayjs(
              stepData.submissions[stepLastSubmission].approvedDate,
              "YYYY-MM-DD"
            ).format("DD MMM YYYY, hh:mm A")}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <Typography
          variant="labelLarge"
          color={theme.palette.menu.dark}
          fontWeight={500}
        >
          Comment
        </Typography>

        <Box
          sx={{
            borderRadius: ".5rem",
            border: "1px solid #C2CCE5",
            padding: "1rem",
          }}
        >
          <Typography variant="bodyLarge" color={theme.palette.icon.dark}>
            {stepData.submissions[stepLastSubmission].comments || "N/A"}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        {!isLoadingValidationProofLinks &&
          stepData.submissions[stepLastSubmission].validationProofs.map(
            (proof, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderRadius: ".5rem",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onHandlePhoto(data?.links[proof.urlDocument] || "");
                    setStepScreen("photo");
                  }}
                >
                  <img
                    src={data?.links[proof.urlDocument]}
                    alt={proof.documentName}
                    width={200}
                    height={200}
                    className="object-cover w-[100px] h-[100px]"
                  />
                </Box>
              );
            }
          )}
      </Box>
    </>
  );
};

export default ViewStep;
