import { TFunction } from "i18next";
import * as yup from "yup";
import { InferType } from "yup";

const inviteValidationSchema = (
  t: TFunction,
  country: string,
  isEditMode: boolean = false,
  isProgramRequired: boolean = false
) => {
  return yup.lazy(() => {
    const localeValidation = (): yup.StringSchema => {
      switch (country) {
        case "US":
          return yup
            .string()
            .test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
            .test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
              return phoneRegex.test(value);
            });
        case "UK":
          return yup
            .string()
            .test(
              "min",
              t("validations.validator_digits_min", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length >= 10;
              }
            )
            .test(
              "max",
              t("validations.validator_digits_max", { count: 10 }),
              (value) => {
                if (!value || value.length === 0) return true;
                return value.length <= 10;
              }
            )
            .test("phoneNumber", t("validations.invalid_format"), (value) => {
              if (!value || value.trim() === "") return true;
              const phoneRegex = /^\(?\d{4}\)?[-. ]?\d{6}$/;
              return phoneRegex.test(value);
            });
        default:
          return yup.string();
      }
    };

    const schemaFields: yup.ObjectShape = {
      firstName: yup
        .string()
        .required("This field is required")
        .min(2, t("validations.min_length", { count: 2 }))
        .max(50, t("validations.max_length", { count: 50 })),
      lastName: yup
        .string()
        .required("This field is required")
        .min(2, t("validations.min_length", { count: 2 }))
        .max(50, t("validations.max_length", { count: 50 })),
      email: yup
        .string()
        .required("This field is required")
        .email(t("validations.invalid_format"))
        .max(100, t("validations.max_length", { count: 100 })),
      phoneNumber: localeValidation(),
      externalId: yup
        .string()
        .optional()
        .test("max", t("validations.max_length", { count: 30 }), (value) => {
          if (!value || value.length === 0) return true;
          return value.length <= 30;
        }),
      roles: yup.string().required("This field is required"),
      programId: yup
        .string()
        .test("programId-required", "This field is required", function (value) {
          if (isProgramRequired) {
            return !!value;
          }
          return true;
        }),
      orgUnitIds: yup
        .array()
        .of(yup.string().required())
        .test(
          "orgUnitIds-required-when-programId",
          "This field is required when program is selected",
          function (value) {
            const { programId } = this.parent;
            if (programId) {
              return value && value.length > 0;
            }
            return true;
          }
        ),
    };

    if (isEditMode) {
      schemaFields["status"] = yup
        .string()
        .required("This field is required")
        .oneOf(["ACTIVE", "INACTIVE", "PENDING"], "Invalid status");
    }

    return yup.object().shape(schemaFields);
  });
};

export type InviteFormData = InferType<
  ReturnType<typeof inviteValidationSchema>
>;

export default inviteValidationSchema;
