import React, { useState } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import BasicDatepicker from "@/components/Datepicker";
import dayjs, { Dayjs } from "dayjs";
import { headCellsConfig } from "@/utils/tableConfig";
import DataTable, { DataTableCell, HeadCell } from "@/components/DataTable";
import { ReactNode, useEffect, useMemo } from "react";
import { useCreateAuditlog, useGetAuditLog } from "@/hooks/useAuditLogs";
import { useGetSettings } from "@/hooks/useSetting";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";

const createData = (
  id: number,
  timestamp: ReactNode,
  user: ReactNode,
  eventDescription: ReactNode
): DataTableCell => {
  return {
    id,
    timestamp,
    user,
    eventDescription,
  };
};

interface AuditLogModalProps {
  open: boolean;
  onClose: () => void;

  module: MODULE_NAME;
  option?: OPTIONS;
  actionCode?: ACTION_CODE;
  action?: string;
  detail?: string;
}

type Ordering = {
  pageNumber: number;
  pageSize: number;
  sortOrder: number;
  sortField: string;
};

export default function AuditLogModal({
  open,
  onClose,
  module,
  option = OPTIONS.AUDIT_LOG_VIEW,
  actionCode = ACTION_CODE.WEB_GEN_LIST_AUDITLOG_VIEW,
  action = "When entering",
  detail = "Viewed the audit log of the module",
}: AuditLogModalProps) {
  const theme = useAppTheme();
  const [dateRange, setDateRange] = useState({
    from: dayjs().subtract(1, "day").startOf("day"),
    to: dayjs().endOf("day"),
  });
  const { data: settings, isPending: areSettingsPending } = useGetSettings();
  const { userInfo } = useUserStore();

  const createAuditLog = useCreateAuditlog();
  const auditLogViewCalledRef = React.useRef(false);

  useEffect(() => {
    setDateRange({
      ...dateRange,
      from: dayjs()
        .subtract((settings?.defaultDaysAuditLog as number) || 1, "day")
        .startOf("day"),
    });
  }, [settings]);

  const orderingState = useState<Ordering>({
    pageNumber: 0,
    pageSize: 10,
    sortOrder: -1,
    sortField: "timestamp",
  });

  const [sortOrdering, setSortOrdering] = orderingState;

  const {
    data: dataAudit,
    isLoading,
    refetch: refetchGetAuditLogs,
  } = useGetAuditLog(
    module,
    dateRange.from.toISOString(),
    dateRange.to.toISOString(),
    sortOrdering.pageNumber,
    sortOrdering.pageSize,
    sortOrdering.sortField,
    sortOrdering.sortOrder
  );

  const headCells: HeadCell[] = headCellsConfig.auditLog;

  const rows = useMemo(() => {
    if (!dataAudit) {
      return [];
    }

    return dataAudit.data.map(
      (
        row: {
          timestamp: string;
          user: ReactNode;
          eventDescription: ReactNode;
        },
        index: number
      ) => {
        return createData(
          index,
          <div style={{ minWidth: "210px" }}>
            {dayjs(row.timestamp).format(
              (settings?.generalDateFormat as string) || "DD MMM YYYY, hh:MM A"
            )}
          </div>,
          <div style={{ minWidth: "180px" }}>{row.user}</div>,
          <div style={{ minWidth: "350px" }}>{row.eventDescription}</div>
        );
      }
    );
  }, [dataAudit]);

  useEffect(() => {
    refetchGetAuditLogs();
  }, [sortOrdering]);

  useEffect(() => {
    if (userInfo && !auditLogViewCalledRef.current) {
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module,
        option,
        actionCode,
        action,
        detail,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
      });
      auditLogViewCalledRef.current = true;
    }
  }, [userInfo]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        isPending={false}
        title="Audit Log"
        theme={theme}
        size={{
          width: "100%",
          minHeight: 640,
          maxHeight: 800,
          maxWidth: 920,
        }}
        content={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {!areSettingsPending && (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                  },
                  paddingTop: "1.5rem",
                }}
              >
                <BasicDatepicker
                  label="Date from"
                  onChange={
                    ((value: Dayjs) => {
                      setDateRange((prev) => ({
                        ...prev,
                        from: value?.startOf("day"),
                      }));

                      setSortOrdering((sortOrdering) => ({
                        ...sortOrdering,
                        pageNumber: 0,
                      }));
                    }) as (value: Dayjs | null) => void
                  }
                  value={dateRange.from}
                  sx={{
                    maxWidth: {
                      sm: 220,
                    },
                  }}
                  disableWrite={true}
                  maxDate={dateRange.to}
                />
                <BasicDatepicker
                  label="Date to"
                  onChange={
                    ((value: Dayjs) => {
                      setDateRange((prev) => ({
                        ...prev,
                        to: value?.endOf("day"),
                      }));

                      setSortOrdering((sortOrdering) => ({
                        ...sortOrdering,
                      }));
                    }) as (value: Dayjs | null) => void
                  }
                  value={dateRange.to}
                  sx={{
                    maxWidth: {
                      sm: 220,
                    },
                  }}
                  minDate={dateRange.from}
                  disableWrite={true}
                />
              </Box>
            )}
            <DataTable
              data={rows}
              headCells={headCells}
              order={sortOrdering.sortOrder === 1 ? "asc" : "desc"}
              orderBy={sortOrdering.sortField}
              rowsPerPage={sortOrdering.pageSize}
              page={sortOrdering.pageNumber}
              total={dataAudit?.total}
              isLoading={areSettingsPending || isLoading}
              onChangePage={(event, newPage) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: newPage,
                }));
              }}
              onChangeSize={(event) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: 0,
                  pageSize: Number(event.target.value),
                }));
              }}
              onChangeSort={(order, orderBy) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: 0,
                  sortField: orderBy as string,
                  sortOrder: order === "asc" ? 1 : -1,
                }));
              }}
              messageEmptyData="No events found for the selected date range."
            />
          </Box>
        }
      />
    </>
  );
}
