import React from "react";
import dayjs from "dayjs";
import { GoalStatus, GoalStepStatus } from "@/domain/goal.interface";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface StatusTextProps {
  status: GoalStatus | GoalStepStatus;
  type: "goal" | "step";
  creationDate?: Date;
  completionDate?: Date;
  lastSubmission?: {
    approvedBy?: { firstName: string; lastName: string };
    approvedDate?: Date;
    rejectedBy?: { firstName: string; lastName: string };
  };
}

const StatusText: React.FC<StatusTextProps> = ({
  status,
  type,
  creationDate,
  completionDate,
  lastSubmission,
}) => {
  const { t } = useTranslation(["clients"]);

  const formatDateAndDuration = (date?: Date, referenceDate?: Date) => {
    if (!date || !referenceDate) return "";
    const formattedDate = dayjs(date).format("DD MMM YYYY, hh:mm A");
    const duration = dayjs(date).diff(dayjs(referenceDate), "days");
    return { formattedDate, duration };
  };

  const getStatusText = () => {
    if (type === "goal") {
      if (
        status === GoalStatus.COMPLETED_STEPS &&
        creationDate &&
        completionDate
      ) {
        const { formattedDate, duration } = formatDateAndDuration(
          completionDate,
          creationDate
        ) as { formattedDate: string; duration: number };
        return t("goals.step_completed", {
          date: formattedDate,
          days: duration,
        });
      }
      if (status === GoalStatus.PENDING_APPROVAL_STEPS) {
        return t("goals.step_pending_approval");
      }
    } else if (type === "step" && lastSubmission) {
      if (lastSubmission.approvedBy && lastSubmission.approvedDate) {
        const { formattedDate, duration } = formatDateAndDuration(
          lastSubmission.approvedDate,
          creationDate
        ) as { formattedDate: string; duration: number };
        return t("goals.step_completed", {
          date: formattedDate,
          days: duration,
        });
      }
      if (lastSubmission.rejectedBy) {
        const managerFullName = `${lastSubmission.rejectedBy.firstName} ${lastSubmission.rejectedBy.lastName}`;
        return t("goals.step_rejected", { managerFullName });
      }
    }
    return null;
  };

  const getStatusColor = () => {
    return status === GoalStepStatus.REJECTED ? "error" : "text.secondary";
  };

  const statusText = getStatusText();

  return (
    <Typography
      variant="body2"
      fontSize={12}
      color={getStatusColor()}
      sx={{ wordBreak: "break-word", textOverflow: "ellipsis" }}
    >
      {statusText}
    </Typography>
  );
};

export default StatusText;
