import React, { useState, useEffect } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { useUsers, useUserById } from "@/hooks/useUsers";
import useDebounce from "@/hooks/useDebounce";
import { useUpdateAdditionalOfficers } from "@/hooks/useEnrollments";
import { UserType } from "@/domain/user.interface";
import SkeletonLoader from "@/components/SkeletonLoader";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { ParticipantResponse } from "@/domain/participant.interface";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useOfficerRoles } from "@/hooks/useRoles";

interface OfficersModalProps {
  open: boolean;
  onClose: () => void;
  participantResponse: ParticipantResponse;
  refetchParticipant: () => void;
}

const OfficersModal: React.FC<OfficersModalProps> = ({
  open,
  onClose,
  participantResponse,
  refetchParticipant,
}) => {
  const { t } = useTranslation("common");
  const theme = useAppTheme();
  const { showSnackBar } = useFeedBack();
  const { participant, lastEnrollment } = participantResponse;
  const { userInfo } = useUserStore();
  const createAuditLog = useCreateAuditlog();
  const { officerRoles } = useOfficerRoles();

  const [selectedOfficers, setSelectedOfficers] = useState<UserType[]>([]);
  const [officerInput] = useState<string>("");
  const [officersList, setOfficersList] = useState<UserType[]>([]);

  const debouncedOfficerInput = useDebounce({
    value: officerInput,
    delay: 300,
  });

  const { user: primaryOfficer, isLoadingUser: isLoadingPrimaryOfficer } =
    useUserById(lastEnrollment?.primaryOfficerId || "", open);

  const { mutateAsync: updateOfficers, isPending: isSaving } =
    useUpdateAdditionalOfficers();
  const {
    users: officersResult,
    refetchUsers: refetchOfficers,
    isLoadingUsers: isLoadingOfficers,
  } = useUsers(
    {
      roleId:
        (officerRoles && officerRoles.length && officerRoles[0]._id) || "",
      sortField: "firstName",
      sortOrder: 1,
      pageNumber: 0,
      pageSize: 50,
      search: debouncedOfficerInput,
      status: ["ACTIVE"],
    },
    open
  );

  useEffect(() => {
    if (open && lastEnrollment?.additionalOfficerId?.length) {
      const preSelectedOfficers = officersResult?.users?.filter((user) =>
        lastEnrollment.additionalOfficerId.includes(user._id)
      );
      if (preSelectedOfficers) {
        setSelectedOfficers(preSelectedOfficers);
      }
    }
  }, [open, lastEnrollment?.additionalOfficerId, officersResult]);

  useEffect(() => {
    if (open && officerRoles && officerRoles.length > 0) {
      refetchOfficers();
    }
  }, [open, officerRoles, refetchOfficers]);

  useEffect(() => {
    if (debouncedOfficerInput) {
      refetchOfficers();
    }
  }, [debouncedOfficerInput, refetchOfficers]);

  useEffect(() => {
    if (!officersResult?.users || isLoadingOfficers) return;

    const availableOfficers = officersResult.users.filter(
      (user) =>
        !selectedOfficers.some((selected) => selected._id === user._id) &&
        user._id !== primaryOfficer?._id
    );

    setOfficersList(availableOfficers);
  }, [officersResult, selectedOfficers, primaryOfficer, isLoadingOfficers]);

  const handleAddOfficer = (
    _event: React.ChangeEvent<object>,
    newOfficers: UserType[]
  ) => {
    setSelectedOfficers(newOfficers);
  };

  const handleSave = async () => {
    try {
      const additionalOfficerIds = selectedOfficers.map(
        (officer) => officer._id
      );

      const existingOfficerIds = lastEnrollment?.additionalOfficerId || [];

      const removed = existingOfficerIds
        .filter((id) => !additionalOfficerIds.includes(id))
        .map((id) =>
          officersResult?.users?.find((officer) => officer._id === id)
        )
        .filter((officer): officer is UserType => !!officer);

      const added = additionalOfficerIds
        .filter((id) => !existingOfficerIds.includes(id))
        .map((id) =>
          officersResult?.users?.find((officer) => officer._id === id)
        )
        .filter((officer): officer is UserType => !!officer);

      if (lastEnrollment?._id) {
        await updateOfficers({
          enrollmentId: lastEnrollment?._id,
          additionalOfficerIds,
        });
      }

      if (removed.length > 0) {
        await createAuditLog.mutateAsync({
          appType: "WEB_BACK_OFFICE",
          module: MODULE_NAME.PARTICIPANTS_MANAGE_OFFICERS,
          option: OPTIONS.MANAGE_OFFICERS,
          actionCode: ACTION_CODE.WEB_PART_REMOVED_OFFICERS,
          action: "When officers removed",
          detail: participant
            ? `Removed officer${removed.length > 1 ? "s" : ""} ${removed
                .map((s) => `${s.firstName} ${s.lastName}`)
                .join(", ")} from ${participant?.firstName} ${
                participant?.lastName
              }`
            : "",
          transactionDate: new Date(),
          accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
          createdBy: userInfo?._id as string,
        });
      }

      if (added.length > 0) {
        await createAuditLog.mutateAsync({
          appType: "WEB_BACK_OFFICE",
          module: MODULE_NAME.PARTICIPANTS_MANAGE_OFFICERS,
          option: OPTIONS.MANAGE_OFFICERS,
          actionCode: ACTION_CODE.WEB_PART_ADDED_OFFICERS,
          action: "When added new officers",
          detail: participant
            ? `Assigned officer${added.length > 1 ? "s" : ""} ${added
                .map((s) => `${s.firstName} ${s.lastName}`)
                .join(", ")} to ${participant?.firstName} ${
                participant?.lastName
              }`
            : "",
          transactionDate: new Date(),
          accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
          createdBy: userInfo?._id as string,
        });
      }
      refetchParticipant();
      onClose();
    } catch (error) {
      showSnackBar(`Failed to update officers: ${error}`);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={isSaving || isLoadingOfficers || isLoadingPrimaryOfficer}
      title={t("officers")}
      theme={theme}
      size={{
        width: "100%",
        minHeight: 340,
        maxHeight: 440,
        maxWidth: 480,
      }}
      content={
        <Box mt={2} sx={{ width: "100%" }}>
          <SkeletonLoader
            isLoading={isLoadingPrimaryOfficer}
            skeletonType="text"
            skeletonHeight={24}
          >
            <Box sx={{ marginBottom: 2 }}>
              <strong>{t("primary_officer")}:</strong>{" "}
              {`${primaryOfficer?.firstName} ${primaryOfficer?.lastName}`}
            </Box>
          </SkeletonLoader>
          <SkeletonLoader
            isLoading={isSaving || isLoadingOfficers}
            skeletonType="text"
            skeletonHeight={24}
          >
            <Autocomplete
              multiple
              value={selectedOfficers}
              options={officersList}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              renderInput={(params) => (
                <TextField {...params} label={t("search_officers")} />
              )}
              onChange={handleAddOfficer}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option._id === value._id}
              filterOptions={(options, { inputValue }) => {
                const filterText = inputValue.toLowerCase();
                return options.filter((option) =>
                  `${option.firstName} ${option.lastName}`
                    .toLowerCase()
                    .includes(filterText)
                );
              }}
            />
          </SkeletonLoader>
        </Box>
      }
      actions={
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button variantType="cancel" onClick={onClose} label={t("cancel")} />
          <Button
            variantType="save"
            onClick={handleSave}
            label={t("save")}
            sx={{ textTransform: "uppercase" }}
          />
        </Box>
      }
    />
  );
};

export default OfficersModal;
