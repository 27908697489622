import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@/components/Select";
import { useOrganizationUnitsByIds } from "@/hooks/useOrganizations";
import GenericAlertModal from "@/components/AlertModal";
import { useAppTheme } from "@/utils/theme";
import { useGetPrograms } from "@/hooks/usePrograms";
import { useOrganizationsByUser } from "@/hooks/useUsers";

interface TabAssignOUProps {
  programsOptions: Array<{ key: string; value: string }>;
  setSelectedOrgUnitIds: (ids: string[]) => void;
  selectedOrgUnitIds: string[];
  isEditMode?: boolean;
  hasAdminRole?: boolean;
  isSubmitting?: boolean;
  editUserId?: string;
  clearSubmitting?: () => void;
  onHandleUncheckeableOrganization?: () => void;
}

export const TabAssignOU: FC<TabAssignOUProps> = ({
  programsOptions,
  setSelectedOrgUnitIds,
  selectedOrgUnitIds,
  isEditMode = false,
  hasAdminRole,
  isSubmitting,
  editUserId,
  clearSubmitting,
  onHandleUncheckeableOrganization,
}) => {
  const theme = useAppTheme();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { organizations, isLoadingOrganizations } = useOrganizationsByUser({
    userId: editUserId as string,
    isEditMode,
  });

  const programId = watch("programId");
  const hasOrgUnit = watch("orgUnitIds");

  const { programs } = useGetPrograms({});

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (
      !hasAdminRole &&
      (!programId || hasOrgUnit?.length === 0) &&
      isSubmitting
    ) {
      setShowError(true);
      clearSubmitting?.();
    }
  }, [errors, isSubmitting]);

  const {
    organizationUnits,
    isLoadingOrganizationUnits,
    refetchOrganizationUnits,
  } = useOrganizationUnitsByIds({
    ids: selectedOrgUnitIds,
    status: "ACTIVE",
  });

  useEffect(() => {
    if (selectedOrgUnitIds.length > 0) {
      refetchOrganizationUnits();
    }
  }, [selectedOrgUnitIds]);

  useEffect(() => {
    if (programId && programs && programs.length > 0 && isEditMode) {
      const orgUnitIds = programs?.find(
        (program) => program._id === programId
      )?.orgUnitId;

      setSelectedOrgUnitIds(orgUnitIds || []);
    }
  }, [programId, programs, isEditMode]);

  return (
    <>
      {hasAdminRole ? (
        <Typography>
          The users with account admin role have access to all programs and org
          units.
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
          }}
        >
          <Controller
            name="programId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                handleChange={(value) => {
                  field.onChange(value);

                  const orgUnitIds = programs?.find(
                    (program) => program._id === value
                  )?.orgUnitId;

                  setSelectedOrgUnitIds(orgUnitIds || []);
                }}
                data={programsOptions || []}
                value={field.value}
                label="Programs"
                id="users-programs"
                isLoading={isLoadingOrganizationUnits}
                maxWidth={220}
              />
            )}
          />

          {isLoadingOrganizationUnits && isLoadingOrganizations ? (
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />
              </CardContent>
            </Card>
          ) : organizationUnits && organizationUnits?.length > 0 ? (
            <Card>
              <CardContent>
                <Controller
                  name="orgUnitIds"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      {organizationUnits?.map((unit) => (
                        <FormControlLabel
                          key={unit._id}
                          sx={{ mx: "0", px: "8px" }}
                          control={
                            <Checkbox
                              checked={field.value?.includes(unit._id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  field.onChange([
                                    ...(field.value as string[]),
                                    unit._id,
                                  ]);
                                } else {
                                  if (isEditMode) {
                                    const canUncheck = organizations?.find(
                                      (org) => org._id === unit._id
                                    )?.canUncheck;

                                    if (canUncheck === false) {
                                      onHandleUncheckeableOrganization?.();
                                      return;
                                    }
                                  }

                                  field.onChange(
                                    (field.value as string[]).filter(
                                      (id: string) => id !== unit._id
                                    )
                                  );
                                }
                              }}
                            />
                          }
                          label={unit.name}
                        />
                      ))}
                    </Box>
                  )}
                />
              </CardContent>
            </Card>
          ) : null}

          {showError && (
            <GenericAlertModal
              onCancel={() => setShowError(false)}
              onClick={() => setShowError(false)}
              title="Error"
              description="At least one organizational unit must be selected for this user."
              submitText="Ok"
              theme={theme}
            />
          )}
        </Box>
      )}
    </>
  );
};
