import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useUserStore } from "@/hooks/useUserStore";

const Home: FC = () => {
  const { t } = useTranslation(["common"]);
  const { userInfo } = useUserStore();

  return (
    <Box sx={{ width: "100%", overflowY: "auto", scrollBehavior: "smooth" }}>
      <Box sx={{ width: "100%", maxWidth: "100%", padding: "0 1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="h-[calc(100vh-122px)]"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-center",
              width: "100%",
              maxWidth: "600px", // Responsive max-width for content
              marginBottom: "2rem",
              padding: "0 1rem", // Add padding to prevent content from touching the screen edges
              boxSizing: "border-box",
            }}
          >
            <Typography variant="h4" gutterBottom>
              {t("hello_username", {
                username: `${userInfo?.firstName} ${userInfo?.lastName}`,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
