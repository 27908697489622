import React, { ReactNode, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { SnackBarContext } from "./FeedBackContext";
import Alert, { AlertProps } from "@mui/material/Alert";
import GenericAlertModal from "../../components/AlertModal";
import { styled } from "@mui/material/styles";
import { useAppTheme } from "../../utils/theme";

const BUTTON_LINK_TAB_ID = "nalcam-open-tab-button";

const CustomStyledSnackbar = styled(Snackbar)(() => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "#B5E0FF",
    color: "#232B36",
    borderRadius: "4px",
  },
}));

const CustomStyledAlert = styled(Alert)(() => ({
  backgroundColor: "#B5E0FF",
  color: "#232B36",
  borderRadius: "4px",
}));

export const FeedBackProvider = ({ children }: { children: ReactNode }) => {
  const theme = useAppTheme();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] =
    useState<AlertProps["severity"]>("error");
  const [isSimpleAlertOpen, setIsSimpleAlertOpen] = useState(false);
  const [simpleAlertMessage, setSimpleAlertMessage] = useState("");

  const showSnackBar = (
    message: string,
    severity: AlertProps["severity"] = "info"
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackBarOpen(true);
  };

  const hideSnackBar = () => {
    setSnackBarMessage("");
    setIsSnackBarOpen(false);
  };

  const showSimpleAlert = (message: string) => {
    setSimpleAlertMessage(message);
    setIsSimpleAlertOpen(true);
  };

  const hideSimpleAlert = () => {
    setSimpleAlertMessage("");
    setIsSimpleAlertOpen(false);
  };

  const openLinkInTab = (link: string) => {
    if (!link) return;
    const aElement = document.getElementById(
      BUTTON_LINK_TAB_ID
    ) as HTMLLinkElement;
    aElement.href = link;
    aElement.dispatchEvent(new MouseEvent("click", { bubbles: true }));
  };

  return (
    <SnackBarContext.Provider
      value={{
        showSnackBar,
        hideSnackBar,
        showSimpleAlert,
        hideSimpleAlert,
        openLinkInTab,
      }}
    >
      {children}
      <CustomStyledSnackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        security={snackBarSeverity}
        onClose={hideSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <CustomStyledAlert
          icon={false}
          onClose={hideSnackBar}
          severity={snackBarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </CustomStyledAlert>
      </CustomStyledSnackbar>
      {isSimpleAlertOpen && (
        <GenericAlertModal
          title="Warning"
          description={simpleAlertMessage}
          submitText="Accept"
          onClick={hideSimpleAlert}
          theme={theme}
        />
      )}
      <a
        hidden
        id={BUTTON_LINK_TAB_ID}
        target="_blank"
        rel="noopener noreferrer"
      />
    </SnackBarContext.Provider>
  );
};
