import { Programs } from "@/domain/programs.interface";
import { axios } from "@/utils/axios.create";

const getPrograms = async ({
  sortType,
}: {
  sortType?: string;
}): Promise<Programs[]> => {
  return axios
    .get("/programs", { params: { status: "ACTIVE" } })
    .then((res) => {
      if (sortType === "desc") {
        return res.data.body.reverse();
      }

      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch programs"
      );
    });
};

export const programService = {
  getPrograms,
};
