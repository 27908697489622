import { OrganizationService } from "../services/organizations.service";
import { useQuery } from "@tanstack/react-query";

export const useOrganizationUnitsByIds = ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}) => {
  const {
    data: organizationUnits,
    isLoading: isLoadingOrganizationUnits,
    refetch: refetchOrganizationUnits,
  } = useQuery({
    queryKey: ["parca-organization-units", ids],
    queryFn: () =>
      OrganizationService.findByIds({
        ids,
        status,
      }),
    enabled: ids.length > 0,
  });

  return {
    organizationUnits,
    isLoadingOrganizationUnits,
    refetchOrganizationUnits,
  };
};
