import { axios, transformAxiosError } from "../utils/axios.create";
import { UserPayload, UserType } from "../domain/user.interface";
import { UsersPayload } from "../hooks/useUsers";
import { IRoleAction } from "../domain/rol.interface";
import { Programs } from "../domain/programs.interface";
import { OrganizationUnit } from "@/domain/organizations.interface";

const findAll = async ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  search,
  status,
  roleId,
  roleSubType,
  programId,
  orgUnitIds,
}: UsersPayload): Promise<{
  users: UserType[];
  totalCount: number;
}> => {
  const queryParams = new URLSearchParams({
    status: status.join(","),
    search,
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
    roleId,
    programId: !programId ? "" : programId,
    orgUnitId: !orgUnitIds ? "" : orgUnitIds,
  });

  if (roleSubType) {
    queryParams.append("roleSubType", roleSubType);
  }

  return axios
    .get(`/users/search?${queryParams.toString()}`)
    .then((res) => {
      const body = res.data.body;
      return {
        users: body.users,
        totalCount: body.totalCount,
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findByEmail = async (email: string): Promise<UserType> => {
  return axios
    .get(`/users/email?${new URLSearchParams({ email }).toString()}`)
    .then((res) => {
      return (res.data.body as UserType) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findRolesById = async (id: string): Promise<IRoleAction[]> => {
  return axios
    .get(`users/${id}/roles`)
    .then((res) => {
      return (res.data.body as IRoleAction[]) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findProgramsByUser = async ({
  id,
  sortType,
}: {
  id: string;
  sortType: string;
}): Promise<Programs[]> => {
  return axios
    .get(`users/${id}/programs`)
    .then((res) => {
      if (sortType === "desc") {
        return (res.data.body as Programs[]).reverse() || [];
      }

      return (res.data.body as Programs[]) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findOrganizationsByUser = async (
  id: string
): Promise<OrganizationUnit[]> => {
  return axios
    .get(`users/${id}/organizations`)
    .then((res) => {
      return (res.data.body as OrganizationUnit[]) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const createOne = async (user: UserPayload): Promise<UserType> => {
  return axios
    .post("/users", user)
    .then((res) => {
      return res.data.body as UserType;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const updateOne = async ({
  user,
  userId,
}: {
  user: UserPayload;
  userId: string;
}): Promise<UserType> => {
  return axios
    .put(`/users/${userId}`, user)
    .then((res) => {
      return res.data.body as UserType;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const deleteOne = async (userId: string): Promise<void> => {
  return axios
    .delete(`/users/${userId}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const resendInvitation = async (email: string): Promise<void> => {
  const queryParams = new URLSearchParams({
    email,
  }).toString();

  return axios
    .post(`/users/resend-invitation?${queryParams}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const findById = async (id: string): Promise<UserType> => {
  return axios
    .get(`/users/find/${id}`)
    .then((res) => {
      return res.data.body as UserType;
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const UsersService = {
  findAll,
  findById,
  findByEmail,
  findRolesById,
  findProgramsByUser,
  findOrganizationsByUser,
  createOne,
  updateOne,
  deleteOne,
  resendInvitation,
};
