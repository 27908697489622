import React, { FC } from "react";
import MUIButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { SxProps, Theme } from "@mui/system";

interface ButtonProps {
  variantType: "cancel" | "save";
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

const Button: FC<ButtonProps> = ({
  variantType,
  label,
  onClick,
  disabled = false,
  isLoading = false,
  sx,
}) => {
  return (
    <MUIButton
      size="large"
      onClick={onClick}
      variant={variantType === "cancel" ? "outlined" : "contained"}
      color={variantType === "cancel" ? "inherit" : "primary"}
      disabled={disabled}
      startIcon={
        isLoading && variantType === "save" ? (
          <CircularProgress data-testid="CircularProgress" size={16} />
        ) : null
      }
      sx={sx}
    >
      {label}
    </MUIButton>
  );
};

export default Button;
