import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

interface GenericModalProps {
  onClick?: () => void;
  onCancel?: () => void;
  title?: string;
  description: string | React.ReactNode;
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  icon?: React.ReactNode;
  showIcon?: boolean;
  theme?: Theme;
  showFotter?: boolean;
  style?: React.CSSProperties;
}

const GenericAlertModal: React.FC<GenericModalProps> = ({
  onClick,
  onCancel,
  title,
  description,
  submitText,
  cancelText,
  isLoading,
  icon,
  showIcon = false,
  theme,
  showFotter = true,
  style,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      onBackdropClick={onCancel}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "280px",
          display: "flex",
          backgroundColor: theme?.palette?.surface?.main,
          textAlign: "center",
          borderRadius: "15px",
          minHeight: "220px",
          ...style,
        },
      }}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="220px"
          padding="24px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {showIcon && (
            <div style={{ marginTop: "1.5rem" }}>
              {icon || <CancelPresentationIcon fontSize="medium" />}
            </div>
          )}
          {title && (
            <DialogTitle
              sx={{
                padding: showIcon ? "1rem 1.25rem" : "1.25rem",
                textAlign: "left",
              }}
            >
              <Typography variant="headlineSmall" color="#261F1F">
                {title}
              </Typography>
            </DialogTitle>
          )}
          <DialogContent style={{ textAlign: "left" }}>
            <DialogContentText variant="bodyMedium" color="#564C4C">
              {description}
            </DialogContentText>
          </DialogContent>

          {showFotter && (
            <DialogActions
              style={{ paddingRight: "24px", paddingBottom: "24px" }}
            >
              {cancelText && (
                <Button
                  onClick={onCancel}
                  variant="text"
                  style={{
                    color: theme?.palette.primary.main,
                    textTransform: "capitalize",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {cancelText}
                </Button>
              )}
              <Button
                onClick={onClick}
                variant="text"
                style={{
                  color: theme?.palette.primary.main,
                  textTransform: "capitalize",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {submitText}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};
export default GenericAlertModal;
