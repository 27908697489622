import React from "react";
import { FC } from "react";
import { DragIndicator } from "@mui/icons-material";
import { Avatar, Card, Grid, Typography } from "@mui/material";
import { useAppTheme } from "@/utils/theme";
import { EventTypeEnum } from "@/services/rewards-engine.service";
import { useRewardsEngine } from "@/hooks/useRewardsEngine";

export type StepCardProps = {
  parcaPoints: number;
  title: string;
  description: string;
};

export const GoalTemplateStepCard: FC<StepCardProps> = (props) => {
  const theme = useAppTheme();

  const { data: stepRewardsDataList } = useRewardsEngine(true, {
    eventType: EventTypeEnum.STEP_COMPLETION,
    event: props.title,
  });

  return (
    <Card
      sx={{
        bgcolor: (theme) => theme.palette.surface.light2,
        padding: 2.5,
      }}
    >
      <Grid container>
        <Grid
          item
          container
          xs={1}
          sm={0.5}
          margin={"auto"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <DragIndicator htmlColor={theme.palette.icon.dark} />
        </Grid>
        <Grid
          item
          container
          xs={2}
          sm={1.5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Avatar
            sx={{
              bgcolor: "lightblue",
              width: 42,
              height: 42,
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              display={"flex"}
              variant="labelLarge"
              style={{ fontWeight: "500" }}
              color={"black"}
              lineHeight={"1"}
            >
              {(stepRewardsDataList?.length &&
                stepRewardsDataList[0].fixedLadderConfiguration?.[0]?.points) ||
                0}
            </Typography>
            <Typography
              display={"flex"}
              variant="labelSmall"
              color={"black"}
              lineHeight={"1"}
              style={{ fontWeight: "500" }}
            >
              PP
            </Typography>
          </Avatar>
        </Grid>
        <Grid
          item
          container
          xs
          sm
          direction={"column"}
          justifyContent={"center"}
        >
          <Grid item>
            <Typography variant="bodyLarge">{props.title}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="bodySmall"
              color={(theme) => theme.palette.textMain.main2}
            >
              {props.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GoalTemplateStepCard;
