import { UpdateStepPayload } from "@/domain/goal.interface";
import { GoalService } from "@/services/goals.service";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGoalsByEnrollment = (enrollmentId: string) => {
  const {
    data,
    isLoading: isLoadingGoals,
    isError: isErrorGoals,
    refetch: refetchGoals,
  } = useQuery({
    queryKey: ["parca-goals-by-enrollment", enrollmentId],
    queryFn: () => GoalService.findGoalsByEnrollment(enrollmentId),
    enabled: !!enrollmentId,
  });
  return {
    data,
    isLoadingGoals,
    isErrorGoals,
    refetchGoals,
  };
};

export const useMarkGoalViewed = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      goalId,
    }: {
      enrollmentId: string;
      goalId: string;
    }) => await GoalService.updateGoalIsViewed(enrollmentId, goalId),
  });
};

export const useUpdateStep = () => {
  return useMutation({
    mutationFn: async ({
      enrollmentId,
      goalId,
      stepId,
      submissionId,
      data,
    }: {
      enrollmentId: string;
      goalId: string;
      stepId: string;
      submissionId: string;
      data: UpdateStepPayload;
    }) =>
      await GoalService.updateStep({
        enrollmentId,
        goalId,
        stepId,
        submissionId,
        data,
      }),
  });
};

export const useValidationProofLinks = (
  enrollmentId: string,
  goalId: string,
  keys: string[]
) => {
  const {
    data,
    isLoading: isLoadingValidationProofLinks,
    isError: isErrorValidationProofLinks,
    refetch: refetchValidationProofLinks,
  } = useQuery({
    queryKey: ["parca-validation-proof-links", enrollmentId, goalId, ...keys],
    queryFn: () => GoalService.validationProofLinks(enrollmentId, goalId, keys),
    enabled: !!enrollmentId && !!goalId && keys?.length > 0,
  });
  return {
    data,
    isLoadingValidationProofLinks,
    isErrorValidationProofLinks,
    refetchValidationProofLinks,
  };
};
