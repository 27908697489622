import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, Link as RouterLink, useParams } from "react-router-dom";
import theme, { ExtendedTypographyOptions } from "@/utils/theme";

interface RouteParams {
  participantId?: string;
  [key: string]: string | undefined;
}

const ProfileBreadcrumb: React.FC = () => {
  const { t } = useTranslation("clients");
  const location = useLocation();
  const { participantId } = useParams<RouteParams>();

  const pathnames: string[] = location.pathname
    .split("/")
    .filter((x: string) => x);

  const getBreadcrumbLabel = (segment: string): string => {
    switch (segment) {
      case "participants":
        return t("client_profile.breadcrumb_clients");
      case "goals":
        return t("client_profile.breadcrumb_goals");
      case participantId:
        return t("client_profile.breadcrumb_client_profile");
      case "journals":
        return t("client_profile.actions_journal_entries");
      default:
        return segment;
    }
  };

  return (
    <Breadcrumbs separator={<ChevronRightIcon />} aria-label="breadcrumb">
      {pathnames.map((value: string, index: number) => {
        const isLast: boolean = index === pathnames.length - 1;
        const to: string = `/${pathnames.slice(0, index + 1).join("/")}`;

        return isLast ? (
          <Typography
            key={value}
            fontSize={
              (theme.typography as ExtendedTypographyOptions).bodyMedium
                ?.fontSize
            }
            fontWeight="bold"
            textTransform={"uppercase"}
            color={theme.palette.textMain.dark}
          >
            {getBreadcrumbLabel(value)}
          </Typography>
        ) : (
          <Link
            key={value}
            component={RouterLink}
            to={to}
            textTransform={"uppercase"}
            underline="hover"
            color={theme.palette.menu.dark}
            fontSize={
              (theme.typography as ExtendedTypographyOptions).bodyMedium
                ?.fontSize
            }
            fontWeight="bold"
          >
            {getBreadcrumbLabel(value)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default ProfileBreadcrumb;
