import React, { ReactNode } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type SkeletonLoaderProps = {
  isLoading: boolean;
  children: ReactNode;
  skeletonType?: "rectangular" | "text" | "circular";
  skeletonCount?: number;
  skeletonHeight?: number | string;
  skeletonWidth?: number | string;
};

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  isLoading,
  children,
  skeletonType = "rectangular",
  skeletonCount = 1,
  skeletonHeight = 40,
  skeletonWidth = "100%",
}) => {
  if (isLoading) {
    return (
      <Stack spacing={2}>
        {Array.from({ length: skeletonCount }).map((_, index) => (
          <Skeleton
            key={index}
            variant={skeletonType}
            height={skeletonHeight}
            width={skeletonWidth}
            data-testid="skeleton"
          />
        ))}
      </Stack>
    );
  }

  return <>{children}</>;
};

export default SkeletonLoader;
