"use client";

import React, { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";
import { FeedBackProvider } from "../providers/FeedBackProvider/FeedBackProvider";
import { ClientsFiltersProvider } from "@/providers/ClientsFiltersContext";
import theme from "./theme";
import { EarningsFiltersProvider } from "@/providers/EarningsFiltersContext";
import { ChatMessagesProvider } from "@/providers/ChatContext";

function Providers({ children }: React.PropsWithChildren) {
  const [client] = React.useState(new QueryClient());

  // adding variables to adjust the chat colors in the css
  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--outgoing-message-bg",
      theme.palette.surface.light2 || "#E7F3FF"
    );
    document.documentElement.style.setProperty(
      "--incoming-message-bg",
      theme.palette.surface.main || "#B5E0FF"
    );
    document.documentElement.style.setProperty(
      "--send-message-button-bg",
      theme.palette.primary.main || "#355DE8"
    );
  }, [theme]);

  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <ClientsFiltersProvider>
          <EarningsFiltersProvider>
            <FeedBackProvider>
              <ChatMessagesProvider>
                <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
              </ChatMessagesProvider>
            </FeedBackProvider>
          </EarningsFiltersProvider>
        </ClientsFiltersProvider>
      </ThemeProvider>
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default Providers;
