import { CategoryService } from "@/services/categories.service";
import { useQuery } from "@tanstack/react-query";

export const useCategoryByType = ({
  type,
  status,
  action,
}: {
  type: string;
  status: string;
  action?: string;
}) => {
  const {
    data: category,
    isLoading: isLoadingCategory,
    refetch: refetchCategory,
  } = useQuery({
    queryKey: ["category", { type }, { action }],
    queryFn: async () => CategoryService.findByType({ type, status }),
  });

  return {
    category,
    isLoadingCategory,
    refetchCategory,
  };
};
