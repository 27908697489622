import { useQuery } from "@tanstack/react-query";
import { programService } from "@/services/program.service";
import { useProgramsByUser } from "./useUsers";

export const useGetPrograms = ({ sortType = "asc" }: { sortType?: string }) => {
  const {
    data: programs,
    isLoading: isLoadingPrograms,
    isFetching: isFetchingPrograms,
    refetch: refetchPrograms,
  } = useQuery({
    queryKey: ["get-programs"],
    queryFn: () => programService.getPrograms({ sortType }),
    staleTime: 1 * 60 * 60 * 1000,
  });

  return {
    programs,
    isLoadingPrograms,
    isFetchingPrograms,
    refetchPrograms,
  };
};

export const useGetUserPrograms = ({
  userId,
  sortType = "asc",
  hasSystemAdminRole,
}: {
  userId: string;
  sortType?: string;
  hasSystemAdminRole: boolean;
}) => {
  if (hasSystemAdminRole) {
    return useGetPrograms({ sortType });
  } else {
    return useProgramsByUser({ userId, sortType });
  }
};
