import { AlertProps } from "@mui/material";
import React from "react";

type SnackBarContextType = {
  showSnackBar: (message: string, severity?: AlertProps["severity"]) => void;
  hideSnackBar: () => void;
  showSimpleAlert: (message: string, severity?: AlertProps["severity"]) => void;
  hideSimpleAlert: () => void;
  openLinkInTab: (link: string) => void;
};

export const SnackBarContext = React.createContext<SnackBarContextType | null>(
  null
);

export const useFeedBack = () => {
  const context = React.useContext(SnackBarContext);
  if (!context) {
    throw new Error("useSnackBar must be used within a SnackBarContext");
  }
  return context;
};
