import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useAppTheme } from "@/utils/theme";
import useDebounce from "@/hooks/useDebounce";
import { MODULE_NAME, OPTIONS, ACTION_CODE } from "@/domain/auditlog.enum";
import {
  useAuditLogFirstRender,
  useCreateAuditlog,
} from "@/hooks/useAuditLogs";
import { useUserStore } from "@/hooks/useUserStore";
import ClientFilters from "@/ui/content/clients/Filters";
import { useClients } from "@/hooks/useClients";
import DataTable, { createDataRow, HeadCell } from "@/components/DataTable";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { getRegistrationStageText } from "@/utils/helperStatus";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import StatsCard from "@/components/StatsCard";
import ClientInviteModal from "@/ui/modals/clients/ClientInviteModal";
import { usePageStore } from "@/hooks/usePageStore";
import AuditLogModal from "@/ui/modals/AuditLogModal";
import { SkeletonClientButtons } from "@/ui/menus/clients/Skeletons";
import { useClientFilters } from "@/providers/ClientsFiltersContext";
import { EnrollmentStatus } from "@/domain/participant.enum";
import { useColorModuleSetting } from "@/hooks/useSetting";
import { useCategoryByType } from "@/hooks/useCategories";
import { CategoryElement } from "@/domain/category.interface";
import { useCurrentDayColor } from "@/hooks/useScreeningColors";

const ClientsPage: FC = () => {
  const theme = useAppTheme();
  const { userInfo } = useUserStore();
  const createAuditLog = useCreateAuditlog();
  const { setPageTitle } = usePageStore();
  const colorModuleSetting = useColorModuleSetting();
  const navigate = useNavigate();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [openAuditLogModal, setOpenAuditLogModal] = useState(false);

  const handleOpenAuditLog = () => {
    setOpenAuditLogModal(true);
  };

  const { filters, setFilters, setPersistFilters } = useClientFilters();
  const debouncedInputValue = useDebounce({ value: filters.search });

  const { clients, isLoadingClients, isFetchingClients, refetchClients } =
    useClients({
      sortField: filters.sortField,
      sortOrder: filters.sortOrder,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      search: debouncedInputValue,
      status: filters.status,
      programId: filters.programId,
      userId: userInfo?._id as string,
    });
  const { category, isLoadingCategory } = useCategoryByType({
    type: "COLORS",
    status: "ACTIVE",
  });

  const colors = useMemo(() => {
    if (!category) return [];

    return category?.categories.map((categoryElement: CategoryElement) => {
      const name = categoryElement.languages.find(
        (language) => language.language === "en-us"
      )?.name as string;

      return {
        key: categoryElement._id,
        value: name,
        hexaColor: categoryElement.hexaColor,
      };
    });
  }, [category]);

  const { currentDayColor, isLoadingCurrentDayColor } = useCurrentDayColor({
    date: new Date().toISOString(),
    programId: filters.programId,
  });

  const coloringModule = useMemo(
    () => colorModuleSetting,
    [colorModuleSetting]
  );

  const rows = useMemo(() => {
    if (clients && clients.participants.length === 0) return [];
    return (
      clients?.participants.map((client) => {
        const status = client.lastEnrollment.status;

        const colorMatch = colors?.find(
          (color) => color.key === client?.lastEnrollment?.colorId
        );

        const record: Record<string, ReactNode> = {
          fullname: (
            <div>
              {`${client.participant.firstName} ${client.participant.lastName}`}
            </div>
          ),
          registrationStage: (
            <div>{getRegistrationStageText(client.participant.status)}</div>
          ),
          status: (
            <div>
              <Chip
                sx={{
                  bgcolor:
                    status === "ACTIVE"
                      ? theme.palette.brand.light
                      : theme.palette.warning.light,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor:
                    status === "ACTIVE"
                      ? theme.palette.brand.main
                      : theme.palette.warning.dark,
                }}
                label={
                  <div className="text-xs">
                    {
                      EnrollmentStatus[
                        client.lastEnrollment
                          .status as keyof typeof EnrollmentStatus
                      ]
                    }
                  </div>
                }
              />
            </div>
          ),
        };
        if (coloringModule) {
          record["color"] = colorMatch ? (
            <div className="flex items-center">
              <Box
                sx={{
                  bgcolor: colorMatch?.hexaColor,
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  marginRight: 1,
                }}
              />
              <span>{colorMatch?.value}</span>
            </div>
          ) : (
            <div style={{ color: theme.palette.textMain.light2 }}>
              Not assigned
            </div>
          );
        }
        record["actions"] = (
          <div className="flex flex-1 justify-end">
            <ChevronRightOutlinedIcon />
          </div>
        );
        const row = createDataRow(client._id, record);

        return row;
      }) || []
    );
  }, [clients, colors]);

  const headCells = useMemo(() => {
    const baseHeadCells: HeadCell[] = [
      {
        id: "firstName",
        isSortable: true,
        disablePadding: true,
        label: "Full name",
        align: "left",
        width: "220px",
      },
      {
        id: "registrationStage",
        isSortable: false,
        disablePadding: false,
        label: "Registration stage",
        align: "left",
        width: "200px",
      },
      {
        id: "status",
        isSortable: true,
        disablePadding: false,
        label: "Status",
        align: "left",
        width: "200px",
      },
    ];
    if (coloringModule) {
      baseHeadCells.push({
        id: "color",
        isSortable: true,
        disablePadding: false,
        label: "Color assigned",
        align: "left",
        width: "250px",
      });
    }
    baseHeadCells.push({
      id: "actions",
      isSortable: false,
      disablePadding: false,
      label: "",
      align: "left",
      width: "250px",
    });
    return baseHeadCells;
  }, [coloringModule]);

  useEffect(() => {
    if (debouncedInputValue !== "") {
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.USERS,
        option: OPTIONS.PARTICIPANTS_LIST,
        actionCode: ACTION_CODE.WEB_PART_LIST_SEARCH,
        action: "When searching",
        detail: "Searched within the list of clients",
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
      });
    }
  }, [debouncedInputValue]);

  useAuditLogFirstRender({
    appType: "WEB_BACK_OFFICE",
    module: MODULE_NAME.USERS,
    option: OPTIONS.PARTICIPANTS_LIST,
    actionCode: ACTION_CODE.WEB_PART_LIST_VIEW,
    action: "When entering",
    detail: "Viewed the list of clients",
    transactionDate: new Date(),
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    createdBy: userInfo?._id as string,
  });

  useEffect(() => {
    refetchClients();
  }, [
    filters.sortField,
    filters.sortOrder,
    filters.pageNumber,
    filters.pageSize,
    filters.status,
    filters.programId,
  ]);

  useEffect(() => {
    setPageTitle("Clients");
  }, []);

  return (
    <>
      <div className="w-full overflow-y-auto scroll-smooth">
        <div className="full-width-container mx-auto">
          <div className="flex flex-col items-center justify-start min-h-screen">
            <Box
              sx={{
                display: "none",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
                gap: "1.25rem",
                width: "100%",
              }}
            >
              <StatsCard
                title="Reward request pending"
                value="5"
                icon={<AccountCircleOutlinedIcon />}
              />

              <StatsCard
                title="Rewards requests approved"
                value="8"
                icon={<CheckCircleOutlinedIcon />}
              />

              <StatsCard
                title="Rewards requests rejected"
                value="3"
                icon={<CancelOutlinedIcon />}
              />
            </Box>
            <Card sx={{ width: "100%", borderRadius: "8px" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography variant="headlineSmall" component="h1">
                      Clients
                    </Typography>
                    {coloringModule && !isLoadingCurrentDayColor ? (
                      <>
                        <Typography variant="headlineSmall" component="h1">
                          -
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: currentDayColor?.color?.hexaColor,
                            display: currentDayColor?.color?.hexaColor
                              ? "block"
                              : "none",
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          variant="titleSmall"
                          component="h2"
                          fontWeight={700}
                        >
                          Today’s color{" "}
                          {currentDayColor
                            ? `is ${
                                currentDayColor?.color?.languages.find(
                                  (language) => language.language === "en-us"
                                )?.name
                              }`
                            : "has not been assigned"}
                        </Typography>
                      </>
                    ) : null}
                  </Box>

                  <Box display="flex" gap="0.5rem">
                    {isLoadingClients || isFetchingClients ? (
                      <SkeletonClientButtons />
                    ) : (
                      <>
                        <Tooltip title="Audit log">
                          <IconButton
                            size="small"
                            sx={{
                              borderRadius: "8px",
                              border: `1px solid ${theme.palette.primary.dark}`,
                              height: "40px",
                              width: "40px",
                            }}
                            onClick={handleOpenAuditLog}
                          >
                            <HistoryOutlinedIcon
                              sx={{ color: theme.palette.primary.dark }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Button
                          size={"medium"}
                          onClick={() => setShowInviteModal(true)}
                          variant="contained"
                          sx={{
                            borderRadius: "8px",
                            textTransform: "none",
                            textAlign: "center",
                            height: "40px",
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.textMain.light,
                          }}
                          startIcon={<AddIcon />}
                        >
                          ADD
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>

                <ClientFilters filtersState={[filters, setFilters]} />

                <DataTable
                  data={rows}
                  headCells={headCells}
                  order={filters.sortOrder === 1 ? "asc" : "desc"}
                  orderBy={filters.sortField}
                  rowsPerPage={filters.pageSize}
                  page={filters.pageNumber}
                  total={clients?.totalCount}
                  isLoading={
                    isLoadingClients || isFetchingClients || isLoadingCategory
                  }
                  onChangePage={(event, newPage) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: newPage,
                    }));
                  }}
                  onChangeSize={(event) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: 0,
                      pageSize: Number(event.target.value),
                    }));
                  }}
                  onChangeSort={(order, orderBy) => {
                    setFilters((filters) => ({
                      ...filters,
                      pageNumber: 0,
                      sortField: orderBy as string,
                      sortOrder: order === "asc" ? 1 : -1,
                    }));
                  }}
                  onRowClick={(_, id) => {
                    setPersistFilters(true);
                    navigate(`/participants/${id}`);
                  }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {showInviteModal ? (
        <ClientInviteModal
          open={showInviteModal}
          onClose={() => {
            setShowInviteModal(false);
            refetchClients();
          }}
        />
      ) : null}

      {openAuditLogModal ? (
        <AuditLogModal
          open={openAuditLogModal}
          module={MODULE_NAME.PARTICIPANTS_LIST}
          onClose={() => setOpenAuditLogModal(false)}
        />
      ) : null}
    </>
  );
};

export default ClientsPage;
