import { Enrollment } from "./enrollment.interface";
import { Programs } from "./programs.interface";

export enum ParticipantStatus {
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  PAUSED = "PAUSED",
  VERIFIED = "VERIFIED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface TermCondition {
  accepted: boolean;
  termId: string;
  executionDate: string;
  revision: string;
}

export interface Invitation {
  invitationDate: string;
  code: string;
  status: string;
}

export interface MobileUser {
  status: string;
  authenticationId: string;
  lastLoginDate: Date;
}

export interface Officer {
  id: string;
  name: string;
}

export interface Participant {
  _id: string;
  isDeleted: boolean;
  createdBy: string;
  updatedBy: string;
  accountId: string;
  firstName: string;
  lastName: string;
  countryPhoneCode: string;
  phoneNumber: string;
  email: string;
  dateOfBirth: string;
  status: ParticipantStatus;
  termConditions: TermCondition[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  invitations: Invitation;
  mobileUser: MobileUser;
  primaryOfficer: string;
}

export interface ParticipantResponse {
  participant: Participant;
  lastEnrollment: Enrollment;
  program: Programs;
}
