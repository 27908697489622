import React, { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataTable, { createDataRow, HeadCell } from "@/components/DataTable";
import { Link } from "react-router-dom";
import { Chip, Button } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import {
  useRewardsEngine,
  useCreateRewardConfig,
} from "@/hooks/useRewardsEngine";
import { usePageStore } from "@/hooks/usePageStore";
import RewardsConfigModal, {
  RewardsConfigFormData,
} from "@/ui/modals/rewardsConfig/RewardsConfigurationModal";
import { useUserStore } from "@/hooks/useUserStore";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { RewardConfigurationType } from "@/services/rewards-engine.service";

const RewardsConfigPage: FC = () => {
  const { setPageTitle } = usePageStore();
  const { userInfo } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const {
    data: rewardsEngineList,
    isLoading,
    isFetching,
  } = useRewardsEngine(true);
  const { mutateAsync: createRewardConfig, isPending: isSaving } =
    useCreateRewardConfig();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSave = async (data: RewardsConfigFormData) => {
    const {
      fixedLadderConfiguration,
      programmableLadderConfiguration,
      invalidResult,
      validValue,
      invalidValue,
      ...rest
    } = data;

    const cleanedData = {
      ...rest,
      ...(data.rewardConfigurationType ===
        RewardConfigurationType.FixedLadder && {
        fixedLadderConfiguration,
      }),
      ...(data.rewardConfigurationType ===
        RewardConfigurationType.ProgrammableLadder && {
        programmableLadderConfiguration: programmableLadderConfiguration
          ? [programmableLadderConfiguration]
          : undefined,
      }),
      ...(data.rewardBasedOnResult && {
        invalidResult,
        validValue,
        invalidValue,
      }),
      accountId: userInfo?.accountId,
      createdBy: userInfo?._id,
    };

    try {
      await createRewardConfig(cleanedData);
      handleCloseModal();
    } catch (error) {
      showSnackBar("Error saving reward config: " + error);
    }
  };

  const rows = useMemo(() => {
    if (!rewardsEngineList || rewardsEngineList.length === 0) return [];

    return rewardsEngineList.map((reward, index) => {
      const row = createDataRow(index, {
        eventType: reward.eventType,
        event: reward.event,
        rewardConfigurationType: reward.rewardConfigurationType,
        configurationDetails: (
          <Chip
            label={`Ladder Steps: ${
              reward.fixedLadderConfiguration
                ? reward.fixedLadderConfiguration.length
                : reward.programmableLadderConfiguration
                  ? "Programmable Ladder"
                  : "No Details"
            }`}
          />
        ),
        actions: (
          <Link
            to={`/rewards-engine/${reward._id}`}
            className="text-dark3 underline font-bold"
          >
            View Details <ChevronRightOutlinedIcon />
          </Link>
        ),
      });
      return row;
    });
  }, [rewardsEngineList]);

  const headCells: HeadCell[] = [
    {
      id: "eventType",
      isSortable: true,
      disablePadding: false,
      label: "Event Type",
      align: "left",
      width: "20%",
    },
    {
      id: "event",
      isSortable: true,
      disablePadding: false,
      label: "Event",
      align: "left",
      width: "20%",
    },
    {
      id: "rewardConfigurationType",
      isSortable: true,
      disablePadding: false,
      label: "Configuration Type",
      align: "left",
      width: "20%",
    },
    {
      id: "configurationDetails",
      isSortable: false,
      disablePadding: false,
      label: "Details",
      align: "left",
      width: "30%",
    },
    {
      id: "actions",
      isSortable: false,
      disablePadding: true,
      label: "Actions",
      align: "center",
      width: "10%",
    },
  ];

  useEffect(() => {
    setPageTitle("Rewards Configuration");
  }, []);

  return (
    <Box className="w-full overflow-y-auto">
      <div className="full-width-container mx-auto">
        <div className="flex flex-col items-center justify-start min-h-screen">
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              Create New Configuration
            </Button>
          </Box>

          <Card sx={{ width: "100%", borderRadius: "8px" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <DataTable
                data={rows}
                headCells={headCells}
                order="asc"
                orderBy="event"
                rowsPerPage={10}
                page={0}
                total={rewardsEngineList?.length || 0}
                isLoading={isLoading || isFetching}
              />
            </CardContent>
          </Card>
        </div>
      </div>

      <RewardsConfigModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        isPending={isSaving}
      />
    </Box>
  );
};

export default RewardsConfigPage;
