import React from "react";
import ProfileBreadcrumb from "@/ui/breadcrumbs/clients/ProfileBreadcrumb";
import ClientNameCard from "../ProfileName";
import { ParticipantResponse } from "@/domain/participant.interface";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";

interface GoalHeaderProps {
  isMobile: boolean;
  selectedGoal: boolean;
  onGoBack: () => void;
  headerText: string;
  participantResponse: ParticipantResponse;
  isLoadingParticipant: boolean;
}

const GoalHeader: React.FC<GoalHeaderProps> = ({
  isMobile,
  selectedGoal,
  onGoBack,
  headerText,
  participantResponse,
  isLoadingParticipant,
}) => {
  if (selectedGoal && isMobile) {
    return (
      <Box display="flex" alignItems="center">
        <IconButton onClick={onGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="headlineSmall">{headerText}</Typography>
      </Box>
    );
  }
  return (
    <>
      <ProfileBreadcrumb />
      <ClientNameCard
        isLoading={isLoadingParticipant}
        firstName={participantResponse?.participant?.firstName}
        lastName={participantResponse?.participant?.lastName}
      />
    </>
  );
};

export default GoalHeader;
