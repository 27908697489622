import { AppTheme } from "./theme";
import { ParticipantStatus, EnrollmentStatus } from "@/domain/participant.enum";

export const getStatusColor = (result: string, theme: AppTheme) => {
  switch (result) {
    case ParticipantStatus.PENDING_VERIFICATION:
      return theme.palette.primary.light;
    case ParticipantStatus.PENDING_ACTIVATION:
      return theme.palette.secondary.light;
    case ParticipantStatus.DENIED:
      return theme.palette.error.light;
    case ParticipantStatus.ACTIVE:
      return "#FCEAD0";
    default:
      return "#FCEAD0";
  }
};

export const getStatusText = (result: string) => {
  switch (result) {
    case ParticipantStatus.PENDING_VERIFICATION:
      return EnrollmentStatus.PENDING_VERIFICATION;
    case ParticipantStatus.PENDING_ACTIVATION:
      return EnrollmentStatus.PENDING_ACTIVATION;
    case ParticipantStatus.ACTIVE:
      return EnrollmentStatus.PENDING_TO_ENROLL;
    case ParticipantStatus.DENIED:
      return EnrollmentStatus.DENIED;
    default:
      return EnrollmentStatus.PENDING_VERIFICATION;
  }
};

export const getRegistrationStageText = (result: string) => {
  switch (result) {
    case ParticipantStatus.PENDING_VERIFICATION:
    case ParticipantStatus.PENDING_ACTIVATION:
      return "Pending";
    case ParticipantStatus.ACTIVE:
      return "Completed";
    default:
      return "Completed";
  }
};
