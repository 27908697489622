import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Grid,
  Button,
  Box,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Modal from "@/components/Modal";
import { useAppTheme } from "@/utils/theme";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { RewardConfigurationType } from "@/services/rewards-engine.service";

export interface RewardsConfigFormData {
  eventType: string;
  event: string;
  rewardConfigurationType: RewardConfigurationType;
  fixedLadderConfiguration?: { index: number; points: number }[];
  programmableLadderConfiguration?: {
    startValue: number;
    stepValue: number;
    maxValue: number;
    resetValue: number;
  };
  maxOccurrences?: number;
  rewardBasedOnResult?: boolean;
  validValue?: string;
  invalidValue?: string;
  invalidResult?: {
    invalidResultType: string;
    rewardForInvalid: string;
    resetRuleType: string;
    specificIndex: number;
  };
  createdBy: string;
}

const defaultValues: RewardsConfigFormData = {
  eventType: "",
  event: "",
  rewardConfigurationType: RewardConfigurationType.FixedLadder,
  fixedLadderConfiguration: [{ index: 1, points: 0 }],
  programmableLadderConfiguration: {
    startValue: 0,
    stepValue: 1,
    maxValue: 5,
    resetValue: 0,
  },
  maxOccurrences: 3,
  rewardBasedOnResult: false,
  createdBy: "",
};

interface RewardsConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: RewardsConfigFormData) => void;
  isPending?: boolean;
}

const RewardsConfigModal: React.FC<RewardsConfigModalProps> = ({
  open,
  onClose,
  onSave,
  isPending = false,
}) => {
  const theme = useAppTheme();
  const { handleSubmit, control, watch, setValue } =
    useForm<RewardsConfigFormData>({
      defaultValues,
    });

  const rewardConfigurationType = watch("rewardConfigurationType");
  const rewardBasedOnResult = watch("rewardBasedOnResult");
  const maxOccurrences =
    watch("maxOccurrences") || defaultValues.maxOccurrences;
  const fixedLadderConfiguration = watch("fixedLadderConfiguration");

  const [currentSteps, setCurrentSteps] = useState(
    defaultValues.fixedLadderConfiguration?.length || 1
  );

  const onSubmit = (data: RewardsConfigFormData) => {
    onSave(data);
  };

  const handleAddStep = () => {
    setCurrentSteps((prev) => prev + 1);
    setValue(`fixedLadderConfiguration.${currentSteps}`, {
      index: currentSteps + 1,
      points: 0,
    });
  };

  const handleRemoveStep = (stepIndex: number) => {
    setValue(
      "fixedLadderConfiguration",
      fixedLadderConfiguration?.filter((_, index) => index !== stepIndex)
    );
    setCurrentSteps((prev) => prev - 1);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={isPending}
      title="Create Rewards Configuration"
      theme={theme}
      size={{
        width: "100%",
        minHeight: 640,
        maxHeight: 720,
        maxWidth: 760,
      }}
      content={
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Controller
                  name="eventType"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Event Type"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="event"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Event"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="rewardConfigurationType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Reward Configuration Type"
                      defaultValue="FIXED_LADDER"
                    >
                      <MenuItem value="FIXED_LADDER">Fixed Ladder</MenuItem>
                      <MenuItem value="PROGRAMMABLE_LADDER">
                        Programmable Ladder
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              {rewardConfigurationType === "FIXED_LADDER" && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name="maxOccurrences"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Max Occurrences"
                          fullWidth
                          variant="outlined"
                          type="number"
                          inputProps={{ min: 1, max: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  {fixedLadderConfiguration?.map((_, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={5}>
                        <Controller
                          name={`fixedLadderConfiguration.${index}.index`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={`Ladder Index ${index + 1}`}
                              fullWidth
                              variant="outlined"
                              type="number"
                              InputProps={{ readOnly: true }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          name={`fixedLadderConfiguration.${index}.points`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Points"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {index > 0 && (
                          <IconButton
                            onClick={() => handleRemoveStep(index)}
                            color="error"
                            aria-label="remove step"
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                  {currentSteps < (maxOccurrences || 1) && (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddStep}
                        disabled={currentSteps >= (maxOccurrences || 1)}
                      >
                        Add Step
                      </Button>
                    </Grid>
                  )}
                </>
              )}

              {rewardConfigurationType === "PROGRAMMABLE_LADDER" && (
                <>
                  <Grid item xs={3}>
                    <Controller
                      name="programmableLadderConfiguration.startValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Start Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="programmableLadderConfiguration.stepValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Step Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="programmableLadderConfiguration.maxValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Max Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="programmableLadderConfiguration.resetValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Reset Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Controller
                  name="rewardBasedOnResult"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) =>
                            setValue("rewardBasedOnResult", e.target.checked)
                          }
                        />
                      }
                      label="Reward Based on Result"
                    />
                  )}
                />
              </Grid>

              {rewardBasedOnResult && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name="validValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Valid Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="invalidValue"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Invalid Value"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      name="invalidResult.invalidResultType"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Invalid Result Type"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="invalidResult.rewardForInvalid"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Reward for Invalid"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="invalidResult.resetRuleType"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Reset Rule Type"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="invalidResult.specificIndex"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Specific Index"
                          fullWidth
                          variant="outlined"
                          type="number"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Box>
      }
      actions={
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">
            Create
          </Button>
        </Box>
      }
    />
  );
};

export default RewardsConfigModal;
