import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useMemo } from "react";
import { SkeletonScreeningColorsFilters } from "./skeletons";
import Select from "@/components/Select/Select";
import Datepicker from "@/components/Datepicker";
import { Programs } from "@/domain/programs.interface";

export type Filters = {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  programId: string;
  startDate: Dayjs;
  endDate: Dayjs;
};

export const defaultFilters: Filters = {
  sortField: "date",
  sortOrder: 1,
  pageNumber: 0,
  pageSize: 10,
  programId: "",
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
};

type Props = {
  filtersState: [Filters, React.Dispatch<React.SetStateAction<Filters>>];
  programs: Programs[] | undefined;
  isLoadingPrograms: boolean;
  isFetchingPrograms: boolean;
};

export const ScreeningColorsFilters: FC<Props> = ({
  filtersState,
  programs,
  isLoadingPrograms,
  isFetchingPrograms,
}) => {
  const [filters, setFilters] = filtersState;

  const programsOptions = useMemo(() => {
    if (!programs) return [];
    return programs.map((program) => ({
      key: program._id,
      value: program.name,
    }));
  }, [programs]);

  useEffect(() => {
    if (programs && programs.length > 0 && !filters.programId) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        programId: programs[0]._id,
      }));
    }
  }, [programs, filters.programId, setFilters]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.25rem",
        width: "100%",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
        },
      }}
    >
      {isFetchingPrograms || isLoadingPrograms ? (
        <SkeletonScreeningColorsFilters />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              width: {
                xs: "100%",
                sm: "100%",
              },
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
            }}
          >
            <Select
              handleChange={(value) => {
                setFilters((filters) => ({
                  ...filters,
                  programId: value as string,
                  pageNumber: 0,
                }));
              }}
              data={programsOptions || []}
              value={filters.programId}
              label="Program"
              id="users-program"
              isLoading={isLoadingPrograms}
              maxWidth={320}
              fullWidth
            />

            <Datepicker
              label="Date from"
              onChange={
                ((value: Dayjs) => {
                  setFilters((filters) => ({
                    ...filters,
                    startDate: value?.startOf("day"),
                    pageNumber: 0,
                  }));
                }) as (value: Dayjs | null) => void
              }
              value={filters.startDate}
              sx={{ maxWidth: 220 }}
              disableWrite={true}
              disableFuture={false}
              maxDate={filters.endDate}
            />
            <Datepicker
              label="Date to"
              onChange={
                ((value: Dayjs) => {
                  setFilters((filters) => ({
                    ...filters,
                    endDate: value?.endOf("day"),
                    pageNumber: 0,
                  }));
                }) as (value: Dayjs | null) => void
              }
              value={filters.endDate}
              sx={{ maxWidth: 220 }}
              minDate={filters.startDate}
              disableWrite={true}
              disableFuture={false}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
