import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatepicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { SxProps } from "@mui/material";

interface BasicDatepickerProps {
  value?: Dayjs | null;
  label: string;
  onChange?: (value: Dayjs | null) => void;
  sx?: SxProps<object>;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  disableWrite?: boolean;
  disableFuture?: boolean;
}

const Datepicker: React.FC<BasicDatepickerProps> = ({
  value,
  label,
  onChange,
  sx,
  error,
  helperText,
  required = false,
  minDate,
  maxDate,
  disabled = false,
  disableWrite = false,
  disableFuture = true,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          minWidth: 180,
          display: "flex",
          flexGrow: 1,
          maxWidth: "100%",
          ...sx,
        }}
      >
        <FormControl fullWidth error={error}>
          <MuiDatepicker
            value={value}
            label={label}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            slotProps={{
              textField: {
                error: error,
                helperText: error ? helperText : "",
                required: required,
              },
              field: {
                readOnly: disableWrite,
              },
            }}
            disabled={disabled}
          />
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

export default Datepicker;
