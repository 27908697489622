import { RolesService } from "../services/roles.service";
import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "./useUserStore";
import {
  RoleTypeEnum,
  StandardAccountRoleSubtypeEnum,
} from "@/domain/roles.enum";

export const useRoles = () => {
  const { userInfo } = useUserStore();
  const userRole = userInfo?.roles[0] as unknown as string;

  const { data: roles, isLoading: isLoadingRoles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => RolesService.findAll({ status: "ACTIVE" }),
    select: (data) => {
      if (userRole) {
        if (
          data.some((role) => role.name === "Sysadmin" && role._id === userRole)
        ) {
          return data;
        } else if (
          data.some(
            (role) =>
              (role.name === "Support" || role.name === "Account Admin") &&
              role._id === userRole
          )
        ) {
          data = data.filter((role) => role.name !== "Sysadmin");
        } else {
          data = data.filter((role) => role.type === "STANDARD_SYSTEM_ROLE");
        }
      }
      return data;
    },
  });

  return {
    roles,
    isLoadingRoles,
  };
};

export const useOfficerRoles = () => {
  const { data: officerRoles, isLoading: isLoadingOfficerRoles } = useQuery({
    queryKey: ["officerRoles"],
    queryFn: () => RolesService.findAll({ status: "ACTIVE" }),
    select: (data) => {
      return data.filter(
        (role) =>
          role.type === RoleTypeEnum.STANDARD_ACCOUNT_ROLE &&
          role.subType === StandardAccountRoleSubtypeEnum.OFFICER
      );
    },
  });

  return {
    officerRoles,
    isLoadingOfficerRoles,
  };
};
