import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Providers from "./utils/provider";
import "./i18n/config";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <Providers>
      <Router>
        <AppRoutes />
      </Router>
    </Providers>
  );
}

export default App;
