import React, { useEffect, useState, useMemo } from "react";
import { Box, Checkbox, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "@/components/DataTable";
import { DataRow, HeadCell } from "@/components/DataTable/dataTable.interface";
import { useTranslation } from "react-i18next";
import { ProviderModule } from "@/domain/enrollment.interface";
import { Module } from "@/domain/module.interface";
import theme from "@/utils/theme";

interface SupportStaffPermissionsMatrixProps {
  staffList: ProviderModule[];
  modules: Module[];
  onRemoveStaff: (staff: ProviderModule) => void;
  updateSupportStaffModules: (
    staffId: string,
    updatedModules: string[]
  ) => void;
  isLoading: boolean;
}

const SupportStaffPermissionsMatrix: React.FC<
  SupportStaffPermissionsMatrixProps
> = ({
  staffList,
  modules,
  onRemoveStaff,
  updateSupportStaffModules,
  isLoading,
}) => {
  const { t } = useTranslation("common");
  const [localStaffList, setLocalStaffList] = useState<ProviderModule[]>([]);

  useEffect(() => {
    setLocalStaffList(staffList);
  }, [staffList]);

  const getColumnIndeterminate = (moduleId: string) => {
    const totalChecked = localStaffList.filter((staff) =>
      staff?.modulesIds?.includes(moduleId)
    ).length;
    return totalChecked > 0 && totalChecked < localStaffList.length;
  };

  const handleColumnCheckAll = (moduleId: string, isChecked: boolean) => {
    const updatedList = localStaffList.map((staff) => ({
      ...staff,
      modulesIds: isChecked
        ? addUniqueModuleId(staff.modulesIds, moduleId)
        : staff.modulesIds.filter((id) => id !== moduleId),
    }));

    setLocalStaffList(updatedList);

    updatedList.forEach((staff) => {
      updateSupportStaffModules(staff.id, staff.modulesIds);
    });
  };

  const headCells: HeadCell[] = useMemo(() => {
    if (modules.length === 0) return [];

    const dynamicHeadCells: HeadCell[] = modules.map((module) => ({
      id: module._id,
      label: (
        <Box display="flex" alignItems="center">
          <Checkbox
            indeterminate={getColumnIndeterminate(module._id)}
            checked={localStaffList.every((staff) =>
              staff?.modulesIds?.includes(module._id)
            )}
            onChange={(event) =>
              handleColumnCheckAll(module._id, event.target.checked)
            }
          />
          <Box component="span" whiteSpace={"nowrap"}>
            {module.name}
          </Box>
        </Box>
      ),
      disablePadding: false,
      isSortable: false,
      align: "left",
    }));

    return [
      {
        id: "name",
        label: (
          <Box component="span" sx={{ whiteSpace: "nowrap" }}>
            {t("support_staff_with_access")}
          </Box>
        ),
        disablePadding: true,
        isSortable: false,
        align: "left",
      },
      ...dynamicHeadCells,
      {
        id: "action",
        label: "",
        disablePadding: false,
        isSortable: false,
        align: "right",
      },
    ];
  }, [modules, localStaffList, t]);

  const handleModuleChange = (
    staffId: string,
    moduleId: string,
    isChecked: boolean
  ) => {
    const updatedList = localStaffList.map((staff) =>
      staff.id === staffId
        ? {
            ...staff,
            modulesIds: isChecked
              ? [...staff.modulesIds, moduleId]
              : staff.modulesIds.filter((id) => id !== moduleId),
          }
        : staff
    );

    setLocalStaffList(updatedList);

    updateSupportStaffModules(
      staffId,
      updatedList.find((staff) => staff.id === staffId)?.modulesIds || []
    );
  };

  const handleRowCheckAll = (staffId: string, isChecked: boolean) => {
    const updatedList = localStaffList.map((staff) =>
      staff.id === staffId
        ? {
            ...staff,
            modulesIds: isChecked ? modules.map((module) => module._id) : [],
          }
        : staff
    );

    setLocalStaffList(updatedList);

    updateSupportStaffModules(
      staffId,
      updatedList.find((staff) => staff.id === staffId)?.modulesIds || []
    );
  };

  function addUniqueModuleId(modulesIds: string[], moduleId: string): string[] {
    return modulesIds.includes(moduleId)
      ? modulesIds
      : [...modulesIds, moduleId];
  }

  const getRowIndeterminate = (staffId: string) => {
    const staff = localStaffList.find((staff) => staff.id === staffId);
    if (!staff) return false;
    const totalChecked = staff?.modulesIds?.length;
    return totalChecked > 0 && totalChecked < modules.length;
  };

  const data: DataRow[] = localStaffList.map((staff) => ({
    id: staff.id,
    name: (
      <Box display="flex" alignItems="center" sx={{ minWidth: 0 }}>
        <Checkbox
          sx={{
            marginLeft: `-${theme.spacing(1)}`,
            transform: `translate(-${theme.spacing(0.5)})`,
          }}
          indeterminate={getRowIndeterminate(staff.id)}
          checked={staff?.modulesIds?.length === modules.length}
          onChange={(event) =>
            handleRowCheckAll(staff.id, event.target.checked)
          }
        />
        <Box
          component="span"
          sx={{
            wordBreak: "break-word",
            whiteSpace: "normal",
            marginLeft: 1,
            display: "inline-block",
            lineHeight: 1.5,
            textAlign: "left",
          }}
        >
          {staff.name}
        </Box>
      </Box>
    ),
    ...modules.reduce(
      (acc, module) => {
        acc[module._id] = (
          <Checkbox
            checked={staff?.modulesIds?.includes(module._id)}
            onChange={(event) =>
              handleModuleChange(staff.id, module._id, event.target.checked)
            }
          />
        );
        return acc;
      },
      {} as Record<string, JSX.Element>
    ),
    action: (
      <IconButton size="small" onClick={() => onRemoveStaff(staff)}>
        <CloseIcon />
      </IconButton>
    ),
  }));

  return (
    <DataTable
      data={data}
      headCells={headCells}
      rowsPerPage={10}
      total={localStaffList.length}
      order="asc"
      orderBy="name"
      page={0}
      minWidth={500}
      showPagination={false}
      isLoading={isLoading}
      rowSx={() => ({
        "& .MuiTableCell-root": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      })}
    />
  );
};

export default SupportStaffPermissionsMatrix;
