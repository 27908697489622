import { axios, transformAxiosError } from "../utils/axios.create";
import { OrganizationUnit } from "../domain/organizations.interface";

const findByIds = async ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}): Promise<OrganizationUnit[]> => {
  const statusQuery = new URLSearchParams({
    status: status,
    _id: ids.join(","),
  }).toString();

  return axios
    .get(`/organizations?${statusQuery}`)
    .then((res) => {
      return res.data.body as OrganizationUnit[];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const OrganizationService = {
  findByIds,
};
