import { EventTypeEnum } from "@/services/rewards-engine.service";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface Filters {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  search: string;
  status: string[];
  programId: string;
}

interface EarningsFiltersContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  persistFilters: boolean;
  setPersistFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

export const defaultFilters: Filters = {
  sortField: "transactions.date",
  sortOrder: 0,
  programId: "",
  status: [
    EventTypeEnum.JOURNAL,
    EventTypeEnum.STEP_COMPLETION,
    EventTypeEnum.GOAL_COMPLETION,
    EventTypeEnum.SUPERVISION_ACTIVITY,
    EventTypeEnum.IN_HOUSE_SERVICE,
    EventTypeEnum.EXTERNAL_PROVIDER_SERVICE,
    EventTypeEnum.REDEEMED,
  ],
  search: "",
  pageNumber: 0,
  pageSize: 5,
};

const EarningsFiltersContext = createContext<
  EarningsFiltersContextType | undefined
>(undefined);

export const EarningsFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [persistFilters, setPersistFilters] = useState<boolean>(false);

  return (
    <EarningsFiltersContext.Provider
      value={{ filters, setFilters, persistFilters, setPersistFilters }}
    >
      {children}
    </EarningsFiltersContext.Provider>
  );
};

export const useEarningsFilters = () => {
  const context = useContext(EarningsFiltersContext);
  if (!context) {
    throw new Error(
      "useEarningsFilters must be used within a EarningsFiltersContext"
    );
  }
  return context;
};
