import {
  ScreeningColor,
  SearchScreeningColorsPayload,
  SearchScreeningColorsResponse,
} from "@/domain/screening-colors.interface";
import { axios, transformAxiosError } from "@/utils/axios.create";

const findAll = async ({
  status,
}: {
  status?: string;
}): Promise<ScreeningColor[]> => {
  return axios
    .get("/screening-colors", { params: { status: status } })
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch screening colors"
      );
    });
};

const getCurrentDayColor = async ({
  programId,
  date,
}: {
  programId?: string;
  date?: string;
}): Promise<ScreeningColor> => {
  return axios
    .get("/screening-colors/current", {
      params: { date: date?.toString(), programId: programId?.toString() },
    })
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch screening color"
      );
    });
};

const create = async (screeningColor: {
  categoryId: string;
  date: Date;
  programId: string;
}): Promise<ScreeningColor> => {
  return axios
    .post("/screening-colors", screeningColor)
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.error.errorMessage ||
          "Failed to create screening color"
      );
    });
};

const update = async (screeningColor: {
  _id: string;
  categoryId: string | null;
  date: Date;
  programId: string;
}): Promise<ScreeningColor> => {
  return axios
    .put(`/screening-colors/${screeningColor._id}`, screeningColor)
    .then((res) => {
      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.error.errorMessage ||
          "Failed to update screening color"
      );
    });
};

const deleteById = async (screeningColorId: string): Promise<void> => {
  return axios
    .delete(`/screening-colors/${screeningColorId}`)
    .then(() => {})
    .catch((err) => {
      throw new Error(
        err.response?.data?.error.errorMessage ||
          "Failed to delete screening color"
      );
    });
};

const search = async ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  programId,
  startDate,
  endDate,
}: SearchScreeningColorsPayload): Promise<SearchScreeningColorsResponse> => {
  if (!programId) return { list: [], totalCount: 0 };
  const queryParams = new URLSearchParams({
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
    programId,
  });
  if (startDate) {
    queryParams.append("startDate", startDate);
  }
  if (endDate) {
    queryParams.append("endDate", endDate);
  }

  return axios
    .get(`/screening-colors/search?${queryParams.toString()}`)
    .then((res) => {
      const body = res.data.body;
      return {
        list: body.list,
        totalCount: body.totalCount,
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};
export const ScreeningColorService = {
  findAll,
  getCurrentDayColor,
  create,
  update,
  deleteById,
  search,
};
