import React from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  Link,
  useTheme,
  ThemeProvider,
  Theme,
} from "@mui/material";
import theme from "../../utils/theme";
import { useAuth0 } from "@auth0/auth0-react";

interface UnauthorizedProps {
  theme?: Theme;
}

export default function Unauthorized({ theme: appTheme }: UnauthorizedProps) {
  const clientTheme = appTheme ? appTheme : useTheme();
  const { logout } = useAuth0();
  const signOutFunction = async () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  setTimeout(() => {
    signOutFunction();
  }, 2000);

  return (
    <ThemeProvider theme={clientTheme}>
      <Container
        sx={{
          maxWidth: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: 0,
        }}
      >
        <Box
          borderColor={theme.palette.outline.light}
          sx={{
            position: "absolute",
            top: "15px",
            left: "50%",
            transform: "translateX(-50%)",
            width: { xs: 60, sm: 79 },
            height: { xs: 60, sm: 78 },
          }}
        >
          <img
            src="/assets/parca-logo.svg"
            alt="Logo"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>

        <Card
          sx={{
            width: "90%",
            maxWidth: 480,
            padding: { xs: "40px 20px", sm: "60px 40px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            borderRadius: clientTheme.shape.borderRadius,
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ddd",
            textAlign: "center",
            backgroundColor: theme.palette.surface.light,
          }}
        >
          <Box
            component="img"
            src="/assets/401.svg"
            alt="401 Unauthorized"
            sx={{
              width: { xs: "100px", sm: "150px" },
              height: "auto",
            }}
          />

          {/* Updated Title for 401 */}
          <Typography
            variant="h4"
            sx={{
              marginBottom: 1,
              fontFamily: clientTheme.typography.fontFamily,
              fontSize: { xs: "24px", sm: clientTheme.typography.h4.fontSize },
              fontWeight: clientTheme.typography.h4.fontWeight,
              lineHeight: {
                xs: "32px",
                sm: clientTheme.typography.h4.lineHeight,
              },
              letterSpacing: clientTheme.typography.h4.letterSpacing,
            }}
          >
            Authorization required
          </Typography>

          {/* Updated Description for 401 */}
          <Typography
            variant="body1"
            sx={{
              color: clientTheme.palette.textMain.dark2,
              fontFamily: clientTheme.typography.fontFamily,
              fontSize: {
                xs: "14px",
                sm: clientTheme.typography.body1.fontSize,
              },
              fontWeight: clientTheme.typography.body1.fontWeight,
              lineHeight: {
                xs: "20px",
                sm: clientTheme.typography.body1.lineHeight,
              },
              letterSpacing: clientTheme.typography.body1.letterSpacing,
            }}
          >
            You are not authorized to access this resource. If you require
            assistance, please contact support.
          </Typography>
        </Card>

        {/* Footer */}
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: clientTheme.palette.textMain.dark }}
          >
            Powered by{" "}
            <Link href="https://q2i.com" color="inherit" underline="always">
              Q2i
            </Link>
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: clientTheme.palette.textMain.main2 }}
          >
            PARCA © {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
