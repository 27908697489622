import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import theme from "@/utils/theme";

interface ClientNameCardProps {
  isLoading: boolean;
  firstName?: string;
  lastName?: string;
}

const ClientNameCard: React.FC<ClientNameCardProps> = ({
  isLoading,
  firstName,
  lastName,
}) => {
  return isLoading ? (
    <Skeleton variant="text" width={200} height={50} />
  ) : (
    <>
      <Box mt={1}>
        <Typography
          color={theme.palette.textMain.dark2}
          variant="headlineMedium"
        >{`${firstName} ${lastName}`}</Typography>
      </Box>
    </>
  );
};

export default ClientNameCard;
