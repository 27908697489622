import { HeadCell } from "../components/DataTable/dataTable.interface";

export const headCellsConfig = {
  default: [
    {
      id: "fullname",
      isSortable: true,
      disablePadding: true,
      label: "Full name",
      width: "160px",
      align: "left",
    },
    {
      id: "provider",
      isSortable: true,
      disablePadding: false,
      label: "Provider",
      align: "left",
    },
    {
      id: "cmStartDate",
      isSortable: true,
      disablePadding: false,
      label: "CM start date",
      width: "160px",
      align: "left",
    },
    {
      id: "cmEndDate",
      isSortable: true,
      disablePadding: false,
      label: "CM end date",
      width: "160px",
      align: "left",
    },
    {
      id: "status",
      isSortable: true,
      disablePadding: false,
      label: "Status",
      width: "140px",
      align: "center",
    },
    {
      id: "incEarned",
      isSortable: true,
      disablePadding: false,
      label: "Inc. earned",
      width: "140px",
      align: "right",
    },
    {
      id: "incReceived",
      isSortable: true,
      disablePadding: false,
      label: "Inc. received",
      width: "140px",
      align: "right",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Action",
      width: "140px",
      align: "right",
    },
  ] as HeadCell[],
  participant: [
    {
      id: "user.fullName",
      isSortable: true,
      disablePadding: true,
      label: "Full name",
      width: "160px",
      align: "left",
    },
    {
      id: "lastEnrollment.startDate",
      isSortable: true,
      disablePadding: false,
      label: "Enrollment Date",
      align: "left",
    },
    {
      id: "participant.status",
      isSortable: true,
      disablePadding: false,
      label: "Registration Stage",
      align: "left",
    },
    {
      id: "lastEnrollment.isTraningCompleted",
      isSortable: true,
      disablePadding: false,
      label: "Training",
      align: "left",
    },
    {
      id: "lastEnrollment.isFirstValidation",
      isSortable: true,
      disablePadding: false,
      label: "First Validation",
      align: "left",
    },
    {
      id: "lastEnrollment.status",
      isSortable: true,
      disablePadding: false,
      label: "Status",
      align: "left",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Action",
      width: "140px",
      align: "right",
    },
  ] as HeadCell[],
  user: [
    {
      id: "name",
      isSortable: true,
      disablePadding: true,
      label: "Name",
      width: "160px",
      align: "left",
    },
    {
      id: "lastname",
      isSortable: true,
      disablePadding: true,
      label: "Last name",
      width: "160px",
      align: "left",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Action",
      width: "140px",
      align: "right",
    },
  ] as HeadCell[],
  consentFormsListView: [
    {
      id: "program",
      isSortable: true,
      disablePadding: true,
      label: "Program",
      width: "140px",
      align: "left",
    },
    {
      id: "cmStartDate",
      isSortable: true,
      disablePadding: false,
      label: "CM Start date",
      width: "140px",
      align: "left",
    },
    {
      id: "cmEndDate",
      isSortable: true,
      disablePadding: false,
      label: "CM End date",
      width: "140px",
      align: "left",
    },
    {
      id: "provider",
      isSortable: true,
      disablePadding: false,
      label: "Provider",
      width: "140px",
      align: "left",
    },
    {
      id: "status",
      isSortable: true,
      disablePadding: false,
      label: "Status",
      width: "140px",
      align: "left",
    },
    {
      id: "fileName",
      isSortable: true,
      disablePadding: false,
      label: "File name",
      width: "140px",
      align: "left",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Actions",
      width: "70px",
      align: "right",
    },
  ] as HeadCell[],
  beneficiaryListView: [
    {
      id: "visit",
      isSortable: false,
      disablePadding: true,
      label: "Visit",
      width: "120px",
      align: "left",
    },
    {
      id: "provider",
      isSortable: false,
      disablePadding: true,
      label: "Provider",
      width: "160px",
      align: "left",
    },
    {
      id: "visitDate",
      isSortable: false,
      disablePadding: true,
      label: "Visit Date",
      width: "160px",
      align: "left",
    },
    {
      id: "results",
      isSortable: false,
      disablePadding: true,
      label: "Results",
      width: "160px",
      align: "center",
    },
    {
      id: "earned",
      isSortable: false,
      disablePadding: true,
      label: "Earned",
      width: "160px",
      align: "center",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: true,
      label: "Actions",
      width: "120px",
      align: "right",
    },
  ] as HeadCell[],
  auditLog: [
    {
      id: "timestamp",
      isSortable: true,
      disablePadding: true,
      label: "Timestamp",
      width: "150px",
      align: "left",
    },
    {
      id: "user",
      isSortable: true,
      disablePadding: true,
      label: "User",
      width: "120px",
      align: "left",
    },
    {
      id: "eventDescription",
      isSortable: true,
      disablePadding: true,
      label: "Event Description",
      align: "left",
    },
  ] as HeadCell[],
  beneficiaryListIncentives: [
    {
      id: "timestamp",
      isSortable: false,
      disablePadding: true,
      label: "Timestamp",
      width: "200px",
      align: "left",
    },
    {
      id: "transaction",
      isSortable: false,
      disablePadding: true,
      label: "Transaction",
      width: "160px",
      align: "left",
    },
    {
      id: "value",
      isSortable: false,
      disablePadding: true,
      label: "Value",
      width: "160px",
      align: "center",
    },
    {
      id: "provider",
      isSortable: false,
      disablePadding: true,
      label: "Provider",
      width: "200px",
      align: "left",
    },
  ] as HeadCell[],
  enrollQueue: [
    {
      id: "user.fullName",
      isSortable: true,
      disablePadding: true,
      label: "Full name",
      width: "160px",
      align: "left",
    },
    {
      id: "dateJoined",
      isSortable: true,
      disablePadding: false,
      label: "Registration",
      align: "left",
    },
    {
      id: "status",
      isSortable: true,
      disablePadding: false,
      label: "Registration Stage",
      align: "left",
    },
    {
      id: "onboarding.type",
      isSortable: true,
      disablePadding: false,
      label: "Onboarding Type",
      align: "left",
    },
    {
      id: "status",
      isSortable: true,
      disablePadding: false,
      label: "Status",
      align: "left",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Action",
      width: "140px",
      align: "right",
    },
  ] as HeadCell[],
  profileResults: [
    {
      id: "day",
      isSortable: false,
      disablePadding: false,
      label: "Day",
      width: "140px",
      align: "left",
    },
    {
      id: "validationNumber",
      isSortable: true,
      disablePadding: false,
      label: "Validation #",
      align: "left",
    },
    {
      id: "validationType",
      isSortable: true,
      disablePadding: false,
      label: "Validation Type",
      align: "left",
    },
    {
      id: "validationStatus",
      isSortable: true,
      disablePadding: false,
      label: "Validation Status",
      align: "left",
    },
    {
      id: "validationDate",
      isSortable: true,
      disablePadding: false,
      label: "Validation",
      align: "left",
    },
    {
      id: "numberOfAttempts",
      isSortable: true,
      disablePadding: false,
      label: "Validation Attempts",
      align: "center",
    },
    {
      id: "rewardEarned",
      isSortable: true,
      disablePadding: false,
      label: "Reward Earned",
      align: "center",
    },
    {
      id: "action",
      isSortable: false,
      disablePadding: false,
      label: "Actions",
      width: "140px",
      align: "right",
    },
  ] as HeadCell[],
};
