import React from "react";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { useAppTheme } from "@/utils/theme";
import { ArrowForwardIos as ArrowForwardIcon } from "@mui/icons-material";
import { GoalStepStatus, Step } from "@/domain/goal.interface";
import { useTranslation } from "react-i18next";
import ParcaPoints from "./GoalParcaPoints";
import StatusIcon from "./StatusIcon";
import StatusText from "./StatusText";
import { useRewardsEngine } from "@/hooks/useRewardsEngine";
import { EventTypeEnum } from "@/services/rewards-engine.service";

interface GoalStepCardProps {
  onSelectGoalStep: (stepData: Step) => void;
  stepData: Step;
}

const GoalStepCard: React.FC<GoalStepCardProps> = ({
  stepData,
  onSelectGoalStep,
}) => {
  const theme = useAppTheme();
  const { t } = useTranslation(["clients"]);

  const { data: stepRewardsDataList } = useRewardsEngine(true, {
    eventType: EventTypeEnum.STEP_COMPLETION,
    event: stepData.name,
  });

  const stepRewardPoints = stepRewardsDataList?.length
    ? stepRewardsDataList[0].fixedLadderConfiguration?.[0].points
    : 0;

  const isPendingSubmit = stepData.status === GoalStepStatus.PENDING_SUBMIT;

  return (
    <Card
      role={!isPendingSubmit ? "button" : undefined}
      tabIndex={!isPendingSubmit ? 0 : undefined}
      data-testid="goal-step-card"
      onClick={!isPendingSubmit ? () => onSelectGoalStep(stepData) : undefined}
      sx={{
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: 1,
        borderRadius: 2,
        bgcolor: theme.palette.surface.light2,
        mb: 2,
        cursor: !isPendingSubmit ? "pointer" : "default",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box sx={{ margin: 1 }}>
          <StatusIcon status={stepData.status} type="step" />
        </Box>
        <Box>
          <Typography
            variant="titleSmall"
            sx={{
              wordBreak: "break-word",
              textOverflow: "ellipsis",
            }}
          >
            {stepData.name}
          </Typography>
          <Typography
            variant="body2"
            fontSize={12}
            color="text.secondary"
            sx={{
              wordBreak: "break-word",
              textOverflow: "ellipsis",
            }}
          >
            {t("goals.required_validation", {
              validationType: stepData.validationType,
            })}
          </Typography>
          {(stepData.status == GoalStepStatus.APPROVED ||
            stepData.status == GoalStepStatus.REJECTED) && (
            <StatusText
              status={stepData.status}
              type="step"
              creationDate={stepData.creationDate}
              lastSubmission={
                stepData.submissions?.[stepData.submissions.length - 1]
              }
            />
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ParcaPoints points={stepRewardPoints} />
        {!isPendingSubmit ? (
          <IconButton>
            <ArrowForwardIcon />
          </IconButton>
        ) : (
          <Box sx={{ width: "40px", height: "40px" }} />
        )}
      </Box>
    </Card>
  );
};

export default GoalStepCard;
