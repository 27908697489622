import React from "react";
import { Box, Typography, Card, IconButton } from "@mui/material";
import { ArrowForwardIos as ArrowForwardIcon } from "@mui/icons-material";
import { useAppTheme } from "@/utils/theme";
import { Goal } from "@/domain/goal.interface";
import ParcaPoints from "./GoalParcaPoints";
import StatusIcon from "./StatusIcon";
import StatusText from "./StatusText";
import { useRewardsEngine } from "@/hooks/useRewardsEngine";
import { EventTypeEnum } from "@/services/rewards-engine.service";

interface GoalCardProps {
  goalData: Goal;
  onSelectGoal: () => void;
  isSelected: boolean;
}

const GoalCard: React.FC<GoalCardProps> = ({
  goalData,
  onSelectGoal,
  isSelected,
}) => {
  const theme = useAppTheme();
  const { data: goalRewardsDataList } = useRewardsEngine(true, {
    eventType: EventTypeEnum.GOAL_COMPLETION,
    event: goalData.name,
  });

  const goalRewardPoints = goalRewardsDataList?.length
    ? goalRewardsDataList[0].fixedLadderConfiguration?.[0].points
    : 0;

  return (
    <Card
      role="button"
      tabIndex={0}
      data-testid="goal-card"
      onClick={onSelectGoal}
      sx={{
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: 1,
        borderRadius: 2,
        bgcolor: theme.palette.surface.light2,
        mb: 2,
        border: isSelected ? "2px solid blue" : "none",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box sx={{ margin: 1 }}>
          <StatusIcon status={goalData.status} type="goal" />
        </Box>
        <Box>
          <Typography
            variant="titleSmall"
            fontWeight="bold"
            sx={{
              wordBreak: "break-word",
              textOverflow: "ellipsis",
            }}
          >
            {goalData.name}
          </Typography>

          <StatusText
            status={goalData.status}
            type="goal"
            creationDate={goalData?.creationDate}
            completionDate={goalData?.completionDate}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ParcaPoints points={goalRewardPoints} />
        <IconButton sx={{ marginLeft: 1 }}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

export default GoalCard;
