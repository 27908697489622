import React, { FC, useMemo, useState } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { a11yProps } from "@/utils/a11yProps";
import Tab from "@mui/material/Tab";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import inviteValidationSchema, { InviteFormData } from "./validation.schema";
import { useTranslation } from "react-i18next";
import TabPanel from "@/components/TabPanel";
import { useUserUpdateOne } from "@/hooks/useUsers";
import { useRoles } from "@/hooks/useRoles";
import { TabUsers } from "./TabUsers";
import { TabRoles } from "./TabRoles";
import { TabAssignOU } from "./TabAssignOU";
import Button from "@/components/Button";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import {
  groupRoles,
  showGroupRolesByUserRole,
  sortGroupedRoles,
} from "@/utils/rolesUtils";
import { ErrorResponse } from "@/domain/error.interface";
import { UserPayload } from "@/domain/user.interface";
import { useUserStore } from "@/hooks/useUserStore";
import {
  useAuditLogFirstRender,
  useCreateAuditlog,
} from "@/hooks/useAuditLogs";
import { MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { ACTION_CODE } from "../../../domain/auditlog.enum";
import { useGetUserPrograms } from "@/hooks/usePrograms";

type UserEditModalProps = {
  open: boolean;
  onClose: () => void;
  editUserId: string;
  userData: InviteFormData;
};

const UserEditModal: FC<UserEditModalProps> = ({
  open,
  onClose,
  editUserId,
  userData,
}) => {
  const { t } = useTranslation("common");
  const { userInfo, hasSystemAdminRole } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const theme = useAppTheme();

  const [selectedTab, setSelectedTab] = useState(0);

  const createAuditLog = useCreateAuditlog();
  const userUpdateOne = useUserUpdateOne();
  const isPending = userUpdateOne.isPending;

  const { roles } = useRoles();
  const { programs } = useGetUserPrograms({
    userId: userInfo?._id as string,
    hasSystemAdminRole,
  });

  const programsOptions = useMemo(() => {
    if (!programs) return [];

    return programs.map((program) => ({
      key: program._id,
      value: program.name,
    }));
  }, [programs]);

  const [selectedOrgUnitIds, setSelectedOrgUnitIds] = useState<string[]>([]);

  const methods = useForm<InviteFormData>({
    resolver: yupResolver(inviteValidationSchema(t, "US", true)),
    mode: "all",
    defaultValues: userData, // Set default values here
  });

  const { watch } = methods;
  const selectedRoles = watch("roles");

  const systemAdminRole = roles?.filter(
    (rol) => rol.type === "STANDARD_SYSTEM_ROLE"
  );

  const hasAdminRole = useMemo(() => {
    return systemAdminRole?.some((role) => selectedRoles === role._id);
  }, [selectedRoles, systemAdminRole]);

  const groupedRoles = useMemo(() => groupRoles(roles || []), [roles]);

  const rolesArray = useMemo(() => {
    if (!userInfo) return [];

    const rolesArray = sortGroupedRoles(groupedRoles);

    const userRole = userInfo.roles[0] as unknown as string;

    return showGroupRolesByUserRole({
      rolesArray,
      userRole,
    });
  }, [groupedRoles, userInfo]);

  const onCheckTabs = async () => {
    const hasRole = await methods.watch("roles");
    const hasProgram = await methods.watch("programId");
    const hasOrgUnit = await methods.watch("orgUnitIds");

    const isValidTab0 = await methods.trigger([
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "externalId",
    ]);

    if (!isValidTab0) {
      setSelectedTab(0);
      return false;
    }

    if (selectedTab !== 1 && !hasRole) {
      setSelectedTab(1);
      return false;
    }

    if (selectedTab === 1 && !hasRole) {
      setSelectedTab(1);
      return true;
    }

    if (
      !hasAdminRole &&
      (!hasProgram || (hasOrgUnit as string[]).length === 0)
    ) {
      setSelectedTab(2);
      return false;
    }

    return true;
  };

  const onSubmit = async (data: InviteFormData) => {
    createAuditLog.mutate({
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.USERS,
      option: OPTIONS.USER_ADD_EDIT,
      actionCode: ACTION_CODE.WEB_USRS_USR_EDIT,
      action: "When saving a user (editing)",
      detail: `Edited the user ${data.email}`,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
    });

    const payload = {
      ...data,
      status: data.status,
      updatedBy: userInfo?._id as string,
      authenticationId: userInfo?._id as string,
      roles: [data.roles],
      orgUnitIds: data.orgUnitIds ? data.orgUnitIds : [],
    } as UserPayload;

    try {
      await userUpdateOne.mutateAsync(
        {
          user: payload,
          userId: editUserId,
        },
        {
          onSuccess: () => {
            onClose();
            showSnackBar("The registry have been saved.");
          },
          onError: (error: ErrorResponse) => {
            showSnackBar(
              (Array.isArray(error.message)
                ? error.message[0]
                : error.message) ||
                error.error ||
                "An error occurred"
            );
          },
        }
      );
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    const isValidTab0 = await methods.trigger([
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "externalId",
    ]);

    if (newValue === 1) {
      if (!isValidTab0) {
        setSelectedTab(0);
        return;
      }
    } else if (newValue === 2) {
      if (!isValidTab0) {
        setSelectedTab(0);
        return;
      }

      const isValidTab1 = await methods.trigger("roles");
      if (!isValidTab1) {
        setSelectedTab(1);
        return;
      }
    }

    setSelectedTab(newValue);
  };

  useAuditLogFirstRender({
    appType: "WEB_BACK_OFFICE",
    module: MODULE_NAME.USERS,
    option: OPTIONS.USER_ADD_EDIT,
    actionCode: ACTION_CODE.WEB_USRS_USR_VIEW,
    action: "When entering to edit a user",
    detail: `Viewed the user ${userData.email}`,
    transactionDate: new Date(),
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    createdBy: userInfo?._id as string,
  });

  const handleUncheckeableOrganization = () => {
    showSnackBar(
      "The participants of this org unit and program are only assigned to this user. Please move all participants related to this user to another officer before removing access to this org unit.",
      "error"
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      isPending={isPending}
      title="User"
      theme={theme}
      size={{
        width: "100%",
        minHeight: 440,
        maxHeight: 550,
        maxWidth: 860,
      }}
      content={
        <FormProvider {...methods}>
          <Box sx={{ width: "100%", minHeight: 380 }}>
            <Box sx={{ padding: "20px 0 0" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="edit user tabs"
              >
                <Tab label="User" {...a11yProps(0)} />
                <Tab label="User Role" {...a11yProps(1)} />
                <Tab label="Assign OU" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <TabUsers editMode />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <TabRoles rolesArray={rolesArray} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <TabAssignOU
                programsOptions={programsOptions}
                setSelectedOrgUnitIds={setSelectedOrgUnitIds}
                selectedOrgUnitIds={selectedOrgUnitIds}
                isEditMode={true}
                hasAdminRole={hasAdminRole}
                editUserId={editUserId}
                onHandleUncheckeableOrganization={
                  handleUncheckeableOrganization
                }
              />
            </TabPanel>
          </Box>
        </FormProvider>
      }
      actions={
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variantType="cancel"
            onClick={onClose}
            disabled={isPending}
            label="CANCEL"
          />
          <Button
            variantType="save"
            onClick={() => {
              const hasError = onCheckTabs();
              if (!hasError) return;

              methods.handleSubmit(onSubmit)();
            }}
            disabled={isPending}
            isLoading={isPending}
            label="SAVE"
          />
        </Box>
      }
    />
  );
};

export default UserEditModal;
