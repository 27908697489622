import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Logout from "@mui/icons-material/Logout";
import { useMenuStore } from "@/hooks/useMenuStore";
import MenuItemButton from "@/components/MenuItemButton";
import MenuItemIcon from "@/components/MenuItemIcon";
import getIcon from "@/utils/get-icon";
import { Box, Typography, IconButton } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import clientTheme, { ExtendedTypographyOptions } from "@/utils/theme";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { useClientFilters } from "@/providers/ClientsFiltersContext";
import { ParticipantStatus } from "@/domain/participant.enum";
import theme from "@/utils/theme";

interface MainMenuProps {
  isMobile: boolean;
  handleDrawerClose: () => void;
}

const MainMenu: FC<MainMenuProps> = ({ isMobile, handleDrawerClose }) => {
  const navigate = useNavigate();
  const { menuItems } = useMenuStore();
  const { logout } = useAuth0();
  const { userInfo } = useUserStore();
  const { setFilters, setPersistFilters } = useClientFilters();
  const createAuditLog = useCreateAuditlog();

  //TODO: where should we get the app version from?
  const buildVersion = process.env.REACT_APP_VERSION || "1.0.0";

  const signOutFunction = async () => {
    try {
      await createAuditLog.mutateAsync({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.SIGN_OUT,
        option: OPTIONS.SIGN_OUT,
        actionCode: ACTION_CODE.WEB_SIGN_OUT_OK,
        action: "Sign out",
        detail: "User signed out successfully",
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
      });
    } finally {
      document.cookie = "";
      sessionStorage.clear();
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <List className="px-2.5" sx={{ flexGrow: 1 }}>
        {menuItems.map((item) => (
          <div key={item.id}>
            <ListItem disablePadding className="mb-2">
              <MenuItemButton
                active={item.active}
                onClick={() => {
                  if (item.type === "link") {
                    if (item.route.startsWith("http")) {
                      window.open(item.route, "_blank");
                    } else {
                      useMenuStore.getState().setActive(item.id);
                      if (item.title === "Clients") {
                        setFilters({
                          sortField: "fullName",
                          sortOrder: 1,
                          programId: "",
                          status: [
                            ParticipantStatus.ACTIVE,
                            ParticipantStatus.COMPLETED,
                          ],
                          search: "",
                          pageNumber: 0,
                          pageSize: 10,
                        });

                        setPersistFilters(false);
                      }
                      navigate(item.route);
                      if (isMobile) handleDrawerClose();
                    }
                  } else {
                    useMenuStore.getState().toggleOpen(item.id);
                  }
                }}
              >
                <MenuItemIcon>{getIcon(item.icon)}</MenuItemIcon>
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{ variant: "bodyLarge" }}
                  sx={{
                    color: item.active
                      ? "inherit"
                      : theme.palette.outline.light2,
                  }}
                />
                {item.type === "parent" &&
                  (item.open ? <ExpandLess /> : <ExpandMore />)}
              </MenuItemButton>
            </ListItem>
            {item.children && (
              <Collapse
                key={`collapse-${item.id}`}
                in={item.open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.children.map((child, i) => (
                    <ListItem
                      key={`${item.id}-${child.id}-${i}`}
                      disablePadding
                    >
                      <MenuItemButton
                        active={child.active}
                        onClick={() => {
                          useMenuStore.getState().setActive(child.id);
                          navigate(child.route);
                          if (isMobile) handleDrawerClose();
                        }}
                      >
                        <MenuItemIcon>{getIcon(child.icon)}</MenuItemIcon>
                        <ListItemText
                          primary={child.title}
                          primaryTypographyProps={{
                            variant: "bodyLarge",
                          }}
                        />
                      </MenuItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Box sx={{ p: 2, textAlign: "center" }}>
        <IconButton
          onClick={signOutFunction}
          sx={{
            fontSize: (clientTheme.typography as ExtendedTypographyOptions)
              .titleSmall?.fontSize,
            color: clientTheme.palette.textMain.light,
          }}
        >
          <Logout sx={{ mr: 1 }} />
          Sign out
        </IconButton>
        <Typography
          variant="body2"
          sx={{
            fontSize: (clientTheme.typography as ExtendedTypographyOptions)
              .labelMedium?.fontSize,
            color: clientTheme.palette.textMain.light,
          }}
        >
          PARCA © {new Date().getFullYear()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: (clientTheme.typography as ExtendedTypographyOptions)
              .labelSmall?.fontSize,
            color: clientTheme.palette.textMain.light,
          }}
        >
          Version: {buildVersion}
        </Typography>
      </Box>
    </Box>
  );
};

export default MainMenu;
