import React, { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import { InviteFormData } from "./validation.schema";
import Select from "@/components/Select/index";
import { StatusType } from "@/domain/user.enum";
import { useCountrySetting } from "@/hooks/useSetting";

type TabUsersProps = {
  editMode?: boolean;
};

export const TabUsers: FC<TabUsersProps> = ({ editMode = false }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<InviteFormData>();

  const userStatus = watch("status");
  const country = useCountrySetting();

  const showMoreStatusOptions = useMemo(() => {
    const status = userStatus === "ACTIVE" || userStatus === "INACTIVE";
    if (status) {
      return [];
    }

    return [{ key: "PENDING", value: "Pending activation" }];
  }, [editMode]);

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="firstName"
              label="First name"
              variant="outlined"
              required
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : null}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="lastName"
              label="Last name"
              variant="outlined"
              required
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : null}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="email"
              label="Email"
              required
              variant="outlined"
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : null}
              fullWidth
              disabled={editMode}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              defaultCode={country}
              value={field.value}
              label="Phone number"
              variant="outlined"
              className="flex flex-1 w-full"
              onChange={(value) => field.onChange(value)}
              error={!!errors.phoneNumber}
              helperText={
                errors.phoneNumber ? errors.phoneNumber.message : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="externalId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              id="externalId"
              label="External ID"
              variant="outlined"
              className="flex flex-1"
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={!!errors.externalId}
              helperText={errors.externalId ? errors.externalId.message : null}
            />
          )}
        />
      </Grid>
      {editMode ? (
        <Grid item xs={12} sm={6}>
          <Controller
            name="status"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                handleChange={(value) => field.onChange(value)}
                data={[
                  { key: "ACTIVE", value: "Active" },
                  { key: "INACTIVE", value: "Inactive" },
                  ...showMoreStatusOptions,
                ]}
                value={field.value as string}
                label="Status"
                id="client-status"
                error={!!errors.status}
                errorMessage={errors.status?.message || ""}
                isLoading={editMode && userStatus === StatusType.Pending}
              />
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
