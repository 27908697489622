import React from "react";
import { Box, Card, Typography } from "@mui/material";
import ParcaPoints from "./EarningParcaPoints";
import { useAppTheme } from "@/utils/theme";

interface EarningHistoryCardProps {
  title: string;
  description: string;
  points: number;
  date: string;
}

const EarningHistoryCard: React.FC<EarningHistoryCardProps> = ({
  title,
  description,
  points,
  date,
}) => {
  const theme = useAppTheme();

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        bgcolor: theme.palette.surface.light2,
        borderRadius: 2,
      }}
    >
      <Box display="flex" alignItems="center">
        <ParcaPoints points={points} />
        <Box className="ml-4">
          <Typography variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#48556A" }}
            className="text-[11px]"
          >
            {description}
          </Typography>
          <Typography
            style={{ color: "#48556A" }}
            fontWeight="medium"
            className="text-base"
          >
            {date}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default EarningHistoryCard;
