import React, { FC, useMemo, useState } from "react";
import {
  RedeemRequest,
  RedeemRequestStatus,
} from "@/domain/enrollment.interface";
import { Card, CardContent, Grid, Typography, Chip, Box } from "@mui/material";
import { useAppTheme } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Button from "@/components/Button";
import GenericAlertModal from "@/components/AlertModal";
import RejectModal from "@/ui/content/clients/rewards/RejectModal";
import { useApproveRedeemRequest } from "@/hooks/useEnrollments";
import { useUserStore } from "@/hooks/useUserStore";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { ParticipantResponse } from "@/domain/participant.interface";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";

const RedemptionCard: FC<{
  item: RedeemRequest;
  participant: ParticipantResponse;
  enrollmentId: string;
  refetchRequests?: () => void;
}> = ({ item, participant, enrollmentId, refetchRequests }) => {
  const theme = useAppTheme();
  const { t } = useTranslation("clients");
  const { userInfo, userHasAccessToCode } = useUserStore();
  const { showSnackBar } = useFeedBack();
  const createAuditLog = useCreateAuditlog();

  const { mutateAsync: approveRedeemRequest, isPending: isPendingApproval } =
    useApproveRedeemRequest();
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [permissionsModalOpen, setpermissionsModalOpen] = useState(false);

  const statusStyles = useMemo(() => {
    switch (item.status) {
      case RedeemRequestStatus.PENDING_APPROVAL:
        return {
          borderColor: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
          border: `1px solid ${theme.palette.warning.dark}`,
        };
      case RedeemRequestStatus.APPROVED:
        return {
          borderColor: theme.palette.brand.dark,
          backgroundColor: theme.palette.brand.light,
          border: `1px solid ${theme.palette.brand.dark}`,
        };
      default:
        return {
          borderColor: theme.palette.error.dark,
          backgroundColor: theme.palette.error.light,
          border: `1px solid ${theme.palette.error.dark}`,
        };
    }
  }, [item.status]);

  const statusText = useMemo(() => {
    switch (item.status) {
      case RedeemRequestStatus.PENDING_APPROVAL:
        return t("client_profile.pending_approval");
      case RedeemRequestStatus.APPROVED:
        return t("client_profile.approved");
      default:
        return t("client_profile.rejected");
    }
  }, [item.status]);

  const handleApprove = () => {
    if (userHasAccessToCode("PARTICIPANT_REWARD_APPROVE")) {
      setApproveModalOpen(true);
    } else {
      setpermissionsModalOpen(true);
    }
  };

  const handleApproveConfirm = async () => {
    try {
      await approveRedeemRequest({
        enrollmentId: enrollmentId,
        redeemRequestId: item._id,
        approvedBy: userInfo?._id as string,
      });
      showSnackBar("The request has been approved", "success");
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANT_REWARDS,
        option: OPTIONS.PARTICIPANT_REWARD_REDEMPTION,
        actionCode: ACTION_CODE.WEB_PART_REWARD_REDEMPTION_APPROVE,
        action: "When approved reward redemption request",
        detail: `Approved the reward redemption request for ${item?.redeemableItem.description} to ${participant?.participant.firstName} ${participant?.participant.lastName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
        targetId: participant?.participant._id as string,
        programId: participant?.lastEnrollment?.programId,
        orgUnitId: participant?.lastEnrollment.orgUnitId,
      });
      setApproveModalOpen(false);
    } catch (error) {
      showSnackBar(`Error approving request: ${error}`);
    } finally {
      if (refetchRequests) refetchRequests();
    }
  };

  const handleReject = () => {
    if (userHasAccessToCode("PARTICIPANT_REWARD_REJECT")) {
      setRejectModalOpen(true);
    } else {
      setpermissionsModalOpen(true);
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: theme.palette.surface.light2 }}>
        <CardContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="titleSmall" fontWeight={700}>
                {t("alerts.redeem_requested_benefit_title")}
                {item.redeemableItem?.description} -{" "}
                {item.redeemableItem?.preUnitText}
                {item.redeemableItem?.unit}
                {item.redeemableItem?.postUnitText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
              <Typography
                variant="body2"
                fontWeight={theme.typography.fontWeightBold}
                color="textSecondary"
              >
                Points required: {item.parcaPoints}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={theme.typography.fontWeightBold}
                color="textSecondary"
              >
                Quantity: {item.quantity}
              </Typography>
              <Box display={"flex"} gap={1} alignItems={"baseline"}>
                <Typography
                  variant="body2"
                  fontWeight={theme.typography.fontWeightBold}
                  color="textSecondary"
                >
                  Status:
                </Typography>
                <Chip label={statusText} sx={{ ...statusStyles }} />
              </Box>
              <Typography variant="caption" color="textSecondary">
                {`${dayjs(item.processDate).format("DD MMM YYYY, hh:mm A")}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="row"
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {item.status === RedeemRequestStatus.PENDING_APPROVAL && (
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  <Button
                    label={t("alerts.reject")}
                    variantType="cancel"
                    onClick={handleReject}
                  />
                  <Button
                    label={t("alerts.approve")}
                    variantType="save"
                    onClick={handleApprove}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {approveModalOpen && (
        <GenericAlertModal
          isLoading={isPendingApproval}
          title="Approve Redeem Request"
          description="Are you sure you want to approve this redeem request?"
          submitText="Confirm"
          cancelText="Cancel"
          onClick={handleApproveConfirm}
          onCancel={() => setApproveModalOpen(false)}
          theme={theme}
        />
      )}

      {permissionsModalOpen && (
        <GenericAlertModal
          isLoading={isPendingApproval}
          title={t("alerts.insufficient_permissions")}
          description={t("client_profile.insufficient_permissions_execute")}
          submitText="Close"
          onClick={() => setpermissionsModalOpen(false)}
          theme={theme}
        />
      )}

      {rejectModalOpen && (
        <RejectModal
          open={rejectModalOpen}
          onClose={() => setRejectModalOpen(false)}
          participant={participant}
          redeemRequest={item}
          refetch={refetchRequests || (() => {})}
        />
      )}
    </>
  );
};

export default RedemptionCard;
