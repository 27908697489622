import { create } from "zustand";
import { persist } from "zustand/middleware";
import { MenuItem } from "../domain/menu.interface";
import { UsersService } from "../services/users.service";
import { moduleService } from "../services/modules.service";
import { IAction } from "../domain/actions.interface";
import { UserType } from "../domain/user.interface";
import { IRoleAction } from "../domain/rol.interface";
import { Module, PROVIDER_MODULE_STATUS } from "@/domain/module.interface";
import { routesConfig } from "src/routes/routesConfig";

type UserStore = {
  isLoading: boolean;
  isUserDataFetched: boolean;
  userInfo: UserType | null;
  roles: IRoleAction[];
  hasSystemAdminRole: boolean;
  menus: MenuItem[];
  setUser: (data: UserType) => void;
  getUserByEmail: (email: string, overrideFetch?: boolean) => Promise<UserType>;
  fetchRolesAndActions: (
    userId: string,
    fetchFromDb?: boolean
  ) => Promise<void>;
  clearUserData: () => void;
  userHasAccess: (path: string) => Promise<boolean>;
  userHasAccessToCode: (roleCode: string) => boolean;
  modules: Module[] | null;
  fetchModules: () => Promise<void>;
};

const mapActionsToMenuItems = (actions: IAction[]): MenuItem[] => {
  const map: Record<string, MenuItem> = {};
  const rootMenus: MenuItem[] = [];

  actions.forEach((action) => {
    map[action._id] = {
      id: action._id,
      icon: action.icon,
      title: action.name,
      route: action.link,
      type: "link",
      open: false,
      active: false,
      children: [],
    };
  });

  actions.forEach((action) => {
    if (action.subActions && action.subActions.length > 0) {
      action.subActions.forEach((subActionId) => {
        const subAction = map[subActionId as unknown as string];
        if (subAction) {
          map[action._id].type = "parent";
          map[action._id].children?.push(subAction);
        }
      });
    }
    if (
      !actions.some((a) =>
        a.subActions?.includes(action._id as unknown as IAction)
      )
    ) {
      rootMenus.push(map[action._id]);
    }
  });

  return rootMenus;
};

export const useUserStore = create(
  persist<UserStore>(
    (set, get) => ({
      isLoading: false,
      isUserDataFetched: false,
      userInfo: null,
      hasSystemAdminRole: false,
      roles: [],
      menus: [],

      setUser: (data) => {
        set({ userInfo: data });
      },

      getUserByEmail: async (
        email: string,
        overrideFetch?: boolean
      ): Promise<UserType> => {
        if (!overrideFetch && get().isUserDataFetched) {
          const { userInfo } = get();
          if (userInfo) {
            return userInfo as UserType;
          }
        }
        set({ isLoading: true });
        try {
          const user = await UsersService.findByEmail(email);
          if (!user) throw new Error("User not found");

          set({
            userInfo: user,
            isUserDataFetched: true,
            isLoading: false,
          });

          return user;
        } catch (error) {
          set({ isLoading: false });
          throw error;
        }
      },

      fetchRolesAndActions: async (userId: string, fetchFromDb = false) => {
        set({ isLoading: true });
        try {
          let roles = get().roles;

          if (fetchFromDb || roles.length === 0) {
            roles = await UsersService.findRolesById(userId);
            const hasSystemAdminRole = roles.some(
              (role) => role.type === "STANDARD_SYSTEM_ROLE"
            );

            set({
              roles,
              hasSystemAdminRole,
            });
          }

          const allActions = roles
            .flatMap((role) => role.actions)
            .filter((a) => a.type === "MENU_OPTION")
            .sort((a, b) => a.order - b.order);
          const menus = mapActionsToMenuItems(allActions);
          set({
            menus,
            isLoading: false,
          });
        } catch (error) {
          set({ isLoading: false });
          throw error;
        }
      },

      clearUserData: () => {
        set({
          userInfo: null,
          isUserDataFetched: false,
          menus: [],
          roles: [],
        });
      },

      userHasAccess: async (path: string): Promise<boolean> => {
        if (["/403", "/404", "/"].includes(path?.toLowerCase())) return true;

        const user = get().userInfo;
        if (!user) return false;

        const roles = get().roles;
        const getRequiredActions = (path: string): string[] => {
          const matchRoute = (routePath: string, pathname: string): boolean => {
            const regexPattern = new RegExp(
              `^${routePath.replace(/:\w+/g, "\\w+")}$`
            );
            return regexPattern.test(pathname);
          };

          for (const route of routesConfig) {
            if (matchRoute(route.path, path)) {
              return route.requiredActions || [];
            }
          }

          return [];
        };

        const requiredActions = getRequiredActions(path);
        return requiredActions.every((requiredAction) =>
          roles.some((role) =>
            role.actions.some(
              (a: IAction) =>
                a.code === requiredAction ||
                a.subActions?.some(
                  (subAction: IAction) => subAction.code === requiredAction
                )
            )
          )
        );
      },

      userHasAccessToCode: (roleCode: string) => {
        const roles = get().roles;
        return roles.some((role) =>
          role.actions.some((a: IAction) => {
            const userHasAccess = a.code === roleCode;
            const userHasChildAccess = a.subActions?.some(
              (subAction) => subAction.code === roleCode
            );
            return userHasAccess || userHasChildAccess;
          })
        );
      },

      modules: null,

      fetchModules: async () => {
        if (!get().modules) {
          try {
            const modules = await moduleService.findAll();
            const activeModules = modules.filter(
              (m) => m.status === PROVIDER_MODULE_STATUS.ACTIVE
            );
            set({ modules: activeModules });
          } catch (error) {
            throw error;
          }
        }
      },
    }),
    {
      name: "UserStore",
    }
  )
);
