import theme from "@/utils/theme";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import React from "react";

const EarningHeader: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Box
        sx={{
          gap: "10x",
          flexDirection: "column",
        }}
        display="flex"
      >
        <Typography variant="headlineSmall" component="h1">
          Earning history
        </Typography>
        <Typography
          variant="bodyMedium"
          sx={{ color: theme.palette.menu.dark, fontWeight: "600" }}
        >
          PARCA POINTS
        </Typography>
      </Box>
      <Box display="flex" gap="0.5rem">
        <Tooltip title="Redemptions">
          <Avatar
            sx={{
              backgroundColor: "black",
              width: "48px",
              height: "48px",
            }}
          >
            <img
              style={{ maxHeight: "96px", maxWidth: "100%" }}
              src="/assets/icons/earnings-icon.svg"
              alt="Redemptions"
            />
          </Avatar>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default EarningHeader;
