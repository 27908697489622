import { FC } from "react";
import Box from "@mui/material/Box";

type PhotoStepProps = {
  selectedPhoto: string;
};

const PhotoStep: FC<PhotoStepProps> = ({ selectedPhoto }) => {
  return (
    <Box
      sx={{
        borderRadius: ".5rem",
        overflow: "hidden",
      }}
    >
      <img
        src={selectedPhoto as string}
        alt="proof"
        width={500}
        height={500}
        className="object-cover w-full h-[420px]"
      />
    </Box>
  );
};

export default PhotoStep;
