import { usePageStore } from "@/hooks/usePageStore";
import DashboardCards from "@/ui/content/dashboard/DashboardCards";
import { Box } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

const DashboardPage: FC = () => {
  const { setPageTitle } = usePageStore();
  const { t } = useTranslation("common");
  useEffect(() => {
    setPageTitle(t("home"));
  }, []);
  return (
    <Box>
      <DashboardCards />
    </Box>
  );
};

export default DashboardPage;
