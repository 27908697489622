import { ParticipantResponse } from "@/domain/participant.interface";
import { axios, transformAxiosError } from "@/utils/axios.create";

const findById = async (id: string): Promise<ParticipantResponse> => {
  return axios
    .get(`/participants/${id}`)
    .then((res) => {
      return (res.data.body as ParticipantResponse) || [];
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const ParticipantsService = {
  findById,
};
