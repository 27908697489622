export enum RoleType {
  StandardSystemRole = "Standard System Role",
  CustomRole = "Custom Role",
  StandardAccountRole = "Standard Account Role",
}

export enum StatusType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
}
