import {
  createTheme,
  PaletteOptions,
  ThemeOptions,
  useTheme,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

type CustomPaletteOptions = {
  main: string;
  main2?: string;
  light?: string;
  dark?: string;
  light2?: string;
  dark2?: string;
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    textMain: CustomPaletteOptions;
    surface: CustomPaletteOptions;
    accent: CustomPaletteOptions;
    icon: CustomPaletteOptions;
    outline: CustomPaletteOptions;
    menu: CustomPaletteOptions;
    brand: CustomPaletteOptions;
  }
  interface PaletteOptions {
    textMain: CustomPaletteOptions;
    surface: CustomPaletteOptions;
    accent: CustomPaletteOptions;
    icon: CustomPaletteOptions;
    outline: CustomPaletteOptions;
    menu: CustomPaletteOptions;
    brand: CustomPaletteOptions;
  }
}

export interface ExtendedPaletteOptions extends PaletteOptions {
  textMain: CustomPaletteOptions;
  surface: CustomPaletteOptions;
  accent: CustomPaletteOptions;
  icon: CustomPaletteOptions;
  outline: CustomPaletteOptions;
  menu: CustomPaletteOptions;
  brand: CustomPaletteOptions;
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display: true;
    headlineLarge: true;
    headlineMedium: true;
    headlineSmall: true;
    titleMedium: true;
    titleSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
  }
}

export interface ExtendedTypographyOptions extends TypographyOptions {
  display?: React.CSSProperties;
  headlineLarge?: React.CSSProperties;
  headlineMedium?: React.CSSProperties;
  headlineSmall?: React.CSSProperties;
  titleMedium?: React.CSSProperties;
  titleSmall?: React.CSSProperties;
  bodyLarge?: React.CSSProperties;
  bodyMedium?: React.CSSProperties;
  bodySmall?: React.CSSProperties;
  labelLarge?: React.CSSProperties;
  labelMedium?: React.CSSProperties;
  labelSmall?: React.CSSProperties;
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          "@media (max-width: 600px)": {
            maxHeight: "calc(100% - 32px)!important",
            marginTop: "20px!important",
            marginBottom: "20px!important",
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#355DE8",
      light: "#9480E8",
      dark: "#355DE8",
    },
    textMain: {
      main: "#6253A2",
      light: "#FFFFFF",
      light2: "#99979A",
      dark: "#261F1F",
      dark2: "#232B36",
      main2: "#7D7979",
    },
    secondary: {
      main: "#4CB7A4",
      light: "#E7EFB8",
      dark: "#005757",
    },
    error: {
      main: "#F44545",
      light: "#FCD6D0",
      dark: "#AB0707",
    },
    warning: {
      main: "#FFC8A9",
      light: "#FFE7C2",
      dark: "#EAB766",
    },
    surface: {
      main: "#B5E0FF",
      light: "#FFFFFF",
      light2: "#E7F3FF",
      dark: "#DED9F8",
    },
    accent: {
      main: "#52197B",
    },
    icon: {
      main: "#ADA6B3",
      light: "#D9D1DD",
      dark: "#48556A",
      dark2: "#065A97",
    },
    outline: {
      main: "#D9D1DD",
      main2: "#C4C8DF",
      light: "#CDD9E9",
      light2: "#D2D2D2",
      dark: "#ADA6B3",
      dark2: "#4C4C56",
    },
    menu: {
      main: "#6253A2",
      light: "#557BFF",
      light2: "#E7F3FF",
      dark: "#1A43D2",
    },
    brand: {
      main: "#ACDD49",
      light: "#E2F4A6",
      dark: "#CFFF80",
    },
  } as ExtendedPaletteOptions,
  typography: {
    display: {
      fontWeight: "900",
      fontSize: "4rem",
      lineHeight: "5.5rem",
    },
    headlineLarge: {
      fontWeight: "bold",
      fontSize: "3rem",
      lineHeight: "4rem",
    },
    headlineMedium: {
      fontWeight: "bold",
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    headlineSmall: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    titleMedium: {
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    titleSmall: {
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "1.25rem",
      letterSpacing: "-0.5px",
    },
    bodyLarge: {
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    bodyMedium: {
      fontWeight: "normal",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    bodySmall: {
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
    labelLarge: {
      fontWeight: "300",
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    labelMedium: {
      fontWeight: "300",
      fontSize: "0.75rem",
      lineHeight: "1.5rem",
    },
    labelSmall: {
      fontWeight: "300",
      fontSize: "11px",
      lineHeight: "1rem",
    },
  } as ExtendedTypographyOptions,
} as ThemeOptions);

export default theme;

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();
