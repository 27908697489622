import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useParticipantById } from "@/hooks/useParticipants";
import { Navigate, useParams } from "react-router-dom";
import { useAppTheme } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import { ParticipantResponse } from "@/domain/participant.interface";
import { usePageStore } from "@/hooks/usePageStore";
import { useUpdateJournal, useSearchJournals } from "@/hooks/useEnrollments";
import { Journal } from "@/domain/enrollment.interface";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useUserStore } from "@/hooks/useUserStore";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import SkeletonLoader from "@/components/SkeletonLoader";
import { Circle } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JournalHeader from "@/ui/content/clients/journals/JournalHeader";

interface Filters {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
}

const PAGE_SIZE = 5;
const defaultFilters: Filters = {
  sortField: "createdAt",
  sortOrder: -1,
  pageNumber: 0,
  pageSize: PAGE_SIZE,
};

const JournalsPage: React.FC = () => {
  const theme = useAppTheme();
  const { participantId } = useParams();
  const { setPageTitle } = usePageStore();
  const { userInfo } = useUserStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation(["clients", "common"]);

  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [selectedItem, setSelectedItem] = useState<Journal | undefined>(
    undefined
  );
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );
  const [pendingIndex, setPendingIndex] = useState<number | undefined>(
    undefined
  );
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const createAuditLog = useCreateAuditlog();
  const updateJournal = useUpdateJournal();
  const auditLogViewJournalsCalledRef = React.useRef(false);

  const { data: participantResponse, isLoadingParticipant } =
    useParticipantById(participantId || "");
  const { journals, isFetchingJournals, isLoadingJournals, refetchJournals } =
    useSearchJournals({
      sortField: filters.sortField,
      sortOrder: filters.sortOrder,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      enrollmentId: participantResponse?.lastEnrollment?._id ?? "",
    });

  useEffect(() => {
    const primaryOfficerId =
      participantResponse?.lastEnrollment?.primaryOfficerId;
    const additionalOfficerIds =
      participantResponse?.lastEnrollment?.additionalOfficerId || [];
    const currentUserId = userInfo?._id;

    if (primaryOfficerId && currentUserId) {
      if (
        primaryOfficerId !== currentUserId &&
        !additionalOfficerIds.includes(currentUserId)
      ) {
        setShouldRedirect(true);
      }
    }
  }, [
    participantResponse?.lastEnrollment?.primaryOfficerId,
    participantResponse?.lastEnrollment?.additionalOfficerId,
    userInfo?._id,
  ]);

  useEffect(() => {
    refetchJournals();
  }, [
    filters.sortField,
    filters.sortOrder,
    filters.pageNumber,
    filters.pageSize,
    participantResponse?.lastEnrollment?._id,
  ]);

  useEffect(() => {
    if (journals && !isFetchingJournals && pendingIndex !== undefined) {
      const relativeIndex = pendingIndex % filters.pageSize;
      if (relativeIndex >= 0 && relativeIndex < journals.list.length) {
        setSelectedItem(journals.list[relativeIndex]);
      }
      setPendingIndex(undefined);
    }
  }, [isFetchingJournals, journals, pendingIndex]);

  useEffect(() => {
    if (selectedIndex !== undefined) {
      updateSelectedItem(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    setPageTitle(t("client_profile.page_journals_title"));
  }, []);

  useEffect(() => {
    if (
      !isLoadingParticipant &&
      userInfo &&
      !auditLogViewJournalsCalledRef.current
    ) {
      const participantFullName = `${participantResponse?.participant?.firstName} ${participantResponse?.participant?.lastName}`;
      createAuditLog.mutate({
        appType: "WEB_BACK_OFFICE",
        module: MODULE_NAME.PARTICIPANT_JOURNALS,
        option: OPTIONS.PARTICIPANT_JOURNALS_LIST,
        actionCode: ACTION_CODE.WEB_PART_JOURNAL_LIST_VIEW,
        action: "When entering to view the list",
        detail: `Viewed the journals list of ${participantFullName}`,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
        targetId: participantResponse?.participant?._id as string,
        programId: participantResponse?.lastEnrollment.programId,
        orgUnitId: participantResponse?.lastEnrollment.orgUnitId,
      });
      auditLogViewJournalsCalledRef.current = true;
    }
  }, [isLoadingParticipant, userInfo]);

  const onJournalSelected = (item: Journal) => {
    const relativeIndex = (journals?.list || []).findIndex(
      (i) => i._id === item?._id
    );
    const absoluteIndex = filters.pageNumber * filters.pageSize + relativeIndex;
    setSelectedIndex(absoluteIndex);
  };

  useEffect(() => {
    if (!selectedItem) return;
    if (!selectedItem.isViewed) {
      updateJournal.mutate(
        {
          enrollmentId: participantResponse?.lastEnrollment?._id || "",
          journalId: selectedItem?._id?.toString() || "",
          body: { isViewed: true },
        },
        {
          onSuccess: () => {
            refetchJournals();
          },
        }
      );
    }
    const participantFullName = `${participantResponse?.participant?.firstName} ${participantResponse?.participant?.lastName}`;
    createAuditLog.mutate({
      appType: "WEB_BACK_OFFICE",
      module: MODULE_NAME.PARTICIPANT_JOURNALS,
      option: OPTIONS.PARTICIPANT_JOURNAL,
      actionCode: ACTION_CODE.WEB_PART_JOURNAL_VIEW,
      action: "When viewing a journal",
      detail: `Viewed the journal of ${participantFullName} submitted on ${dayjs(selectedItem?.dateTime).format("DD MMM YYYY")}`,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
      targetId: participantResponse?.participant?._id as string,
      programId: participantResponse?.lastEnrollment.programId,
      orgUnitId: participantResponse?.lastEnrollment.orgUnitId,
    });
  }, [selectedItem]);

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const onPreviousHandler = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex !== undefined && prevIndex > 0 ? prevIndex - 1 : 0
    );
  };

  const onNextHandler = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex !== undefined ? prevIndex + 1 : 0
    );
  };

  const handleGoBack = () => {
    // setSlideDirection("right");
    setSelectedItem(undefined);
    setSelectedIndex(undefined);
  };

  useEffect(() => {
    resetFilters();
  }, [participantId]);

  const updateSelectedItem = async (index: number) => {
    if (!journals?.list || !journals.totalCount) return;
    const totalJournals = journals.totalCount;

    if (index >= 0 && index < totalJournals) {
      const isWithinCurrentList =
        index >= filters.pageNumber * filters.pageSize &&
        index < (filters.pageNumber + 1) * filters.pageSize;

      if (isWithinCurrentList) {
        const relativeIndex = index % filters.pageSize;
        setSelectedItem(journals.list[relativeIndex]);
      } else {
        const changePage =
          index < filters.pageNumber * filters.pageSize ? -1 : 1;
        setPendingIndex(index);
        setFilters((filters) => ({
          ...filters,
          pageNumber: filters.pageNumber + changePage,
        }));
      }
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/403" />;
  }

  return (
    <Box>
      <JournalHeader
        isMobile={isMobile}
        selectedItem={!!selectedItem}
        onGoBack={handleGoBack}
        headerText={"Journals"}
        participantResponse={participantResponse as ParticipantResponse}
        isLoadingParticipant={isLoadingParticipant}
      />
      <Grid container mt={0} spacing={2}>
        {(!isMobile || !selectedItem) && (
          <Grid
            item
            xs={12}
            md={5}
            container
            sx={{ display: "flex", gap: "20px" }}
          >
            <Card sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
              <Box
                sx={{
                  padding: "0 20px 0 20px",
                }}
                display="flex"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    gap: "10x",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    variant="titleSmall"
                    component="h3"
                    sx={{
                      color: theme.palette.icon.dark,
                      fontWeight: "700",
                    }}
                  >
                    Journal entries
                  </Typography>
                  <Typography
                    variant="headlineMedium"
                    sx={{
                      color: theme.palette.textMain.dark2,
                      fontWeight: "700",
                    }}
                  >
                    {journals?.totalCount || 0}
                  </Typography>
                </Box>

                <Box>
                  <img
                    style={{ maxHeight: "96px", maxWidth: "100%" }}
                    src="/assets/actions_journal.svg"
                    alt="journal entries"
                  />
                </Box>
              </Box>
            </Card>
            <Card sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                }}
              >
                <SkeletonLoader
                  skeletonType="rectangular"
                  skeletonHeight={90}
                  skeletonCount={3}
                  isLoading={isLoadingJournals || isFetchingJournals}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {(journals?.totalCount || 0) === 0 && (
                      <p>There are not journals</p>
                    )}

                    {journals?.list?.map((item, index) => (
                      <JournalCard
                        key={index}
                        item={item}
                        onClick={onJournalSelected}
                        isSelected={selectedItem?._id === item._id}
                      />
                    ))}
                  </Box>
                </SkeletonLoader>
                {(journals?.totalCount || 0) > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component="div"
                      count={journals?.totalCount || 0}
                      rowsPerPage={filters.pageSize}
                      page={filters.pageNumber}
                      onPageChange={(e, newPage) => {
                        setSelectedItem(undefined);
                        setSelectedIndex(undefined);
                        setFilters((filters) => ({
                          ...filters,
                          pageNumber: newPage,
                        }));
                      }}
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} md={7}>
          {selectedItem && (
            <Card sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography variant="headlineSmall">
                    Journal{" "}
                    {dayjs(selectedItem?.dateTime).format(
                      "DD MMM YYYY, hh:mm A"
                    )}
                  </Typography>
                  <TextField
                    label="Detail"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                    sx={{ mt: 2 }}
                    value={selectedItem?.detail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: theme.palette.surface.light2,
                      height: 80,
                      borderRadius: 2,
                    }}
                  >
                    <img
                      src={selectedItem?.emoticon?.base64image}
                      alt="Image"
                    />
                  </Box>
                </Box>

                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<ArrowBackIosIcon />}
                      sx={{
                        borderRadius: 2,
                        fontWeight: 500,
                        borderColor: theme.palette.menu.dark,
                        color: theme.palette.menu.dark,
                        py: 1,
                      }}
                      disabled={
                        selectedIndex === 0 ||
                        isFetchingJournals ||
                        updateJournal.isPending
                      }
                      onClick={onPreviousHandler}
                    >
                      <Typography
                        variant="bodyLarge"
                        sx={{ fontWeight: "500" }}
                      >
                        Previous
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      endIcon={<ArrowForwardIosIcon />}
                      sx={{
                        borderRadius: 2,
                        fontWeight: 500,
                        borderColor: theme.palette.menu.dark,
                        color: theme.palette.menu.dark,
                        py: 1,
                      }}
                      disabled={
                        (selectedIndex || 0) + 1 === journals?.totalCount ||
                        isFetchingJournals ||
                        updateJournal.isPending
                      }
                      onClick={onNextHandler}
                    >
                      <Typography
                        variant="bodyLarge"
                        sx={{ fontWeight: "500" }}
                      >
                        Next
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface JournalCardProps {
  item: Journal;
  onClick: (item: Journal) => void;
  isSelected?: boolean;
}

const DETAIL_MAX_LENGTH = 30;
const JournalCard = ({
  item,
  onClick,
  isSelected = false,
}: JournalCardProps) => {
  const theme = useAppTheme();

  const getDetail = (detail: string) => {
    const sanitized = detail?.trim() ?? "";
    return sanitized.length > DETAIL_MAX_LENGTH
      ? sanitized.substring(0, DETAIL_MAX_LENGTH) + "..."
      : sanitized;
  };
  return (
    <Card
      elevation={0}
      sx={{
        alignItems: "center",
        backgroundColor: theme.palette.surface.light2,
        display: "flex",
        gap: "20px",
        padding: "20px",
        borderRadius: 2,
        borderColor: isSelected ? "#007bff" : "transparent",
        borderWidth: isSelected ? "2px" : "1px",
      }}
    >
      <img src={item?.emoticon?.base64image} alt="Image" />

      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="bodyMedium"
          sx={{
            letterSpacing: ".5px",
            color: theme.palette.icon.dark,
            fontWeight: "500",
          }}
        >
          {dayjs(item.dateTime).format("DD MMM YYYY, hh:mm A")}
          {!item.isViewed && <Circle sx={{ height: 10 }} color={"error"} />}
        </Typography>

        <Typography variant="bodyMedium" component="p">
          {getDetail(item.detail)}
        </Typography>
      </Box>

      <IconButton
        edge="end"
        size="small"
        onClick={() => onClick(item)}
        sx={{
          width: 48,
          height: 48,
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Card>
  );
};

export default JournalsPage;
