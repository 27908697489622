import { MenuStore, MenuItem } from "../domain/menu.interface";
import { create } from "zustand";

const updateActiveState = (
  menuItems: MenuItem[],
  pathname: string
): MenuItem[] => {
  return menuItems.map((item) => {
    const isActive = item.route === pathname;
    const updatedChildren = item.children
      ? updateActiveState(item.children, pathname)
      : [];
    const hasActiveChild = updatedChildren.some((child) => child.active);
    return {
      ...item,
      active: isActive || hasActiveChild,
      children: updatedChildren,
    };
  });
};

export const useMenuStore = create<MenuStore>()((set) => ({
  menuItems: [],
  isLoading: true,
  addMenuItem: (item) =>
    set((state) => ({ menuItems: [...state.menuItems, item] })),
  setMenuItems: (items) => set({ menuItems: items, isLoading: false }),
  toggleOpen: (id) =>
    set((state) => ({
      menuItems: state.menuItems.map((item) =>
        item.id === id ? { ...item, open: !item.open } : item
      ),
    })),
  setActive: (pathname) =>
    set((state) => {
      const updatedMenu = updateActiveState(state.menuItems, pathname);
      return {
        menuItems: updatedMenu,
      };
    }),
}));
