export enum RoleSubtype {
  "IN-HOUSE_PROVIDER" = "In-house provider",
  "SUPPORT_STAFF" = "Support Staff",
}

export enum StandardAccountRoleSubtypeEnum {
  OFFICER = "OFFICER",
}

export enum RoleTypeEnum {
  STANDARD_SYSTEM_ROLE = "STANDARD_SYSTEM_ROLE",
  STANDARD_ACCOUNT_ROLE = "STANDARD_ACCOUNT_ROLE",
  CUSTOM_ROLE = "CUSTOM_ROLE",
}
