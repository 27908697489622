export enum ParticipantStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  DISENROLLED = "DISENROLLED",
  PENDING_ACTIVATION = "PENDING_ACTIVATION",
  PENDING_TO_ENROLL = "PENDING_TO_ENROLL",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  DENIED = "DENIED",
  INACTIVE = "INACTIVE",
}

export enum EnrollmentStatus {
  ACTIVE = "Active",
  PAUSED = "Paused",
  COMPLETED = "Completed",
  DISENROLLED = "Dismissed",
  PENDING_ACTIVATION = "Pending Activation",
  PENDING_TO_ENROLL = "Pending to Enroll",
  PENDING_VERIFICATION = "Pending Verification",
  DENIED = "Denied",
  INACTIVE = "Inactive",
}

export enum EnrollmentReasons {
  RENEWED_MOTIVATION_OR_INTEREST = "RENEWED_MOTIVATION_OR_INTEREST",
  DISENROLLED_FROM_ANOTHER_PROGRAM = "DISENROLLED_FROM_ANOTHER_PROGRAM",
  INCOMPLETE_INITIAL_PARTICIPATION = "INCOMPLETE_INITIAL_PARTICIPATION",
  ADHERENCE_REINFORCEMENT = "ADHERENCE_REINFORCEMENT",
  REQUIREMENT_FOR_A_NEW_JOB = "REQUIREMENT_FOR_A_NEW_JOB",
  ENROLLED_FROM_THE_QUEUE_LIST = "ENROLLED_FROM_THE_QUEUE_LIST",
}
