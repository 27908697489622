// ClientsFiltersContext.tsx
import { ParticipantStatus } from "@/domain/participant.enum";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface Filters {
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  search: string;
  status: string[];
  programId: string;
}

interface ClientsFiltersContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  persistFilters: boolean;
  setPersistFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultFilters: Filters = {
  sortField: "fullName",
  sortOrder: 1,
  programId: "",
  status: [ParticipantStatus.ACTIVE, ParticipantStatus.COMPLETED],
  search: "",
  pageNumber: 0,
  pageSize: 10,
};

const ClientsFiltersContext = createContext<
  ClientsFiltersContextType | undefined
>(undefined);

export const ClientsFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [persistFilters, setPersistFilters] = useState<boolean>(false);

  return (
    <ClientsFiltersContext.Provider
      value={{ filters, setFilters, persistFilters, setPersistFilters }}
    >
      {children}
    </ClientsFiltersContext.Provider>
  );
};

export const useClientFilters = () => {
  const context = useContext(ClientsFiltersContext);
  if (!context) {
    throw new Error(
      "useClientFilters must be used within a ClientsFiltersContext"
    );
  }
  return context;
};
