import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/account/${process.env.REACT_APP_ACCOUNT_ID}`,
  headers: {
    "Content-Type": "application/json",
  },
});

const setBaseURL = (url: string): void => {
  instance.defaults.baseURL = url;
};

const setToken = (token: string): void => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const transformAxiosError = (error: Error) => {
  if (axios.isAxiosError(error)) {
    return {
      message:
        error.response?.data?.error.errorMessage ||
        error.response?.data?.message ||
        error.message,
      error: error.response?.data?.error || "Unknown error",
    };
  }
  return error;
};

const isAxiosAuthorized = (): boolean => {
  return !!instance.defaults.headers.common["Authorization"];
};

export {
  instance as axios,
  setBaseURL,
  setToken,
  transformAxiosError,
  isAxiosAuthorized,
};
