import { useQuery, useMutation } from "@tanstack/react-query";
import { AlertsService } from "@/services/alerts.service";
import { OfficerPendingAlertsResponse } from "@/domain/alerts.interface";

export const useGetAlertsByOfficerId = (
  officerId: string,
  pageSize: number = 5,
  pageNumber: number = 1
) => {
  const {
    data,
    isLoading: isLoadingAlerts,
    isError: isErrorAlerts,
    refetch: refetchAlerts,
  } = useQuery<OfficerPendingAlertsResponse, Error>({
    queryKey: ["officer-alerts", officerId, pageSize, pageNumber],
    queryFn: () =>
      AlertsService.getAlertsByOfficerId(officerId, pageSize, pageNumber),
  });

  return {
    alerts: data?.alerts,
    totalAlerts: data?.total,
    isLoadingAlerts,
    isErrorAlerts,
    refetchAlerts,
  };
};

export const useUpdateAlertIsViewed = () => {
  return useMutation({
    mutationFn: async ({ alertId }: { alertId: string }) =>
      await AlertsService.updateAlertIsViewed(alertId),
  });
};
