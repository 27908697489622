import { useEffect, useRef, useState } from "react";
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  MessagingSessionConfiguration,
  Message,
} from "amazon-chime-sdk-js";
import {
  ChimeSDKMessagingClient,
  GetMessagingSessionEndpointCommand,
} from "@aws-sdk/client-chime-sdk-messaging";
import { useNotificationStore } from "@/hooks/useUserNotifications";
import { ISettingResponse } from "@/domain/setting.interface";
import { useUserStore } from "./useUserStore";
import { MessageModelExtended } from "@/domain/chat.interface";
import { useChatMessages } from "./useChatMessages";
import { SearchClients } from "@/domain/client.interface";
import { useMessagingClient } from "@/hooks/useMessagingClient";

let globalMessagingClient: ChimeSDKMessagingClient | null = null;

export const useMessagingNotifications = (
  userArn: string,
  userId: string,
  contacts: SearchClients[],
  settings?: ISettingResponse,
  userEmail?: string
) => {
  const [initializingSession, setInitializingSession] = useState(false);
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );
  const userStore = useUserStore();
  const triedFetchingUserArn = useRef(false);
  const { setContactMessages } = useChatMessages({
    contacts,
    userInfo: {
      _id: userId,
      chimeInstanceUserArn: userArn,
    },
    settings,
  });
  const { messagingClient, isFetching, error } = useMessagingClient(userId);

  useEffect(() => {
    if (!userId || !settings?.chatModule) {
      console.log("Messaging client or necessary data is not ready");
      return;
    }

    const startSession = async () => {
      if (!globalMessagingClient) {
        console.error("Messaging client is not initialized");
        return;
      }

      const endpointResponse = await globalMessagingClient.send(
        new GetMessagingSessionEndpointCommand({})
      );

      const websocketEndpoint = endpointResponse?.Endpoint?.Url;
      const logger = new ConsoleLogger("ChimeMessagingLogger", LogLevel.INFO);
      const sessionConfig = new MessagingSessionConfiguration(
        userArn,
        null,
        websocketEndpoint,
        globalMessagingClient
      );

      const session = new DefaultMessagingSession(sessionConfig, logger);

      session.addObserver({
        messagingSessionDidStart: () => {
          console.log("Messaging session started!");
          setInitializingSession(false);
        },
        messagingSessionDidStop: (event) => {
          console.log("Messaging session stopped.", event);
          if (event.code === 4999) {
            globalMessagingClient = null;
            initialize();
          }
        },
        messagingSessionDidReceiveMessage: (message: Message) => {
          if (message.type === "CREATE_CHANNEL_MESSAGE") {
            try {
              const parsedPayload = JSON.parse(message.payload);
              const {
                Content: content,
                CreatedTimestamp: createdTimestamp,
                Sender: { Arn: senderArn },
                MessageId: messageId,
              } = parsedPayload;

              if (senderArn !== userArn) {
                // Add to notifications
                addNotification({
                  id: messageId,
                  message: content || "New message received",
                  isRead: false,
                  sender: senderArn,
                  createdAt: createdTimestamp,
                });
              }

              // Invoke callback for new message
              const contactId = senderArn.split("/").pop() || "unknown";

              const newMessageModel: MessageModelExtended = {
                message: content || "",
                sentTime: new Date(createdTimestamp).getTime().toString(),
                sender: contactId,
                direction: "incoming",
                position: "single",
                messageId,
              };

              setContactMessages((prevMessages) => {
                const existingMessages = prevMessages[senderArn] || [];
                const existingMessageIds = new Set(
                  existingMessages.map((msg) => msg.messageId)
                );
                if (!existingMessageIds.has(messageId)) {
                  return {
                    ...prevMessages,
                    [senderArn]: [...existingMessages, newMessageModel],
                  };
                }
                return prevMessages;
              });
            } catch (error) {
              console.error("Failed to parse message payload:", error);
            }
          }
        },
      });

      try {
        await session.start();
      } catch (error) {
        console.error("Error starting messaging session:", error);
      }
    };

    const fetchUserArnIfMissing = async () => {
      if (!userArn && !triedFetchingUserArn.current) {
        triedFetchingUserArn.current = true;
        try {
          const userInfo = await userStore.getUserByEmail(
            userEmail as string,
            true
          );
          if (userInfo) {
            userArn = userInfo.chimeInstanceUserArn;
          }
        } catch (error) {
          console.error("Error fetching userArn:", error);
        }
      }
    };

    const initializeClient = () => {
      if (messagingClient) {
        globalMessagingClient = messagingClient;
        return messagingClient;
      }

      if (error) {
        console.error("Error fetching messaging client:", error);
      }

      if (isFetching) {
        console.log("Messaging client is still loading...");
      }
    };

    const initialize = async () => {
      if (initializingSession || globalMessagingClient) {
        console.log("Session is already initializing or initialized");
        return;
      }
      if (!userArn || !userId || !settings?.chatModule) {
        console.log("Messaging client or necessary data is not ready");
        await fetchUserArnIfMissing();
        if (!userArn) {
          console.warn("UserArn is still missing after fetch attempt");
          return;
        }
      }
      await initializeClient();
      await startSession();
    };

    initialize();

    // No cleanup logic here since the session is global
  }, [userArn, userId, addNotification, settings, messagingClient]);
};
