import React from "react";
import ProfileBreadcrumb from "@/ui/breadcrumbs/clients/ProfileBreadcrumb";
import ClientNameCard from "../ProfileName";
import { ParticipantResponse } from "@/domain/participant.interface";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  ArchiveOutlined as ArchiveIcon,
  UnarchiveOutlined as UnarchiveIcon,
  StarOutline as StarIcon,
} from "@mui/icons-material";
import { EnrollmentBalance } from "@/domain/enrollment.interface";
import theme from "@/utils/theme";

interface RewardsHeaderProps {
  participantResponse: ParticipantResponse;
  isLoadingParticipant: boolean;
  enrollmentBalance?: EnrollmentBalance | undefined;
}

const PointsCard = ({
  title,
  points,
  icon,
}: {
  title: string;
  points: number | undefined;
  icon: React.ReactNode;
}) => (
  <Box
    sx={{
      display: "flex",
      backgroundColor: "white",
      p: 2,
      borderRadius: 2,
      boxShadow: 0,
      textAlign: "center",
      height: 104,
    }}
  >
    <Grid container alignItems="center">
      <Grid item xs="auto">
        <Avatar
          sx={{
            width: 40,
            height: 40,
            backgroundColor: theme.palette.textMain.dark2,
            border: "2px solid white",
            color: "white",
          }}
        >
          {icon}
        </Avatar>
      </Grid>
      <Grid item xs textAlign="left" sx={{ pl: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="caption" color="menu.dark">
          PARCA POINTS
        </Typography>
      </Grid>
      <Grid item xs="auto" textAlign="right">
        <Typography variant="h3" fontWeight="bold">
          {points ?? 0}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

const RewardsHeader: React.FC<RewardsHeaderProps> = ({
  participantResponse,
  isLoadingParticipant,
  enrollmentBalance,
}) => {
  return (
    <>
      <Box>
        <ProfileBreadcrumb />
        <ClientNameCard
          isLoading={isLoadingParticipant}
          firstName={participantResponse?.participant?.firstName}
          lastName={participantResponse?.participant?.lastName}
        />
      </Box>
      <Grid container mt={0} spacing={2}>
        <Grid item xs={12} md={4}>
          <PointsCard
            title="Earned"
            points={enrollmentBalance?.totalEarnedPoints}
            icon={<ArchiveIcon />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PointsCard
            title="Redeemed"
            points={enrollmentBalance?.totalRedeemedPoints}
            icon={<UnarchiveIcon />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PointsCard
            title="Available"
            points={enrollmentBalance?.balancePoints}
            icon={<StarIcon />}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RewardsHeader;
