import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Chip, Paper, TextField } from "@mui/material";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { useUsers } from "@/hooks/useUsers";
import useDebounce from "@/hooks/useDebounce";
import SupportStaffPermissionsMatrix from "@/ui/tables/clients/SupportStaffPermissionsMatrix";
import {
  useUpdateSupportStaffModules,
  useRemoveSupportStaff,
} from "@/hooks/useEnrollments";
import {
  ProviderModule,
  ProviderModuleItem,
  RoleSubType,
} from "@/domain/enrollment.interface";
import { ParticipantResponse } from "@/domain/participant.interface";
import GenericAlertModal from "@/components/AlertModal";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { Module } from "@/domain/module.interface";
import InternalProviderPermissionsMatrix from "@/ui/tables/clients/InternalProviderPermissionsMatrix";
import SkeletonLoader from "@/components/SkeletonLoader";

interface ProviderModulesModalProps {
  open: boolean;
  onClose: () => void;
  participantResponse: ParticipantResponse;
  refetchParticipant: () => void;
  roleSubType: RoleSubType;
  modules: Module[];
  isLoadingParticipant: boolean;
  createAuditLogAddStaff: (
    staffNames: string[],
    participantId?: string
  ) => Promise<void>;
  createAuditLogRemoveStaff: (
    staffName: string,
    participantId?: string
  ) => Promise<void>;
  providersList: ProviderModuleItem[];
}

const ProviderModulesModal: React.FC<ProviderModulesModalProps> = ({
  open,
  onClose,
  participantResponse,
  refetchParticipant,
  roleSubType,
  modules,
  isLoadingParticipant,
  createAuditLogAddStaff,
  createAuditLogRemoveStaff,
  providersList,
}) => {
  const { t } = useTranslation("common");
  const theme = useAppTheme();
  const { showSnackBar } = useFeedBack();
  const { lastEnrollment: enrollment, participant } = participantResponse;
  const [selectedStaff, setSelectedStaff] = useState<ProviderModule[]>([]);
  const [staffInput, setStaffInput] = useState<string>("");
  const [staffList, setStaffList] = useState<ProviderModule[]>([]);
  const [supportStaffList, setSupportStaffList] = useState<ProviderModule[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] =
    useState(false);
  const [staffToRemove, setStaffToRemove] = useState<ProviderModule | null>(
    null
  );

  const debouncedStaffInput = useDebounce({ value: staffInput, delay: 300 });

  const {
    users: supportStaffListResult,
    refetchUsers: refetchSupportStaff,
    isLoadingUsers,
  } = useUsers(
    {
      roleSubType,
      sortField: "firstName",
      sortOrder: 1,
      pageNumber: 0,
      pageSize: 10,
      search: debouncedStaffInput,
      roleId: "",
      status: ["ACTIVE"],
    },
    false
  );
  const { mutateAsync } = useUpdateSupportStaffModules();
  const { mutate: removeSupportStaff } = useRemoveSupportStaff();
  useEffect(() => {
    if (open) {
      refetchSupportStaff();
    }
  }, [open, refetchSupportStaff]);

  useEffect(() => {
    if (
      !open ||
      !supportStaffListResult ||
      isLoading ||
      isLoadingParticipant ||
      isLoadingUsers ||
      supportStaffListResult?.users?.some((user) =>
        user?.roles.some((role) => role?.subType !== roleSubType)
      )
    ) {
      return;
    }
    const existingStaff = providersList.map((staff: ProviderModuleItem) => {
      const user = supportStaffListResult?.users?.find(
        (user) => user._id === staff.providerId
      );

      const firstName = user?.firstName;
      const lastName = user?.lastName;

      return {
        id: staff.providerId,
        providerId: staff.providerId,
        name: `${firstName} ${lastName}`.trim(),
        modulesIds: staff.modulesIds || [],
      };
    });

    setStaffList(existingStaff);
  }, [
    open,
    providersList,
    supportStaffListResult,
    isLoading,
    isLoadingParticipant,
    roleSubType,
    isLoadingUsers,
  ]);

  useEffect(() => {
    if (!supportStaffListResult?.users || isLoading || isLoadingParticipant) {
      return;
    }

    const staffData = supportStaffListResult.users
      .filter(
        (user) =>
          !staffList.some((assignedStaff) => assignedStaff.id === user._id)
      )
      .map((user) => ({
        id: user._id,
        providerId: user._id,
        name: `${user.firstName} ${user.lastName}`,
        modulesIds: [],
      }));

    setSupportStaffList(staffData as ProviderModule[]);
  }, [supportStaffListResult, staffList, isLoading, isLoadingParticipant]);

  const handleAddToList = async () => {
    if (!enrollment || selectedStaff.length === 0) {
      return;
    }

    try {
      setIsLoading(true);

      const updatePromises = selectedStaff.map(async (staff) => {
        return mutateAsync({
          enrollmentId: enrollment._id,
          providerId: staff.id,
          modulesIds: modules.map((module) => module._id) || [],
          providerType: roleSubType,
        });
      });

      await Promise.allSettled(updatePromises);

      const staffNames = selectedStaff.map((staff) => staff.name);
      await createAuditLogAddStaff(staffNames, participant._id);

      setStaffList([...staffList, ...selectedStaff]);
      setSelectedStaff([]);
      setStaffInput("");
      refetchParticipant();
    } catch (error) {
      showSnackBar(`An error occurred while adding support staff: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRemoveStaff = (staff: ProviderModule) => {
    setStaffToRemove(staff);
    setIsRemoveConfirmationOpen(true);
  };

  const handleRemoveStaff = () => {
    if (!enrollment || !staffToRemove) return;

    setIsLoading(true);
    const supportStaffName = staffToRemove.name;
    removeSupportStaff(
      {
        enrollmentId: enrollment._id,
        providerId: staffToRemove.id,
        providerType: roleSubType,
      },
      {
        onSuccess: async () => {
          setStaffList(
            staffList.filter((staff) => staff.id !== staffToRemove.id)
          );
          refetchParticipant();
          await createAuditLogRemoveStaff(supportStaffName, participant._id);
        },
        onError: (error) => {
          showSnackBar(`Failed to remove support staff: ${error.message}`);
        },
        onSettled: () => {
          setIsLoading(false);
          setIsRemoveConfirmationOpen(false);
        },
      }
    );
  };

  const handleUpdateSupportStaffModules = async (
    staffId: string,
    updatedModules: string[]
  ) => {
    try {
      await mutateAsync({
        enrollmentId: enrollment?._id || "",
        providerId: staffId,
        modulesIds: updatedModules,
        providerType: roleSubType,
      });
    } catch (error) {
      showSnackBar(
        `Failed to update support staff modules for ${staffId}: ${error}`
      );
    }
  };

  const handleClose = () => {
    clearState();
    onClose();
    refetchParticipant();
  };

  const clearState = () => {
    setSelectedStaff([]);
    setStaffInput("");
    setStaffToRemove(null);
    setIsRemoveConfirmationOpen(false);
    setIsLoading(false);
  };

  const title =
    roleSubType === RoleSubType.SUPPORT_STAFF
      ? t("support_staff")
      : t("internal_provider");
  const autocompletePlaceholder =
    roleSubType === RoleSubType.SUPPORT_STAFF
      ? t("search_support_staff")
      : t("search_internal_provider");
  const MatrixComponent =
    roleSubType === RoleSubType.SUPPORT_STAFF
      ? SupportStaffPermissionsMatrix
      : InternalProviderPermissionsMatrix;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        isPending={isLoading}
        title={title}
        theme={theme}
        size={{
          width: "100%",
          minHeight: 340,
          maxHeight: 600,
          maxWidth: 860,
        }}
        content={
          <Box mt={4} sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                marginBottom: 2,
                width: "100%",
              }}
            >
              <Autocomplete
                multiple
                value={selectedStaff}
                inputValue={staffInput}
                onInputChange={(_event, newInputValue) =>
                  setStaffInput(newInputValue)
                }
                options={supportStaffList.filter(
                  (staff: ProviderModule) =>
                    staff.name
                      .toLowerCase()
                      .includes(staffInput.toLowerCase()) &&
                    !selectedStaff.some(
                      (selectedStaff) => selectedStaff.id === staff.id
                    )
                )}
                getOptionLabel={(option) => option.name}
                renderTags={(value: ProviderModule[], getTagProps) =>
                  value.map((option: ProviderModule, index: number) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      key={option.id}
                    />
                  ))
                }
                onChange={(_event, newValue) => {
                  setSelectedStaff(newValue as ProviderModule[]);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={autocompletePlaceholder} />
                )}
                sx={{ flexGrow: 1 }}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      maxHeight: "160px",
                      overflow: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
              />

              <Button
                variantType="save"
                label={t("add")}
                onClick={handleAddToList}
                disabled={selectedStaff?.length < 1}
                sx={{
                  marginLeft: 1,
                  flexShrink: 0,
                  textTransform: "uppercase",
                }}
              />
            </Box>
            {staffList.length ? (
              <MatrixComponent
                staffList={staffList}
                modules={modules}
                onRemoveStaff={confirmRemoveStaff}
                updateSupportStaffModules={handleUpdateSupportStaffModules}
                isLoading={isLoading || isLoadingParticipant}
              />
            ) : (
              <SkeletonLoader
                skeletonHeight={100}
                isLoading={isLoading || isLoadingParticipant}
              >
                <></>
              </SkeletonLoader>
            )}
          </Box>
        }
        actions={
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{ textTransform: "uppercase" }}
              variantType="save"
              onClick={handleClose}
              label={t("close")}
            />
          </Box>
        }
      />

      {isRemoveConfirmationOpen && staffToRemove && (
        <GenericAlertModal
          onClick={handleRemoveStaff}
          onCancel={() => setIsRemoveConfirmationOpen(false)}
          title={t("remove_support_staff_title")}
          description={t("remove_support_staff_text")}
          submitText={t("accept")}
          cancelText={t("cancel")}
          theme={theme}
        />
      )}
    </>
  );
};

export default ProviderModulesModal;
