import { Category, CategoryElement } from "@/domain/category.interface";
import { axios } from "@/utils/axios.create";

const findByType = async ({
  type,
  status,
}: {
  type?: string;
  status: string;
}): Promise<Category> => {
  return axios
    .get("/categories/by-type", { params: { type } })
    .then((res) => {
      if (status === "ACTIVE" && res.data.body.categories) {
        const categories = res.data.body.categories;

        return {
          ...res.data.body,
          categories: categories.filter(
            (category: CategoryElement) => category.status === status
          ),
        };
      }

      return res.data.body;
    })
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch categories"
      );
    });
};

export const CategoryService = {
  findByType,
};
