import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

type StatsCardProps = {
  title: string;
  value: string;
  icon: ReactNode;
};

const StatsCard = ({ title, value, icon }: StatsCardProps) => {
  return (
    <Card sx={{ width: "100%", borderRadius: "8px" }}>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1.25rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#232B36",
            color: "#FFFFFF",
            flexShrink: 0,
          }}
        >
          {icon}
        </Box>
        <Typography variant="headlineSmall" component="h1" flexGrow={1}>
          {title}
        </Typography>
        <Typography variant="h3" component="h3" flexGrow={1}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
